import React from 'react';
import axios from 'axios';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@resi/beeem-ui-components';
import Events from '../events';
import Book from '../event/components/book';
import {useNavigate, useParams} from 'react-router-dom';
import Dialog from '../../components/dialog';

axios.defaults.baseURL = process.env.BASE_URL;

interface IEventProps {
  events: IEvent[];
}

const Verify = ({events}: IEventProps) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] =
    React.useState<'booked' | 'notbooked' | 'fullybooked' | 'expired' | 'sent' | 'alreadybooked'>(
      'notbooked'
    );

  React.useEffect(() => {
    const url = `/api/bookings/confirm`;

    axios
      .post(url, {
        code: id,
      })
      .then(result => {
        if (result?.status === 200 || result?.statusText === 'OK') {
          setStatus('booked');
          setOpen(true);
        }
      })
      .catch(error => {
        if (error?.status === 422) {
          setStatus('alreadybooked');
        } else {
          setStatus('expired');
        }
        setOpen(true);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate('/');
  };

  const PopUp = () => {
    return (
      <Dialog open={open} handleClose={() => handleClose()}>
        <Box sx={{width: '100%', margin: 'auto'}}>
          <Box sx={{'.MuiTypography-root': {textAlign: 'left'}}}>
            {status === 'booked' ? (
              <>
                <Typography variant="h2" color="var(--green-theme)" sx={{marginBottom: '20px'}}>
                  Your ticket to this event has been booked.
                </Typography>
                <Divider />
                <Typography variant="body1" color="#202020" sx={{marginTop: '20px'}}>
                  Please check your email for confirmation.
                </Typography>
                <Box sx={{margin: '40px 0', display: 'flex', flexDirection: 'row'}}>
                  <button
                    className={'btn-primary font-body'}
                    style={{marginRight: '20px'}}
                    onClick={() => navigate('/signin')}
                  >
                    Sign In
                  </button>
                  <button
                    className={'btn-primary font-body'}
                    onClick={() => navigate('/onboarding')}
                  >
                    Create Account
                  </button>
                </Box>
              </>
            ) : status === 'fullybooked' ? (
              <>
                <Typography variant="h2" color="#EB3754" sx={{marginBottom: '20px'}}>
                  Unfortunately this event is fully booked
                </Typography>
                <Divider />
                <Typography variant="body1" color="#202020" sx={{margin: '20px 0 150px 0'}}>
                  Please check and try again
                </Typography>
              </>
            ) : status === 'alreadybooked' ? (
              <>
                <Typography variant="h2" color="#EB3754" sx={{marginBottom: '20px'}}>
                  You have already booked for this event
                </Typography>
                <Divider />
                <Typography variant="body1" color="#202020" sx={{margin: '20px 0 150px 0'}}>
                  Please cancel and your booking and rebook if you need to make changes
                </Typography>
              </>
            ) : (
              <>
                {' '}
                <Typography variant="h2" color="#EB3754" sx={{marginBottom: '20px'}}>
                  This link has expired
                </Typography>
                <Divider />
                <Typography variant="body1" color="#202020" sx={{marginTop: '20px'}}>
                  Please close and try again
                </Typography>{' '}
              </>
            )}
          </Box>
        </Box>
      </Dialog>
    );
  };

  return (
    <>
      {status === 'notbooked' && (
        <Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <PopUp />
      <Events events={events} />
    </>
  );
};

export default Verify;
