import * as React from 'react';
import {FormGroup, FormControlLabel, Switch} from '@resi/beeem-ui-components';

interface ISwitchLabels {
  label: string;
  checked?: boolean;
  onChange: () => void;
  name: string;
  fontSize?: string;
  fontWeight?: number;
  marginTop?: string;
  color?: string;
  disabled?: boolean;
}

const SwitchLabels = ({
  label,
  checked,
  onChange,
  name,
  fontSize,
  fontWeight,
  marginTop,
  color,
  disabled
}: ISwitchLabels) => {
  return (
    <FormGroup sx={{...(marginTop && {marginTop: marginTop})}}>
      <FormControlLabel
        name={name}
        disabled={disabled}
        control={<Switch checked={checked} onChange={onChange} />}
        label={label}
        sx={{
          fontSize: fontSize ? fontSize : '16px',
          fontWeight: fontWeight ? fontWeight : 400,
          '.MuiTypography-body1': {
            color: color ? color : '#111111',
            fontSize: fontSize ? fontSize : '16px',
            fontWeight: fontWeight ? fontWeight : 400,
          },
        }}
      />
    </FormGroup>
  );
};

export default SwitchLabels;
