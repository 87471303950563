import React, {useState} from 'react';
import {fetchProfile} from '../../features/profile';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store';
import {AppThunkDispatch} from '../../App';
import {Box, CircularProgress} from '@mui/material';
import SessionTimedOut from '../SessionTimedOut/SessionTimedOut';

const ProtectedRoute = ({children, roles}: {children: JSX.Element; roles?: IRole[]}) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const profile = useSelector((state: RootState) => state.profile.value);
  const profileLoaded = useSelector((state: RootState) => state.profile.loaded);
  const [isAdmin, setIsAdmin] = useState(false);

  React.useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  React.useEffect(() => {
    if (profile.loggedIn) {
      setIsAdmin(!!profile?.isAdmin);
    }
  }, [profile]);

  if (!profileLoaded) {
    return (
      <Box padding="200px 0">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {
        (!profile || !profile?.isAdmin) && <SessionTimedOut />
      }
      {children}
    </>
  );
};

export default ProtectedRoute;
