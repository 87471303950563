import React, {useEffect} from 'react';
import {Box} from '@resi/beeem-ui-components';
import {SelectChangeEvent} from '@mui/material/Select';
import Title from '../../../../components/title';
import {Text} from '../details';
import Cogs from '../../../../components/icons/cogs.svg';
import Switch from '../../../../components/switch';

interface ISettingsProps {
  event: IEventNew;
  handleChange: (
    event: React.BaseSyntheticEvent | IDateEvent | SelectChangeEvent | ISwitchEvent
  ) => void;
  errors?: boolean;
  fontSize?: string;
  fontWeight?: number;
  edit?: boolean;
  callToRSVP: boolean;
  setCallToRSVP: Function;
}

const Settings = ({
  event,
  handleChange,
  callToRSVP,
  setCallToRSVP
}: ISettingsProps) => {
  useEffect(() => {
    setCallToRSVP((event.rsvpEmail || event.rsvpPhone))
  }, [event.rsvpEmail, event.rsvpEmail]);

  return (
    <Box sx={{marginTop: '20px'}}>
      <Title icon={Cogs} title="Event settings" marginTop="60px" />
      <Switch
        marginTop={"40px"}
        fontWeight={500}
        fontSize="18px"
        label={'Call to RSVP'}
        checked={callToRSVP}
        name={'callToRSVP'}
        onChange={() => setCallToRSVP(!callToRSVP)}
      />
      {
        callToRSVP &&
        <>
          <Text
            sx={{marginTop: '20px'}}
            label="Phone number"
            type="text"
            name="rsvpPhone"
            value={event.rsvpPhone ? event.rsvpPhone : ''}
            variant="standard"
            onChange={handleChange}
          />
          <Text
            sx={{marginTop: '20px'}}
            label="Email"
            type="text"
            name="rsvpEmail"
            value={event.rsvpEmail ? event.rsvpEmail : ''}
            variant="standard"
            onChange={handleChange}
          />
        </>
      }
      <Switch
        marginTop="40px"
        fontWeight={500}
        fontSize="18px"
        label="Attendance limit"
        checked={!!event.attendanceLimit}
        name={event?.attendanceLimit === true ? 'attendanceLimitSet' : 'attendanceLimitNotSet'}
        onChange={() =>
          handleChange({target: {name: 'attendanceLimit', value: !event.attendanceLimit}})
        }
      />
      <>
        {
          event.attendanceLimit &&
          <Text
            sx={{marginTop: '20px'}}
            label="Event attendance limit"
            type="number"
            name="capacity"
            value={event.capacity ? event.capacity : ''}
            variant="standard"
            onChange={handleChange}
          />
        }
      </>
      <Switch
        marginTop="40px"
        fontWeight={500}
        fontSize="18px"
        label="Coming Soon"
        checked={event.isComingSoon === true}
        name="isComingSoon"
        onChange={() => handleChange({target: {name: 'isComingSoon', value: !event.isComingSoon}})}
      />
    </Box>
  );
};

export default Settings;
