import {Box, Button, Grid, Typography} from '@resi/beeem-ui-components';
import Side from '../../components/side';
import CustomPaginationActionsTable from '../../components/guestlisttable/index';
import {useParams} from 'react-router-dom';
import {stripSeconds} from '../../utils';
import axios from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {IGuestList} from './IGuestList';
import ErrorNotice from '../../components/guestlisttable/ErrorNotice/ErrorNotice';
import RemCalculator from '../../Utility/RemCalculator';

axios.defaults.baseURL = process.env.BASE_URL;

const GuestList = () => {
  const [event, setEvent] = useState<IEventNew>({});
  const {id} = useParams();
  const [guestList, setGuestList] = useState<IGuestList[]>();
  const [error, setError] = useState();

  useEffect(() => {
    axios.get(`/api/events/${id}`)
      .then(result => {
        const { data } = result;
        setEvent({
          id: data.id,
          title: data.title,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          endTime: stripSeconds(data.endTime),
        });
      })
      .catch(error => setError(error))

    confirmedBookings();
  }, [id])

  const confirmedBookings = useCallback(() => {
    axios.get(`/api/events/bookings/${id}`)
      .then(res => setGuestList(res.data))
      .catch(err => setError(err))
  }, [id])

  return (
    <Box
      sx={{
        padding: '20px 0',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        background: '#FFFFFF',
      }}
    >
      <Side />
      <Box sx={{maxWidth: '1344px', margin: 'auto', padding: {xs: '0px 20px', lg: '0 70px'}}}>
        {
          error ?
          <ErrorNotice /> :
          <Box>
            <Typography
              variant="body1"
              sx={{color: '#00000099'}}
              textAlign={'left'}
              paddingBottom={RemCalculator(15)}
            >
              <span style={{textDecoration: 'underline'}}>Events</span> /{' '}
              <span color="rgba(0, 0, 0, 0.35)" style={{textDecoration: 'underline'}}>
                {` ${event.title} ${event.startDate?.toLocaleDateString('en-GB')}`} {'  '}
              </span>
              <span style={{color: 'rgba(0, 0, 0, 0.6)', fontWeight: 500}}>
                {'  '} / Guest list
              </span>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: RemCalculator(105)
              }}
            >
              {
                (guestList && (guestList?.filter(list => (list.isActive && list.isConfirmed)).length > 0)) ?
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: RemCalculator(41)
                  }}
                >
                  <Box
                    sx={{
                      textAlign: 'left',
                      '.MuiTypography-body1': {textAlign: 'left'},
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Grid container spacing={{xs: '30px', lg: '100px'}} sx={{marginBottom: '8px'}}>
                      <Grid item xs={12} md={8}>
                        <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
                          <Typography
                            variant="h2"
                            color="#111111"
                            fontFamily={'brandon-grotesque'}
                          >
                            {'  '}Guest list - {event.title}
                            <span style={{fontSize: '22px'}}>
                              {'  '}
                              {event.startDate?.toLocaleDateString('en-GB')}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <CustomPaginationActionsTable
                    data={guestList.filter((list) => (list.isActive && list.isConfirmed) || (list.isActive && !list.isConfirmed))}
                    showConfirmedActions={true}
                  />
                </Box> :
                <Typography
                  variant={'body1'}
                  sx={{ textAlign: 'left' }}
                >
                  No confirmed guest(s) available yet
                </Typography>
              }
              {
                (guestList && (guestList.filter(list => !list.isActive && !list.isConfirmed).length > 0)) &&
                <Box>
                  <Box
                    sx={{
                      textAlign: 'left',
                      '.MuiTypography-body1': {textAlign: 'left'},
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Grid container spacing={{xs: '30px', lg: '100px'}} sx={{marginBottom: '41px'}}>
                      <Grid item xs={12} md={8}>
                        <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
                          <Typography
                            variant="h2"
                            color="#111111"
                            fontFamily={'brandon-grotesque'}
                          >
                            Unconfirmed
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <CustomPaginationActionsTable
                    data={guestList.filter(list => !list.isActive && !list.isConfirmed)}
                    showUnconfirmedActions={true}
                  />
                </Box>
              }
              {
                (guestList && (guestList.filter(list => !list.isActive && list.isConfirmed).length > 0)) &&
                <Box>
                  <Box
                    sx={{
                      textAlign: 'left',
                      '.MuiTypography-body1': {textAlign: 'left'},
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Grid container spacing={{xs: '30px', lg: '100px'}} sx={{marginBottom: '41px'}}>
                      <Grid item xs={12} md={8}>
                        <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
                          <Typography
                            variant="h2"
                            color="#111111"
                            fontFamily={'brandon-grotesque'}
                          >
                            Cancellations
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <CustomPaginationActionsTable
                    disabledState={true}
                    data={guestList.filter(list => !list.isActive && list.isConfirmed)}
                  />
                </Box>
              }
              <Box sx={{display: 'flex', justifyContent: 'flex-start', marginTop: '50px'}}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{textTransform: 'initial', lineHeight: '30px'}}
                  onClick={() => {}}
                  href={`/admin/events/${id}`}
                >
                  Back to event overview
                </Button>
              </Box>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default GuestList;
