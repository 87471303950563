import {Box, Typography, styled} from '@resi/beeem-ui-components';
import Clock from '../icons/clock.svg';
import Ticket from '../icons/ticket.svg';
import MapMarker from '../icons/map-marker.svg';
import {useNavigate} from 'react-router-dom';
import Chip from '../chip';
import {convertDate, formatMoney, stripSeconds} from '../../utils';

const Main = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  background: '#FFFFFF',
  border: 'solid 1px rgba(0, 0, 0, 0.12)',
  cursor: 'pointer',
}));

const Event = ({
  id,
  title,
  startTime,
  startDate,
  images,
  location,
  price,
  chip,
  freeForResidents,
  capacity,
  isComingSoon,
  recurringId
}: IEvent) => {
  const navigate = useNavigate();

  return (
    <Main
        className="event"
        onClick={() => navigate(`/events/${id}`)}
        data-name={`event${(capacity && capacity > 0) ? '-with-seats' : ''}`}
        data-event-type={recurringId ? 'recurring-event-' + title : 'event'}
    >
      {
        chip && (
          <Box sx={{position: 'absolute', top: '-10px', right: '10px'}}>
            <Chip text="Free for resident" />
          </Box>
        )
      }
      <Box
        sx={{
          height: '151px',
          width: '100%',
          display: 'block',
          background: `url(${images[0]})`,
          backgroundSize: 'cover',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          gap: '4px',
          '.MuiTypography-root': {textAlign: 'left'},
          '.MuiTypography-body1': {
            marginTop: '8px',
            backgroundRepeat: 'no-repeat',
            paddingLeft: '40px',
          },
        }}
      >
        <Typography variant="h5" fontFamily={'Barlow'} color={'var(--green-theme)'}>{title}</Typography>
        {
          isComingSoon === true ?
          <Typography sx={{background: `url(${Clock})`, backgroundPosition: '0 2px'}} fontFamily={'Barlow'}>
            {`Coming Soon`}
          </Typography> :
          <Typography sx={{background: `url(${Clock})`, backgroundPosition: '0 2px'}} fontFamily={'Barlow'}>
            {`${convertDate(startDate)}, ${stripSeconds(startTime)}`}
          </Typography>
        }
        <Typography sx={{background: `url(${MapMarker})`, backgroundPosition: '2px 2px'}} fontFamily={'Barlow'}>
          {location?.substr(0, location?.indexOf(','))}
        </Typography>
        <Typography sx={{background: `url(${Ticket})`, backgroundPosition: '2px 2px'}} fontFamily={'Barlow'}>
          {price !== 0 ? `${formatMoney(price)}` : freeForResidents ? 'Free for residents' : 'Free'}
        </Typography>
      </Box>
    </Main>
  );
};

export default Event;
