import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Divider, Link, Typography} from '@resi/beeem-ui-components';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Dialog from '../../components/dialog';
import Tabs from '../../components/tabs';
import {ReactComponent as BackArrow} from '../../components/icons/ArrowBack.svg';
import Booking from './components/booking';
import {RootState} from '../../store';
import {AppThunkDispatch} from '../../App';
import {BookingEvent, fetchBookingEvents} from '../../features/profile';
import {
  formatMoney,
  getFutureBookingEvents,
  getHistoricBookingEvents,
  pluraliseWord,
} from '../../utils';
import {cancelBooking} from '../../features/booking/bookingSlice';
import NoBookings from './NoBookings/NoBookings';

interface IBookingEventProps {
  historic?: boolean;
  events: BookingEvent[];
}

const Tickets = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [open, setOpen] = React.useState(false);
  const [cancelBookingFailed, setCancelBookingFailed] = React.useState(false);
  const [cancelledOpen, setCancelledOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const profile = useSelector((state: RootState) => state.profile);
  const userBookings = useSelector((state: RootState) => state.booking);
  const [upcomingEvents, setUpcomingEvents] = useState<BookingEvent[]>([]);
  const [previousEvents, setPreviousEvents] = useState<BookingEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<BookingEvent | null>(null);
  const navigate = useNavigate();

  const selectBookingToCancel = (id: string) => {
    setSelectedId(id);
    const bookingEvent = upcomingEvents.find(b => b.id === id);
    if (bookingEvent) {
      setSelectedEvent(bookingEvent);
    }
    setOpen(true);
  };

  const handleCancelBooking = () => {
    if (selectedEvent) {
      const {
        value: {email},
      } = profile;
      if (email) {
        dispatch(
          cancelBooking({
            emailAddress: email,
            bookingNumber: selectedEvent.bookingNumber,
          })
        );
      }
    }
  };

  useEffect(() => {
    profile.loaded &&
      Object.keys(profile.value).length === 0 &&
      navigate('/signin', {replace: true});

    const {
      loaded,
      value: {id},
    } = profile;
    if (loaded && id) {
      dispatch(fetchBookingEvents(id));
    }
  }, [profile.loaded]);

  useEffect(() => {
    const {loaded} = userBookings;
    const {
      value: {id},
    } = profile;
    if (loaded && id) {
      dispatch(fetchBookingEvents(id));
      setCancelledOpen(true);
      setOpen(false);
    }
  }, [userBookings.bookingCancelled]);

  useEffect(() => {
    const {failed} = userBookings;
    if (failed) {
      setCancelBookingFailed(true);
    }
  }, [userBookings.failed]);

  useEffect(() => {
    const {bookingEvents} = profile;
    if (bookingEvents.length > 0) {
      setUpcomingEvents(getFutureBookingEvents(bookingEvents));
      setPreviousEvents(getHistoricBookingEvents(bookingEvents));
    }
  }, [profile.bookingEvents]);

  const BookingEvent = ({events, historic}: IBookingEventProps) => {
    return (
      <Box sx={{marginTop: '47px'}}>
        {events
          .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
          .map((bookingEvent, index) => {
            return (
              <Booking
                key={bookingEvent.id}
                {...bookingEvent}
                last={index === events.length - 1}
                cancelBooking={selectBookingToCancel}
                historic={historic}
              />
            );
          })}
      </Box>
    );
  };

  const items = [
    {
      title: `Upcoming ${upcomingEvents.length}`,
      content: upcomingEvents.length ? <BookingEvent events={upcomingEvents} /> : <NoBookings />,
    },
    {
      title: `Previous ${previousEvents.length}`,
      content: <BookingEvent events={previousEvents} historic={true} />,
    },
    // { title: 'Waitlist (3)', content: <Box>Waitlist</Box> },
  ];

  function HandleEventCancellationConfirmation(): void {
    let bookedEventsQuantity: number = profile.bookingEvents.length;

    let cancelEventDispatcher = setCancelledOpen(false);

    Promise.resolve(cancelEventDispatcher).then(() => {
      if (bookedEventsQuantity === 0) {
        window.location.reload();
      }
    });
  }

  return (
    <Box>
      <Box sx={{width: '100%', maxWidth: '1122px', margin: 'auto'}}>
        <Typography
          variant="h1"
          color="var(--green-theme)"
          textTransform="uppercase"
          sx={{marginTop: '0px', textAlign: 'left'}}
        >
          My Tickets
        </Typography>
        <Box sx={{marginTop: '30px'}}>
          <Tabs items={items} color="var(--green-theme)" />
        </Box>
      </Box>
      <Dialog
        open={open}
        handleClose={() => {
          setOpen(false);
          setCancelBookingFailed(false);
        }}
      >
        <Box sx={{width: '100%', margin: 'auto'}}>
          <Typography
            variant="h2"
            color="var(--green-theme)"
            sx={{marginTop: '0px', marginBottom: '30px', textAlign: 'left'}}
          >
            Cancel booking for {selectedEvent?.title}
          </Typography>
          <Divider />
          <Typography
            variant="body2"
            color="var(--green-theme)"
            sx={{marginTop: '20px', textAlign: 'left', fontWeight: 600}}
          >
            Are you sure you want to cancel this booking?
          </Typography>
          <Typography
            variant="body1"
            color="var(--green-theme)"
            sx={{marginTop: '20px', textAlign: 'left', fontWeight: 600}}
          >
            {`${selectedEvent?.count} x ${pluraliseWord(selectedEvent?.count || 0, 'ticket')}`}
            <span style={{paddingLeft: '30px'}}>
              {selectedEvent?.isPaid ? formatMoney(selectedEvent.price) : `FREE EVENT`}
            </span>
          </Typography>
          {selectedEvent?.isPaid && (
            <>
              <Divider sx={{paddingTop: '20px'}} />
              <Typography
                variant="body2"
                color="var(--green-theme)"
                sx={{marginTop: '20px', textAlign: 'left', fontWeight: 500}}
              >
                You will be refunded by Stripe to the card used to purchase the tickets.
                <br />
                Refunds may take up to 24 hours to appear on your statement.
              </Typography>
            </>
          )}

          {cancelBookingFailed && (
            <>
              <Divider sx={{marginTop: '20px'}} />
              <Typography
                variant="body1"
                color="error"
                sx={{marginTop: '20px', marginBottom: '30px', textAlign: 'left'}}
              >
                There was a problem cancelling your ticket at this time. Please keep the booking and
                email{' '}
                <Link color="error" href="mailto:events@wallacealiving.com">
                  events@wallacealiving.com
                </Link>{' '}
                so we can process the cancellation for you. Thanks for your patience.
              </Typography>
            </>
          )}

          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px'}}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              startIcon={<BackArrow />}
              sx={{
                color: 'var(--green-theme)',
                '&:hover': {
                  color: 'var(--green-theme)',
                },
              }}
            >
              No, keep booking
            </Button>
            {userBookings.loading ? (
              <CircularProgress />
            ) : (
              <button
                onClick={handleCancelBooking}
                className={"btn-primary font-body"}
              >
                Yes, cancel booking
              </button>
            )}
          </Box>
        </Box>
      </Dialog>

      <Dialog open={cancelledOpen} handleClose={HandleEventCancellationConfirmation}>
        <Box sx={{width: '100%', margin: 'auto'}}>
          <Typography
            variant="h2"
            color="var(--green-theme)"
            sx={{marginTop: '0px', marginBottom: '30px', textAlign: 'left'}}
          >
            {`Booking for ${selectedEvent?.title} has been cancelled`}
          </Typography>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Tickets;
