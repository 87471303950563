import {createTheme} from '@mui/material/styles';
import {orange} from '@mui/material/colors';
import {alpha} from '@mui/material';
import {DEFAULT} from '../shared/constants/constants';

declare module '@mui/material/styles' {
  interface Palette {
    biscuit?: Palette['primary'];
    outline?: Palette['divider'];
    standard?: Palette['divider'];
    backdrop?: Palette['divider'];
    gradient?: Palette['divider'];
  }
  interface PaletteOptions {
    biscuit?: PaletteOptions['primary'];
    outline?: PaletteOptions['divider'];
    standard?: PaletteOptions['divider'];
    backdrop?: PaletteOptions['divider'];
    gradient?: PaletteOptions['divider'];
  }
}

const theme = createTheme({
  brand: DEFAULT,
  name: 'default',
  typography: {
    fontFamily: 'brandon-grotesque, sans-serif',
    h1: {
      fontSize: 50,
      lineHeight: '60px',
      fontWeight: 420,
      letterSpacing: '-1.5px',
      textAlign: 'center',
    },
    h2: {
      fontSize: 40,
      lineHeight: '48px',
      fontWeight: 420,
      letterSpacing: '-0.5px',
      '@media (max-width:620px)': {
        fontSize: 32,
        lineHeight: '38.4px',
      },
    },
    h3: {
      fontSize: 34,
      lineHeight: '40.80px',
      fontWeight: 420,
    },
    h4: {
      fontWeight: 420,
      fontSize: 24,
      lineHeight: '28.8px',
      letterSpacing: '0.25px',
      '@media (max-width:620px)': {
        fontSize: 22,
        lineHeight: '27.17px',
      },
    },
    h5: {
      fontWeight: 420,
      fontSize: 22,
      lineHeight: '29.48px',
      letterSpacing: '0.25px',
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '32px',
      letterSpacing: '0.25px',
      '@media (max-width:620px)': {
        fontSize: 18,
        lineHeight: '21.6px',
      },
    },
    body1: {
      fontWeight: 400,
      fontFamily: 'aktiv-grotesk',
      fontSize: 18,
      lineHeight: '25.2px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontWeight: 400,
      fontFamily: 'aktiv-grotesk',
      fontSize: 16,
      lineHeight: '20.8px',
      letterSpacing: '0.15px',
    },
    caption: {
      fontWeight: 400,
      fontFamily: 'aktiv-grotesk',
      fontSize: 12,
      lineHeight: '12px',
      letterSpacing: '0.4px',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '28.8px',
      letterSpacing: '0.1px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '22.4px',
      letterSpacing: '0.1px',
    },
  },
  components: {
    // Name of the component
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#F9F9F9 #F9F9F9',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#F9F9F9',
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            width: '6px',
            minHeight: '54px',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
          '.PrivatePickersSlideTransition-root': {
            minHeight: '240px ',
          },
          '.Mui-error': {
            color: 'red',
          },
        },
      },
    },
    MuiButtonBase: {},
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255, 0.8)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontWeight: 500,
          fontFamily: 'aktiv-grotesk',
          textTransform: 'initial',
          borderRadius: '30px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-containedPrimary': {
            background: '#111111',
            '&:hover': {
              backgroundColor: '#3A3A3A',
            },
          },
          '&.MuiButton-containedSecondary': {
            background: '#EB3754',
            '&:hover': {
              backgroundColor: '#CC2336',
            },
          },
          '&.MuiButton-containedBiscuit': {
            background: '#EDE8E0',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
          },
          '&.MuiButton-textBiscuit': {
            color: '#111111',
          },
          '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
            cursor: 'not-allowed',
            pointerEvents: 'unset',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
              cursor: 'not-allowed',
            },
          },
        },
        sizeLarge: {
          fontSize: '16px',
          lineHeight: '26px',
          fontWeight: 700,
          padding: '12px 30px 12px 30px',
          minWidth: '119px',
          minHeight: '50px',
        },
        sizeMedium: {
          fontSize: '15px',
          lineHeight: '24px',
          fontWeight: 500,
          padding: '6px 16px 6px 16px',
          minWidth: '98px',
          minHeight: '38px',
        },
        sizeSmall: {
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '22px',
          padding: '6px 12px 6px 12px',
          minWidth: '39px',
          minHeight: '22px',
        },
      },
    },
    MuiInput: {
      // For ListItem, change this to MuiListItem
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
        input: {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            marginTop: '0px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.MuiInput-input': {
            textAlign: 'left',
          },
          '&.MuiSelect-select': {
            textAlign: 'left',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '12px',
        },
      },
    },
    MuiSelect: {
      // For ListItem, change this to MuiListItem
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#fff',
          boxShadow:
            '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {},
    },
  },
  palette: {
    primary: {
      main: '#111111',
      contrastText: '#FFFFFF',
      dark: '#000000',
      light: '#322A2A',
    },
    secondary: {
      main: '#EB3754',
      contrastText: '#FFFFFF',
      dark: '#CC2336',
      light: '#FF6879',
    },
    info: {
      main: 'rgba(17, 17, 17, 0.87)',
      contrastText: '#ffffff',
      dark: 'rgba(17, 17, 17, 0.87)',
      light: 'rgba(0, 0, 0, 0.5)',
    },
    biscuit: {
      main: '#EDE8E0',
      light: '#FFFFFF',
      contrastText: '#111111',
    },
    text: {
      primary: '#111111',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.35)',
    },
    error: {
      main: '#d32f2f',
    },
    background: {
      paper: alpha('#ffffff', 0.54),
      default: '#fff',
    },
    success: {
      main: '#4CAF50',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    outline: 'rgba(0, 0, 0, 0.12)',
    standard: 'rgba(0, 0, 0, 0.42)',
    backdrop: 'rgba(0, 0, 0, 0.42)',
  },
  status: {
    danger: orange[500],
  },
});

export default theme;
