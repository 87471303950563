import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, Link, useLocation} from 'react-router-dom';
import AngleArrowRight from '../icons/AngleArrowRight';
import {Controls, NavHeader, ProfileContainer} from '../WallaceaHeader/Navigation[Style]';

axios.defaults.baseURL = process.env.BASE_URL;

type Props = {
  loggedIn?: boolean;
  onboarding?: boolean;
  profile?: IProfile;
  showBackButton?: boolean;
}

function Header({
  loggedIn,
  profile
}: Props)  {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentPath, setCurrentPath] = useState<string>('');
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const ToggleMenu = () => setActiveMenu(!activeMenu);

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);

  async function SignOut(): Promise<void> {
    try {
      const result = await axios.post('/api/auth/sign-out', { withCredentials: true, });
      if ((result && result.status === 200) || result?.statusText === 'OK') {
        let expires: string = new Date(Date.now() - 86400 * 1000).toUTCString();
        document.cookie = 'idToken=none; expires=' + expires + ';path=/;';
        setActiveMenu(false);
        window.location.reload();
      }
    } catch (e) {
      console.log('error');
    }
  }

  const HandleMyTicketsClick = () => {
    ToggleMenu();
    navigate('/my-tickets');
  };

  const onProduction = process.env.NODE_ENV === 'production';

  return (
    <NavHeader className={"d-row align-center justify-between"}>
      {
        (currentPath !== '/') ?
        <button
          className={"d-row align-center btn-link header-utility-btn back-btn"}
          onClick={() => onProduction ? window.open('https://wallacealiving.com/events', '_self') : navigate('/')}
        >
          <span style={{ transform: "rotate(180deg)"}} className={"d-col align-center justify-center"}>
            <AngleArrowRight width={16*1.5} height={16*1.5} fill={"var(--green-theme)"} />
          </span>
          <p>Back to all events</p>
        </button> :
        <span></span>
      }
      {
        (loggedIn && profile) ?
        <ProfileContainer>
          <button
            onClick={ToggleMenu}
            className={"dashboard-menu d-row align-center"}
            data-value={"profile-container"}
          >
            <p className={"font-body font-barlow font-underline"}>Dashboard</p>
            <p className={"profile"}>{profile.firstName?.charAt(0)}{profile.lastName?.charAt(0)}</p>
          </button>
          <div className={`menu-container d-col align-start ${activeMenu ? "active" : ""}`}>
            {
              profile?.isAdmin && (
                <button
                  onClick={() => (window.location.href = '/admin/events')}
                  data-value={"admin-dashboard-button"}
                  className={"font-body font-barlow w-100"}
                >
                  Admin
                </button>
              )
            }
            <button
                className={"font-body font-barlow w-100"}
                onClick={HandleMyTicketsClick}
            >
              My Tickets
            </button>
            <button
                className={"font-body font-barlow w-100"}
                onClick={SignOut}
            >
              Sign out
            </button>
          </div>
        </ProfileContainer> :
        <Controls>
          { currentPath !== "/onboarding" && <Link to={'/onboarding'} className={'btn-link header-utility-btn'}>{'Create an Events account'}</Link>}
          { currentPath !== "/signin" && <Link to={'/signin'} className={'btn-outline flip-over right'}>{'Sign In'}</Link>}
        </Controls>
      }
      { activeMenu && <span className={"overlay"} onClick={ToggleMenu} /> }
    </NavHeader>
  )
}

export default Header;
