import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import eventsReducer from './features/events/eventsSlice';
import bookingReducer from './features/booking/bookingSlice';
import profileReducer from './features/profile';

export const store = configureStore({
  reducer: {
    events: eventsReducer,
    booking: bookingReducer,
    profile: profileReducer,
  },
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
