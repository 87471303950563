import React from 'react';
import {Box, styled} from '@resi/beeem-ui-components';
import Times from '../icons/times.svg';
import Play from '../icons/play.svg';

interface IImageProps {
  image: string;
  index?: number;
  onClick: (index: number | null) => void;
}
const Image = ({image, index, onClick}: IImageProps) => {
  return (
    <Box
      onClick={() => onClick(index || index === 0 ? index : null)}
      sx={{
        position: 'relative',
        width: '204px',
        height: '124px',
        borderRadius: '17px',
        background: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: '20px 15px 20px 0',
        boxShadow: ' 0px 4px 40px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        backgroundColor: '#111111',
      }}
    >
      <Box
        sx={{
          width: '34px',
          height: '34px',
          background: '#FFFFFF',
          borderRadius: '50%',
          boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          cursor: 'pointer',
        }}
      >
        <Box component="img" src={Times} />
      </Box>

      {!index && index !== 0 && (
        <Box
          sx={{
            width: '34px',
            height: '34px',
            background: '#EB3754',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '45px',
            left: '85px',
            cursor: 'pointer',
          }}
        >
          <Box component="img" src={Play} />
        </Box>
      )}
    </Box>
  );
};
export default Image;
