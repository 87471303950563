import {categories} from '../data';
import {IGetYouTubeThumbnail} from './types';
import {BookingEvent} from '../features/profile';

export const parseDateTimeFromUTCToLocale = (date: string, time: string) => {
  const splitUTCDate = localeDate(date).split('/');
  return new Date(`
    ${splitUTCDate[2]}/${splitUTCDate[1]}/${splitUTCDate[0]} ${time}
  `);
};

export const getFutureBookingEvents = (bookingEvents: BookingEvent[]) =>
  bookingEvents.filter(b => {
    const bookingEventDateTime = parseDateTimeFromUTCToLocale(b.date, b.startTime);
    return bookingEventDateTime >= new Date();
  });

export const getHistoricBookingEvents = (bookingEvents: BookingEvent[]) =>
  bookingEvents.filter(b => {
    const bookingEventDateTime = parseDateTimeFromUTCToLocale(b.date, b.startTime);
    return bookingEventDateTime < new Date();
  });

export const filterEventResults = (events: IEvent[], filter: IFilters) => {
  let results = events;

  if (filter.categories) {
    if (Object.values(filter.categories).every(x => !x)) {
      results = results;
    } else {
      results = results.filter(event =>
        filter.categories ? filter.categories[event.category] : true
      );
    }
  }

  if (filter.residentOnly) {
    results = results.filter(event => !event.isPublic);
  }

  if (filter.price || filter.price === 0) {
    results = results.filter(event =>
      filter.price || filter.price === 0 ? filter.price >= event.price : true
    );
  }

  if (filter.startDate) {
    // if the search date is for today, we want to also respect the current time, so that it doesn't show the
    // current days past events. If it is a future date, we set it to midnight so that it captures the all
    // events for that given day.
    const filterStartDate =
      filter.startDate > new Date()
        ? new Date(filter.startDate.setHours(0, 0, 0, 0))
        : filter.startDate;
    results = results.filter(
      event =>
        filter.startDate &&
        filterStartDate <= parseDateTimeFromUTCToLocale(event.startDate, event.startTime)
    );
  }

  if (filter.endDate) {
    results = results.filter(
      event =>
        filter.endDate &&
        filter.endDate >= parseDateTimeFromUTCToLocale(event.startDate, event.startTime)
    );
  }

  if (filter.time) {
    results = results.filter(event =>
      filter.time ? parseInt(filter.time) <= parseInt(stripSeconds(event.startTime)) : true
    );
  }

  return results.filter(event => event.status === 'PUBLISHED');
};

export const filterEvents = (events: IEvent[], resultsFilter?: IResultsFilter) => {
  let results: IEvent[] | [] = [];

  if (resultsFilter?.search) {
    const search = resultsFilter?.search;
    results = events?.filter(
      event =>
        event.title?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        event.location?.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  } else {
    results = events;
  }

  if (resultsFilter?.sort) {
    if (resultsFilter.sort === 'price') {
      results.sort((a, b) => b.price - a.price);
    }
    if (resultsFilter.sort === 'date') {
      results.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    }
  }

  return results.length ? results : [];
};

export const dateToUtc = (date: Date | string) => {
  if(typeof date === "string") {
    const dateAsAString = Date.UTC(
      new Date(date).getUTCFullYear(),
      new Date(date).getUTCMonth(),
      new Date(date).getUTCDate(),
      new Date(date).getUTCHours(),
      new Date(date).getUTCMinutes(),
      new Date(date).getUTCSeconds()
    );

    return new Date(dateAsAString)
  }

  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(utcDate);
};

export const localeDate = (date: string | Date) => {
  return new Date(date).toLocaleDateString('en-GB');
};

export const convertTableDate = (date: string) => {
  const splitDates = localeDate(date).split('/');
  return `${splitDates[0]}-${splitDates[1]}-${splitDates[2]}`;
};

export const convertDate: (date: string) => string = (date: string) => {
  const convertDate: string =  new Intl.DateTimeFormat('UTC', {
    weekday: 'long',
    month: 'short',
    day: 'numeric'
  }).format(new Date(date));

  return `${convertDate.split('202')[0]}`;
};

export const convertDateMonthLong = (date: string) => {
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const d = new Date(date);
  let dayName = days[d.getDay()];
  let month = d.toLocaleString('en-GB', {month: 'long'});
  let day = d.getDate();

  return `${dayName} ${day} ${month}`;
};

export const getMaxPrice = (events: IEvent[]) => {
  if (!events.length) return 0;
  const max = events.reduce(function (prev, current) {
    return prev.price > current.price ? prev : current;
  }); //returns object

  return max.price;
};

export const getCategory = (name: string) => {
  const cat = categories.find(category => category.name === name);

  return cat?.label ? cat?.label : '';
};

export const checkValidation = (event: IEventNew, edit: boolean) => {
  if (
    !event.title ||
    !event.host ||
    !event.startTime ||
    !event.startDate ||
    !event.endDate ||
    !event.endTime ||
    !event.location ||
    !event.description ||
    !event.category ||
    !event.type ||
    !event.termsandconditions
  )
    return false;

  return true;
};
export const getFormattedDate = (date: Date | null) => {
  if (!date) return '';

  let year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
};

export const checkPassword = (password?: string) => {
  if (!password) return false;
  let rules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,30}$/;

  if (password.match(rules)) return true;

  return false;
};

export const isEmailValid = (email?: string) => {
  if (!email) return false;

  let re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isPhoneValid = (num: string | number | undefined) => {
  if (!num) return false;

  return /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/.test(
    num.toString().replace(/\s/g, '')
  );
};

export const getTicketNumbers = (event: IEvent) => {
  let values = [];
  let ticketsAvailable = 0;

  /**
   * The business logic for showing the number of tickets a user can purchase is as follows:
   * For Free Events with an Attendance Limit:
   *     - The user can book a maximum of 4 tickets subject to availablility
   * For Free Events with no Attendance Limit
   *     - The user can book up to 4 tickets.
   * For Paid Events with an attendance limit:
   *     - The user can book as many tickets that are available
   *
   * Notes:
   *     - Attendance Limit is stored as "capacity" and is optional.
   *     - Tickets sold is stored as "numberOfAttendees".
   *     - After an order is made, the capacity is reduced and the numberOfAttendees is increased.
   */

  if (!!event.price) {
    // PAID EVENT: If the event has an attendance limit (capacity), then use that. Otherwise default to a max number of 50 tickets
    ticketsAvailable = event.capacity !== null ? event.capacity : 50;
  } else if (event.capacity) {
    // FREE EVENT: If the event is free and has an attendance limit (capacity), then display it if less than 4, otherwise default to 4
    ticketsAvailable = event.capacity <= 4 ? event.capacity : 4;
  } else {
    // FREE EVENT: If the event is free and there is no attendance limit (capacity), then default to maximum of 4
    ticketsAvailable = 4;
  }

  for (let x = 0; x <= ticketsAvailable; x++) {
    values.push({name: `${x}`, label: `${x}`});
  }

  return values;
};

export const timeToNumber = (time: string) => {
  let number = time.split(':')[0];

  return parseInt(number);
};

export const getValidEndTimes = (
  start: Date | undefined,
  end: Date | undefined,
  startTime: string | undefined,
  times: Category[]
) => {
  if (!start || !end || !startTime || end > start) return times;

  return times.filter(time =>
    startTime ? timeToNumber(time.name) > timeToNumber(startTime) : true
  );
};

export const isEndTimeValid = (
  start: Date | undefined,
  end: Date | undefined,
  startTime: string | undefined,
  endTime: string | undefined
) => {
  if (start && end && end > start) return true;

  if (startTime && endTime && timeToNumber(endTime) > timeToNumber(startTime)) return true;

  return false;
};

export const stripSeconds = (time: string) => {
  return time.substr(0, time.indexOf(':') + 3);
};

export const getErrorMessage = (profile: IProfile) => {
  const firstName = profile.firstName ? profile.firstName : '';
  const lastName = profile.lastName ? profile.lastName : '';

  if (
    !RegExp(/^\p{L}/, 'u').test(firstName) ||
    !RegExp(/^\p{L}/, 'u').test(lastName) ||
    !isEmailValid(profile.email) ||
    !isPhoneValid(profile.phone)
  ) {
    return 'Please enter a valid first name, last name, email and phone number';
  } else if (
    !RegExp(/^\p{L}/, 'u').test(firstName) &&
    !RegExp(/^\p{L}/, 'u').test(lastName) &&
    !isEmailValid(profile.email)
  ) {
    return 'Please enter a valid first name, last name and email';
  } else if (!RegExp(/^\p{L}/, 'u').test(firstName) && !RegExp(/^\p{L}/, 'u').test(lastName)) {
    return 'Please enter a valid first name and last name';
  }
  if (!RegExp(/^\p{L}/, 'u').test(firstName) && !isPhoneValid(profile.phone)) {
    return 'Please enter a valid first name and phone number';
  }
  if (!RegExp(/^\p{L}/, 'u').test(firstName) && !isEmailValid(profile.email)) {
    return 'Please enter a valid first name and email';
  }
  if (!RegExp(/^\p{L}/, 'u').test(lastName) && !isPhoneValid(profile.phone)) {
    return 'Please enter a valid last name and phone number';
  }
  if (!RegExp(/^\p{L}/, 'u').test(lastName) && !isEmailValid(profile.email)) {
    return 'Please enter a valid last name and email';
  }

  return '';
};

export const getPasswordErrorMessage = (profile: IProfile) => {
  if (!checkPassword(profile.password)) return 'Please enter a valid password';
  if (profile.password !== profile.confirmPassword) return 'Please confirm your password';
  if (!profile.terms) return 'Please agree to the privacy policy';

  return '';
};

export const getYoutubeThumbnail = ({url, version = 'maxresdefault'}: IGetYouTubeThumbnail) => {
  const match = url.match(
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  );
  return url.includes('youtu') && match && match[7].length == 11
    ? `https://img.youtube.com/vi/${match[7]}/${version}.jpg`
    : false;
};

export const checkIfValidVideoUrl = (url: string | undefined) => {
  if (!url) return;

  const isYoutube =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(
      url
    );
  const isVimeo =
    /(https?:\/\/)?(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/.test(
      url
    );

  return isYoutube || isVimeo;
};

type utmTags = {
  utmId?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
};

/**
 * checkUrlForUtmTags
 * This function checks the current url and brings back an object with any of the specified UTM Tags on it
 * @returns utmTags
 */
export const checkUrlForUtmTags = (): utmTags => {
  const utmTags = ['utmId', 'utmSource', 'utmMedium', 'utmCampaign', 'utmTerm'];
  const query = new URLSearchParams(window.location.search);

  return Object.assign({}, ...utmTags.map(tag => query.get(tag) && {[tag]: query.get(tag)}));
};

export const formatMoney = (money: number) =>
  new Intl.NumberFormat('en-gb', {style: 'currency', currency: 'GBP'}).format(money);

/**
 * This function takes count and word, and returns the pluralised version of the word if count is greater than 1
 */
export const pluraliseWord = (count: number, word: string) => {
  return count === 1 ? word : `${word}s`;
};
