import RemCalculator from '../../Utility/RemCalculator';

type Props<S extends string, N = number> = {
    className?: S;
    width: N;
    height: N;
    fill?: S;
}

function AngleArrowRight({className, width, height, fill}: Props<string>): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width={RemCalculator(width)} height={RemCalculator(height)} className={className} fill={fill}>
            <path d="M530-481 332-679l43-43 241 241-241 241-43-43 198-198Z"/>
        </svg>
    );
}

export default AngleArrowRight;