import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Typography} from '@resi/beeem-ui-components';
import Side from '../../components/side';
import Table from '../../components/table';
import {RootState} from '../../store';
import Modal from '../../components/modal';
import {ReactComponent as Delete} from '../../components/icons/delete.svg';
import {ReactComponent as Times} from '../../components/icons/times.svg';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {removeEvents} from '../../features/events/eventsSlice';
import RemCalculator from '../../Utility/RemCalculator';

axios.defaults.baseURL = process.env.BASE_URL;

interface IMainProps {
  children?: React.ReactNode;
}

const AdminEvents = ({children}: IMainProps) => {
  const events: IEvent[] | null = useSelector((state: RootState) => state.events.value);
  const [deleteItems, setDeleteItems] = React.useState<string[]>([]);
  const [deleteType, setDeleteType] = React.useState<'single' | 'bulk'>('single');
  const [itemDelete, setItemDelete] = React.useState<string>();
  const [recurringId, setRecurringId] = React.useState<string>();
  const [deletedNotificationText, setDeletedNotificationText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addItemToDelete = (id: string) => {
    let ids = [...deleteItems];

    const index = ids.findIndex(value => value === id);
    if (index === -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    setDeleteItems(ids);
  };

  const deleteBulk = () => {
    setDeleteType('bulk');
    setOpen(true);
  };
  const deleteSingle = (id: string, recurringId?: string) => {
    setRecurringId(recurringId ? recurringId : undefined);
    setItemDelete(id);
    setDeleteType('single');
    setOpen(true);
  };
  const confirmDelete = async (isRecurring?: boolean) => {
    setOpen(false);
    const url = isRecurring ? `/api/events/recurring/delete/${recurringId}` : `/api/events/delete/${itemDelete}`;

    try {
      const result = await axios.delete(url);

      if (result.status === 200) {
        setDeletedNotificationText( `Event has successfully been deleted.`);

        if (itemDelete) dispatch(removeEvents([itemDelete]));

        window.location = window.location;
      }
    } catch (e) {
      console.log('error');
    }
  }

  return (
    <Box
      sx={{
        padding: '20px 0',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        background: '#FFFFFF',
      }}
    >
      <Side />
      <Box sx={{maxWidth: '1344px', margin: 'auto', padding: {xs: '0px 20px', lg: '0 70px'}}}>
        <Box
          sx={{
            textAlign: 'left',
            '.MuiTypography-body1': {textAlign: 'left'},
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body1" sx={{color: 'rgba(0, 0, 0, 0.6)'}}>
            <span style={{textDecoration: 'underline'}}>Events</span> /{' '}
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '40px 0'}}>
            <Typography variant="h2" color="#111111" sx={{margin: '0 20px 0 0'}}>
              Events
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{textTransform: 'initial', '&:hover': {backgroundColor: '#01788B'}}}
              onClick={() => navigate('/admin/new')}
              data-value={"add-new-event-button"}
            >
              + Add new event
            </Button>
          </Box>
        </Box>

        {events && events?.length > 0 && (
          <Table
            data={events}
            itemsToDelete={deleteItems}
            addItemToDelete={(id: string) => addItemToDelete(id)}
            deleteBulk={deleteBulk}
            deleteSingleEvent={deleteSingle}
            alertMessage={deletedNotificationText}
          />
        )}

        <Modal open={open} handleOpen={() => setOpen(true)} handleClose={() => setOpen(false)}>
          <Box>
            <Typography variant="h5" sx={{textAlign: 'left'}}>
              You are about to delete the event forever. This is irreversible and will delete all associated bookings. Are you sure you want to continue?
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', marginTop: RemCalculator(16*2)}}>
              <Button
                startIcon={<Delete />}
                sx={{
                  textDecoration: 'underline',
                  padding: '6px 0',
                  '&:hover': {backgroundColor: 'initial', color: '#111111'},
                }}
                onClick={() => confirmDelete()}
              >
                {recurringId ? 'Delete occurrence' : 'Delete Event'}
              </Button>
              {
                recurringId &&
                <Button
                  startIcon={<Delete />}
                  sx={{
                    textDecoration: 'underline',
                    padding: '6px 0',
                    '&:hover': {backgroundColor: 'initial', color: '#111111'},
                  }}
                  onClick={() => confirmDelete(true)}
                >
                  Delete series
                </Button>
              }
              <Button
                startIcon={<Times />}
                sx={{
                  textDecoration: 'underline',
                  '&:hover': {backgroundColor: 'initial', padding: '6px 0', color: '#111111'},
                }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AdminEvents;
