import React from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  CircularProgress,
  Typography,
  TextField,
  styled,
  Link,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@resi/beeem-ui-components';
import {useNavigate} from 'react-router-dom';
import {homehub} from '@resi/beeem-ui-theme';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from '@reduxjs/toolkit';

import {RootState} from '../../store';
import {isEmailValid} from '../../utils';

axios.defaults.baseURL = process.env.BASE_URL;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const ContainedField = styled(TextField)(() => ({
  '.MuiFormLabel-root': {
    '&.MuiInputLabel-root': {
      top: '0px',
    },
  },
}));

const SignIn = () => {
  const [emailAddress, setEmailAddress] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [screen, setScreen] = React.useState(1);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);

  const signIn = async () => {
    setIsLoggingIn(true);
    if (!emailAddress && !password) {
      setErrorMessage('Please enter both your email and password');
    } else if (!emailAddress) {
      setErrorMessage('Please enter your email');
    } else if (!password) {
      setErrorMessage('Please enter your password');
    } else {
      setErrorMessage('');
      const url = `/api/auth/sign-in`;

      let result;
      try {
        result = await axios.post(
          url,
          {emailAddress, password},
          {
            withCredentials: true,
          }
        );
      } catch (e: any) {
        setIsLoggingIn(false);
        setErrorMessage(e.response?.data?.message);
      }
      setIsLoggingIn(false);
      if ((result && result.status === 200) || result?.statusText === 'OK') {
        window.location.href = '/';
      }
    }
  };

  const resetPassword = async () => {
    if (!isEmailValid(emailAddress)) {
      setErrorMessage('Please enter a valid email address');
    } else {
      const url = `/api/auth/forgot-password`;
      axios
        .post(
          url,
          {emailAddress},
          {
            withCredentials: true,
          }
        )
        .then(result => {
          if (result?.status === 200 || result?.statusText === 'OK') {
            setScreen(3);
          }
        })
        .catch(error => {});
    }
  };

  return (
    <Box
      sx={{
        width: '529px',
        maxWidth: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: {xs: '', md: '100px'},
        marginBottom: '200px',
        padding: '20px',
        ['@media (min-width:1200px)']: {
          padding: '0px',
        },
      }}
    >
      <Typography
        variant="h3"
        color="text.primary"
        textTransform="uppercase"
        sx={{marginTop: '60px', marginBottom: '50px'}}
      >
        {screen === 1 ? 'Sign in' : screen === 2 ? 'Reset password' : 'Check your email'}
      </Typography>
      {errorMessage && (
        <Alert severity="error" sx={{boxShadow: 'none', marginBottom: '20px', fontWeight: 500}}>
          {errorMessage}
        </Alert>
      )}
      {(screen === 1 || screen === 2) && (
        <ContainedField
          variant="outlined"
          type="email"
          label="Email"
          value={emailAddress}
          data-value={"email"}
          sx={{marginBottom: '20px'}}
          onChange={event => setEmailAddress(event.target.value)}
          color="secondary"
        />
      )}
      {screen === 1 && (
        <ContainedField
          variant="outlined"
          type="password"
          label="Password"
          value={password}
          data-value={"password"}
          onChange={event => setPassword(event.target.value)}
          color="secondary"
        />
      )}
      {screen === 1 ? (
        <Typography
          variant="body1"
          color="#000000"
          sx={{textAlign: 'left', marginTop: '41px', marginBottom: '34px'}}
        >
          Trouble logging in?{' '}
          <Link color="#000000" onClick={() => setScreen(2)}>
            Reset password
          </Link>
        </Typography>
      ) : screen === 2 ? (
        <Link
          color="#000000"
          onClick={() => setScreen(1)} sx={{textAlign: 'left'}}
        >
          Sign In
        </Link>
      ) : (
        <Typography
          variant="body1"
          color="#000000"
          sx={{textAlign: 'left', marginTop: '41px', marginBottom: '34px'}}
        >
          {`We have sent a reset email to ${emailAddress} if the account exists. If you can’t see the email please check your spam or `}
          <Link color="#000000" onClick={() => setScreen(2)}>
            Try again
          </Link>
        </Typography>
      )}
      {screen === 1 && (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={<span>Keep me logged in</span>}
          />
        </FormGroup>
      )}
      {(screen === 1 || screen === 2) && (
        <button
          disabled={isLoggingIn}
          style={{width: '408px', maxWidth: '100%', margin: '50px auto'}}
          onClick={screen === 1 ? signIn : resetPassword}
          className={'btn-primary font-body'}
          data-value={"submit"}
        >
          {isLoggingIn ? (
            <CircularProgress style={{color: homehub.palette.primary.contrastText}} />
          ) : (
            'Submit'
          )}
        </button>
      )}
    </Box>
  );
};

export default SignIn;
