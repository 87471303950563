import * as React from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  styled,
  TextField as Text,
  MenuItem
} from '@resi/beeem-ui-components';
import {useSelector, useDispatch} from 'react-redux';
import {checkPassword, isPhoneValid, getTicketNumbers, pluraliseWord} from '../../../../utils';
import SelectVariants from '../../../../components/selectvariants';
import Switch from '../../../../components/switch';
import {SelectChangeEvent} from '@mui/material/Select';

import {RootState} from '../../../../store';
import {setBooking} from '../../../../features/booking/bookingSlice';
import { TITLES } from '../../../../constants';
import RemCalculator from '../../../../Utility/RemCalculator';

interface IGuestProps {
  errors: boolean;
  event: IEvent;
  loginError: boolean;
  makeBooking: () => void;
  isSending: boolean;
}

const Guest = ({errors, event, loginError, makeBooking, isSending}: IGuestProps) => {
  const dispatch = useDispatch();
  const booking = useSelector((state: RootState) => state.booking.value);
  const profile = useSelector((state: RootState) => state.profile.value);

  const handleChange = (event: React.BaseSyntheticEvent | SelectChangeEvent) => {
    dispatch(setBooking({name: event.target.name, value: event.target.value}));
  };

  const ItemList = styled(ListItem)(() => ({
    display: 'list-item',
    padding: 0,
  }));

  return (
    <Box>
      <Typography variant="h3" sx={{marginTop: '50px'}} color={"var(--green-theme)"}>
        Continue as guest
      </Typography>
      <Typography variant="body1" sx={{margin: '50px 70px'}} color={"var(--green-theme)"}>
        Continue your booking as a guest. You can create an account at the same time if you'd like.
      </Typography>
      {!event.price && (
        <Typography variant="body1" sx={{margin: '20px 0', textAlign: 'left'}} color="var(--green-theme)">
          This event is free to attend.
        </Typography>
      )}
      <Grid container spacing="25px">
        <Grid item xs={4} md={2}>
          <Text
            name="salutation"
            id="dropdown"
            value={booking.salutation || 'Mr'}
            color="secondary"
            onChange={event =>
              dispatch(setBooking({name: 'salutation', value: event.target.value}))
            }
            label="Title"
            sx={{
              width: '100%',
              '& #dropdown': {
                fontSize: '16px',
                paddingLeft: '10px',
              },
            }}
            select
            data-name={"salutation"}
          >
            {TITLES.map(({label, value}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Text>
        </Grid>
        <Grid item xs={12} md={5}>
          <Text
            value={booking.firstName ? booking.firstName : ''}
            name="firstName"
            required={true}
            label="First name"
            onChange={handleChange}
            variant="standard"
            error={errors && (!booking.firstName || /[^a-z]/i.test(booking.firstName))}
            data-name={"firstName"}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Text
            value={booking.lastName ? booking.lastName : ''}
            name="lastName"
            required={true}
            label="Last name"
            onChange={handleChange}
            variant="standard"
            error={errors && (!booking.lastName || /[^a-z]/i.test(booking.lastName))}
            data-name={"lastName"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Text
            value={booking.email ? booking.email : ''}
            name="email"
            required={true}
            label="Email address"
            onChange={handleChange}
            variant="standard"
            error={errors && !booking.email}
            data-name={"email"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Text
            value={booking.confirmEmail ? booking.confirmEmail : ''}
            name="confirmEmail"
            required={true}
            label="Confirm Email address"
            onChange={handleChange}
            variant="standard"
            error={errors && (booking.confirmEmail !== booking.email || !booking.confirmEmail)}
            data-name={"confirmEmail"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Text
            value={booking.phoneNumber ? booking.phoneNumber : ''}
            name="phoneNumber"
            id="phoneNumber"
            required={true}
            label="Phone number"
            onChange={event =>
              dispatch(
                setBooking({name: 'phoneNumber', value: event.target.value.replace(/[^0-9+]/g, '')})
              )
            }
            type="tel"
            variant="standard"
            error={errors && !isPhoneValid(booking.phoneNumber)}
            helperText={
              errors && !isPhoneValid(booking.phoneNumber) && 'Phone number must be 11 characters'
            }
            data-name={"phoneNumber"}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="body1" sx={{textAlign: 'left', color: 'var(--green-theme)', fontWeight: 500}}>
            Tickets required?
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectVariants
            id="selectTickets"
            values={getTicketNumbers(event)}
            label=""
            name="tickets"
            handleChange={event => handleChange(event)}
            value={booking.tickets ? booking.tickets : '0'}
            error={errors && (!booking.tickets || booking.tickets === '0')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" sx={{textAlign: 'left', color: 'var(--green-theme)', fontWeight: 500}}>
            Add a note for the host
          </Typography>
          <Text
            value={booking.notes ? booking.notes : ''}
            name="notes"
            label="Add message"
            onChange={handleChange}
            data-name={"notes"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Switch
            label="Create an account"
            name="createAccount"
            onChange={() =>
              dispatch(setBooking({name: 'createAccount', value: !booking.createAccount}))
            }
            checked={booking.createAccount ? booking.createAccount : false}
            fontWeight={500}
            fontSize="18px"
            color="var(--green-theme)"
          ></Switch>
        </Grid>
        {booking.createAccount && (
          <>
            <Grid item xs={12} md={12}>
              {
                booking.confirmPassword !== booking.password ?
                <Typography
                  color={"error"}
                  textAlign={"left"}
                  padding={`0 0 ${RemCalculator(16*2)}`}
                  fontFamily={"Barlow"}
                >
                  {"Please ensure passwords match"}
                </Typography>
                :
                <Typography
                  color={"error"}
                  textAlign={"left"}
                  padding={`0 0 ${RemCalculator(16*2)}`}
                  fontFamily={"Barlow"}
                >
                  {"Please ensure password requirements are met"}
                </Typography>
              }
              <Text
                value={booking.password ? booking.password : ''}
                name="password"
                label="Password"
                onChange={handleChange}
                sx={{marginTop: '-5px'}}
                type="password"
                error={
                  (errors && !booking.password) || (errors && !checkPassword(booking.password))
                }
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{paddingTop: '20px'}}>
              <Text
                value={booking.confirmPassword ? booking.confirmPassword : ''}
                name="confirmPassword"
                label="Confirm your password"
                onChange={handleChange}
                type="password"
                error={
                  (errors && !booking.confirmPassword) ||
                  booking.confirmPassword !== booking.password
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body1"
                color="var(--green-theme)"
                sx={{textAlign: 'left', marginTop: '20px'}}
              >
                Password requirements:
              </Typography>
              <List sx={{listStyleType: 'disc', pl: 4, color: 'var(--green-theme)'}}>
                <ItemList>One lower case character </ItemList>
                <ItemList>One upper case character </ItemList>
                <ItemList>One number </ItemList>
                <ItemList>10 characters minimum</ItemList>
              </List>
            </Grid>
          </>
        )}
        {event.price > 0 && parseInt(booking.tickets || '0') > 0 && (
          <>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="var(--green-theme)" sx={{textAlign: 'left'}}>
                Order summary
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color="var(--green-theme)" sx={{textAlign: 'left', fontWeight: 500}}>
                {booking.tickets || '0'} x General admission{' '}
                {pluraliseWord(parseInt(booking.tickets || '0'), 'ticket')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                color="var(--green-theme)"
                sx={{textAlign: 'right', fontWeight: 500}}
              >
                £{event.price}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color="var(--green-theme)" sx={{textAlign: 'left', fontWeight: 500}}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                color="var(--green-theme)"
                sx={{textAlign: 'right', fontWeight: 500}}
              >
                £{event.price * parseInt(booking.tickets ? booking.tickets : '0')}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          <FormGroup sx={{marginTop: '10px'}}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{color: errors && !booking.terms ? 'red' : 'var(--green-theme)'}}
                  checked={booking.terms ? booking.terms : false}
                  name={"privacyPolicy"}
                  onChange={event =>
                    dispatch(setBooking({name: 'terms', value: !booking.terms}))
                  }
                />
              }
              label={
                <span className={"font-green"}>
                  I have read and accept the{' '}
                  <Link
                    color="primary.dark"
                    href="https://wallacealiving.com/privacy-policy"
                    target="_blank"
                    className={"font-green"}
                  >
                    privacy policy.
                  </Link>
                </span>
              }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={booking.marketing ? booking.marketing : false}
                  name={"marketingOptIn"}
                  onChange={event =>
                    dispatch(setBooking({name: 'marketing', value: !booking.marketing}))
                  }
                />
              }
              label={<span className={"font-green"}>I’m happy to receive marketing communications</span>}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
          {loginError && (
            <Alert
              severity="error"
              sx={{width: '100%', textAlign: 'center', boxShadow: 'none', marginBottom: '20px'}}
            >
              An account exists for this email.{' '}
              <span style={{textDecoration: 'underline'}}>Sign in to make a booking</span>
            </Alert>
          )}
          <button
            onClick={makeBooking}
            disabled={isSending}
            className={"btn-primary font-body"}
            data-name={"bookEventButton"}
          >
            {isSending ? 'Sending' : event.price ? 'Book now' : ' Verify email to book'}
          </button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Guest;
