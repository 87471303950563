import React from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Alert, Box, Typography} from '@resi/beeem-ui-components';
import Personal from './steps/personal';
import Password from './steps/password';
import {getErrorMessage, getPasswordErrorMessage} from '../../utils';

import {RootState} from '../../store';
import RemCalculator from '../../Utility/RemCalculator';
import PrepareUTMData from '../../Utility/UTMParamsTracker/PrepareUTMData';

axios.defaults.baseURL = process.env.BASE_URL;

const Onboarding = () => {
  const profile = useSelector((state: RootState) => state.profile.value);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [step, setStep] = React.useState(1);
  const navigate = useNavigate();

  const handleClick = async () => {
    setErrorMessage('');
    if (step === 1) {
      const error = getErrorMessage(profile);

      if (error) {
        setErrorMessage(error);
      } else {
        setStep(step + 1);
        window.scrollTo(0, 0);
      }
    } else {
      const error = getPasswordErrorMessage(profile);
      if (error) {
        setErrorMessage(error);
      } else {
        const url = `/api/events/sign-up`;

        let body = {
          salutation: profile.salutation || 'Mr',
          firstName: profile.firstName,
          lastName: profile.lastName,
          emailAddress: profile.email,
          password: profile.password,
          confirmPassword: profile.confirmPassword,
          phoneNumber: profile.phone?.replace('+', ''),
          marketingOptIn: !!profile.marketing,
          ...PrepareUTMData(false),
        };

        try {
          const result = await axios.post(url, body, {
            withCredentials: true,
          });

          if (result.status === 200) {
            navigate('/loading');
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            setErrorMessage(e.response?.data?.message || e.message);
            setStep(1);
          } else {
            setErrorMessage((e as Error).message);
          }
        }
      }
    }
  };

  return (
    <Box marginBottom="200px">
      <Box sx={{maxWidth: '660px', margin: 'auto', padding: '0 20px'}}>
        <Typography
          variant="h2"
          sx={{marginBottom: '0px', marginTop: {xs: '20px', md: '40px'}}}
          color="var(--green-theme)"
          textTransform="uppercase"
          fontFamily={"Oswald"}
        >
          Create an account
        </Typography>
        {step === 1 ? <Personal profile={profile} step={step} /> : <Password profile={profile} />}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <button
          onClick={() => handleClick()}
          className={"btn-primary font-body"}
          style={{ marginTop: RemCalculator(64) }}
          data-name={"next"}
        >
          {step !== 2 ? 'Next' : 'Finish setting up your account'}
        </button>
      </Box>
    </Box>
  );
};

export default Onboarding;
