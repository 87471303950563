import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import {Box} from '@resi/beeem-ui-components';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Text from '../textfield';

interface IDatePickerTextFieldProps {
  value: Date | null;
  label: string;
  name: string;
  onChange: (event: IDateEvent) => void;
  minDate?: Date;
  maxDate?: Date;
  error?: boolean;
  required?: boolean;
  fontWeight?: number;
  marginTop?: string;
  readonly?: boolean;
  dataValue?: string;
}

const DatePickerTextField = ({
  value,
  label,
  name,
  minDate,
  maxDate,
  error,
  onChange,
  required,
  fontWeight,
  marginTop,
  readonly,
  dataValue
}: IDatePickerTextFieldProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Box
        sx={{
          '.Mui-error': {
            '.MuiSvgIcon-root': {
              color: '#d32f2f',
            },
          },
          marginTop: marginTop ? marginTop : '20px',
          '.MuiButtonBase-root': {color: '#000'},
          '.MuiFormLabel-root': {
            color: '#000',
            ...(fontWeight && {fontWeight: fontWeight}),
            '&.Mui-error': {color: '#d32f2f'},
          },
        }}
      >
        <DatePicker
          label={label}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={newDate => {
            onChange({target: {name: name, value: newDate}});
          }}
          readOnly={readonly}
          renderInput={params => (
            <Text
              {...params}
              variant="standard"
              error={error}
              required={required}
              data-value={dataValue}
              sx={{caretColor: 'transparent'}}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerTextField;
