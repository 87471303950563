import React from 'react';
import Dropzone from './Dropzone';
import {Box, styled} from '@resi/beeem-ui-components';

const Container = styled(Box)(({theme}) => ({
  width: '100%',
  background: '#EEF9FB',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  flexDirection: 'row',
  minHeight: '164px',
  [theme.breakpoints.down('sm')]: {
    background: 'none',
    border: 'none',
    minHeight: '60px',
  },
}));

interface IImageUploaderProps {
  onDrop: (files: File[]) => void;
}
const ImageUploader = ({onDrop}: IImageUploaderProps) => {
  return (
    <Container>
      <Dropzone onDrop={onDrop} />
    </Container>
  );
};
export default ImageUploader;
