import React from 'react';
import {Box, Button, CircularProgress, Divider, Link, Typography} from '@resi/beeem-ui-components';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Dialog from '../../components/dialog';
import {RootState} from '../../store';
import {cancelBooking} from '../../features/booking/bookingSlice';
import {AppThunkDispatch} from '../../App';
import {ReactComponent as BackArrow} from '../../components/icons/ArrowBack.svg';

interface IEventProps {
  events: IEvent[];
}

const CancelBooking = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const {id} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const emailAddress = searchParams.get('emailAddress');
  const title = searchParams.get('title');
  const userBookings = useSelector((state: RootState) => state.booking);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleCancelBooking = () => {
    if (id && emailAddress) {
      dispatch(
        cancelBooking({
          bookingNumber: id,
          emailAddress,
        })
      );
    }
  };

  const handleClose = () => {
    onProduction ? window.open('https://wallacealiving.com/events', '_self') : navigate('/');
  };

  const onProduction = process.env.NODE_ENV === 'production';

  return (
    <>
      {userBookings.bookingCancelled ? (
        <>
          <Dialog
            open={open}
            handleClose={() => {
              handleClose();
            }}
          >
            <Box sx={{width: '100%', margin: 'auto'}}>
              <Typography
                variant="h2"
                color="error"
                sx={{marginTop: '0px', marginBottom: '30px', textAlign: 'left'}}
              >
                {`Booking for ${title} has been cancelled`}
              </Typography>
              <Divider />
              <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px'}}>
                <Button variant="contained" onClick={handleClose}>
                  Back to events
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            open={open}
            handleClose={() => {
              handleClose();
            }}
          >
            <Box sx={{width: '100%', margin: 'auto'}}>
              <Typography
                variant="h2"
                color="error"
                sx={{marginTop: '0px', marginBottom: '30px', textAlign: 'left'}}
              >
                {`Cancel order for ${title}`}
              </Typography>
              <Divider />
              <Typography
                variant="body2"
                color="#202020"
                sx={{marginTop: '20px', textAlign: 'left', fontWeight: 600}}
              >
                Are you sure you want to cancel this booking?
              </Typography>

              {userBookings.failed && (
                <>
                  <Divider sx={{marginTop: '20px'}} />
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{marginTop: '20px', marginBottom: '30px', textAlign: 'left'}}
                  >
                    There was a problem cancelling your ticket at this time. Please keep the booking
                    and email{' '}
                    <Link color="error" href="mailto:events@wallacealiving.com">
                      events@wallacealiving.com
                    </Link>{' '}
                    so we can process the cancellation for you. Thanks for your patience.
                  </Typography>
                </>
              )}

              <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px'}}>
                <Button
                  onClick={() => handleClose()}
                  startIcon={<BackArrow />}
                  sx={{
                    '&:hover': {
                      color: 'rgb(17,17,17)',
                    },
                  }}
                >
                  No, keep booking
                </Button>
                {userBookings.loading ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" onClick={handleCancelBooking}>
                    Yes, cancel booking
                  </Button>
                )}
              </Box>
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
};

export default CancelBooking;
