import axios from 'axios';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

axios.defaults.baseURL = process.env.BASE_URL;

export const fetchProfile = createAsyncThunk('profile/profileLoading', () =>
  axios
    .get(`/api/events/customer`, {
      withCredentials: true,
    })
    .then((response: any) => response.data)
    .catch((error: any) => error)
);

export const fetchBookingEvents = createAsyncThunk('profile/bookings', async (id: string) =>
  axios
    .get(`/api/bookings/events/user/${id}`, {
      withCredentials: true,
    })
    .then((response: any) => response.data)
    .catch((error: any) => error)
);

interface PayloadValue {
  name:
    | 'salutation'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'phone'
    | 'password'
    | 'confirmPassword'
    | 'terms'
    | 'marketing'
    | 'isAdmin';
  value: any;
}

export interface BookingEvent {
  id: string;
  eventId: string;
  date: string;
  startTime: string;
  endTime: string;
  count: number;
  location: string;
  title: string;
  orderDate: Date;
  description: string;
  image: string;
  paymentIntentId: string;
  bookingNumber: string;
  isPaid: boolean;
  price: number;
}

export interface ProfileState {
  value: IProfile;
  bookingEvents: BookingEvent[];
  loading: boolean;
  loaded: boolean;
}

const initialState: ProfileState = {
  value: {},
  bookingEvents: [],
  loading: false,
  loaded: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<PayloadValue>) => {
      let value = {...state.value};
      value[action.payload.name] = action.payload.value;

      state.value = value;
    },
  },
  extraReducers: {
    [fetchProfile.pending.type]: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    [fetchProfile.fulfilled.type]: (state, action) => {
      if (action.payload?.firstName) {
        let profile = {
          id: action.payload.id,
          loggedIn: true,
          ...(action.payload.salutation && {salutation: action.payload.salutation}),
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.emailAddress,
          phone: action.payload.phoneNumber,
          isAdmin: action.payload.isAdmin,
        };

        state.value = profile;
      }
      state.loading = false;
      state.loaded = true;
    },
    [fetchProfile.rejected.type]: (state, action) => {
      state.value = {};
      state.loading = false;
    },
    [fetchBookingEvents.fulfilled.type]: (state, action) => {
      state.bookingEvents = action.payload;
    },
  },
});

export const {setProfile} = profileSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
