import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import RemCalculator from '../../Utility/RemCalculator';

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {in: open, children, onEnter, onExited, ...other} = props;

  return <Box>{children}</Box>;
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: RemCalculator(24),
  width: 600,
  bgcolor: 'white',
  boxShadow: 8 ,
  borderRadius: '4px',
  ['@media (max-width:760px)']: {
    maxWidth: '90%',
  },
};

interface ISpringModalProps {
  children: React.ReactElement;
  open: boolean;
  handleOpen?: () => void;
  handleClose: () => void;
}

export default function SpringModal({children, open, handleClose}: ISpringModalProps) {
  return (
    <Box>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>{children}</Box>
        </Fade>
      </Modal>
    </Box>
  );
}
