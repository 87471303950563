import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, Grid, Typography, TextField} from '@resi/beeem-ui-components';
import MenuItem from '@mui/material/MenuItem';

import {setProfile} from '../../../../features/profile';
import type {RootState} from '../../../../store';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from '@reduxjs/toolkit';
import {TITLES} from '../../../../constants';

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

interface IPersonalProps {
  profile: IProfile;
  step?: number;
}

const Personal = ({profile, step}: IPersonalProps) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState('');

  return (
    <Box sx={{width: '100%', maxWidth: '620px', margin: 'auto'}}>
      <Typography
        variant="h4"
        color="var(--green-theme)"
        sx={{marginTop: '40px', marginBottom: {xs: '35px', md: '40px'}}}
        fontFamily={"Oswald"}
      >
        Tell us about yourself
      </Typography>
      <Grid container spacing="25px">
        <Grid item xs={4} md={2}>
          <TextField
            name="salutation"
            id="dropdown"
            value={profile.salutation || 'Mr'}
            color="secondary"
            onChange={event =>
              dispatch(setProfile({name: 'salutation', value: event.target.value}))
            }
            label="Title"
            sx={{
              width: '100%',
              '& #dropdown': {
                fontSize: '16px',
                paddingLeft: '10px',
              },
            }}
            select
            data-name={"salutation"}
          >
            {TITLES.map(({label, value}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="firstname"
            label="First name"
            name="firstName"
            variant="standard"
            autoComplete="off"
            color="secondary"
            value={profile.firstName ? profile.firstName : ''}
            data-name={"firstName"}
            required={true}
            onChange={event => dispatch(setProfile({name: 'firstName', value: event.target.value}))}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="lastname"
            label="Last name"
            name="lastName"
            variant="standard"
            autoComplete="off"
            value={profile.lastName ? profile.lastName : ''}
            data-name={"lastName"}
            onChange={event => dispatch(setProfile({name: 'lastName', value: event.target.value}))}
            color="secondary"
            required={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing="25px" sx={{paddingTop: '25px'}}>
        <Grid item xs={12} md={12}>
          <TextField
            id="email"
            label="Email address"
            variant="standard"
            autoComplete="off"
            type="email"
            data-name={"email"}
            value={profile.email ? profile.email : ''}
            color="secondary"
            onChange={event => dispatch(setProfile({name: 'email', value: event.target.value}))}
            required={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing="25px" sx={{paddingTop: '25px'}}>
        <Grid item xs={12} md={12}>
          <TextField
            color="secondary"
            sx={{marginTop: '10px'}}
            variant="standard"
            label="Phone number"
            type="tel"
            name="number"
            data-name={"phoneNumber"}
            onChange={event =>
              dispatch(
                setProfile({name: 'phone', value: event.target.value.replace(/[^0-9+]/g, '')})
              )
            }
            value={profile.phone ? profile.phone : ''}
            required={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Personal;
