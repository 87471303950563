import useLocalStorage from '../../hooks/useLocalStorage';

type UTMObject = Record<string, string>;

const expectedUTMFields: string[] = ["utmId", "utmSource", "utmMedium", "utmCampaign", "utmTerm", "utmContent"];

function PrepareUTMData(
  isTesting?: boolean,
  mockData?: string
): UTMObject {

  const { get } = useLocalStorage();
  const utmDataFromStorage: string = (isTesting || (!isTesting && mockData)) ? mockData : get("utm_data");
  const utmTransformedData: UTMObject = {};

  if(utmDataFromStorage) {
    const parsedUtmDataFromStorage: string[] = JSON.parse(utmDataFromStorage);

    parsedUtmDataFromStorage.forEach((data: string) => {
      const key: string = data.split("=")[0];

      if(key.includes("utm_")) {
        const spiltKeyFromSnakeCasing: string[] = key.split("_");

        let _firstPart: string  = spiltKeyFromSnakeCasing[0];
        let _secondPart: string = spiltKeyFromSnakeCasing[1].charAt(0).toUpperCase() + spiltKeyFromSnakeCasing[1].slice(1);

        if(expectedUTMFields.some((value: string): boolean => value === `${_firstPart+_secondPart}`)) {
          utmTransformedData[_firstPart+_secondPart] = data.split("=")[1];
        }
      }
    })
  }

  return utmTransformedData;
}

export default PrepareUTMData;