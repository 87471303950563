import { styled } from "@mui/material";
import RemCalculator from "../../Utility/RemCalculator";

export type NavContainerProps<N = number> = {
    paddingTop?: N;
    paddingLeft?: N;
    paddingRight?: N;
    paddingBottom?: N;
}

const NavLinks = styled('div')({
    gap: RemCalculator(34.1)
});

const NavContainer = styled('nav')(({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
 }: NavContainerProps) => ({
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    ".logo": {
        position: "relative",
        zIndex: 6
    }
}));

const Controls = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  gap: "16px",
  alignItems: "center",
  ".button-link": {
    padding: "0 !important"
  },
  "@media screen and (max-width: 768px)": {
    flexDirection: 'column',
    ".right": {
      alignSelf: "flex-end",
    },
    ".flip-over": {
      order: -1
    },
    ".button-outline": {
      padding: "4px 24px !important"
    }
  }
})

const NavHeader = styled("header")({
  padding: '25px 16px 46px',
  ".header-utility-btn": {
    padding: 0
  },
  ".overlay": {
    position: "fixed",
    background: "transparent",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1
  },
  "@media screen and (max-width: 768px)": {
    alignItems: "flex-end",
    padding: '16px'
  }
})

const ProfileContainer = styled("div")({
  position: "relative",
  ".dashboard-menu": {
    gap: "16px"
  },
  ".profile": {
    border: "1px solid rgb(210, 210, 210)",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    padding: "16px",
    borderRadius: "100%"
  },
  ".menu-container": {
    position: "absolute",
    zIndex: "5",
    background: "white",
    top: "100%",
    left: 0,
    border: "1px solid rgb(230, 230, 230)",
    width: "100%",
    padding: "16px 12px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    transform: "translate(0, 30px)",
    opacity: "0",
    pointerEvents: "none",
    transition: ".2s ease",
    "button": {
      textAlign: "left",
      padding: "12px 8px",
      "&:hover": {
        background: "rgb(245, 245, 245)",
      }
    },
    "&.active": {
      transform: "translate(0, 15px)",
      opacity: "1",
      pointerEvents: "all"
    }
  }
})

export {
    NavLinks,
    NavContainer,
    Controls,
    NavHeader,
    ProfileContainer
}