import * as Sentry from "@sentry/react";
import EnvironmentLens from '../EnvironmentLens/EnvironmentLens';

function Monitoring(): any {

  const { local, dev } = EnvironmentLens();

  function Environment() {
    const data = EnvironmentLens();

    let res: string = '';
    for (let env in EnvironmentLens()) {
      if ((data as any)[env] === true) {
        return res = env;
      }
    }
    return res;
  }

  return !local && Sentry.init({
    dsn: 'https://ff9925013d8c96a1c8e18e0d15c4b8a4@o4506060635963392.ingest.sentry.io/4506350741684224',
    environment: Environment(),
    integrations: [
      new Sentry.BrowserTracing({}),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1,
    replaysSessionSampleRate: (local || dev) ? .05 : .01,
    replaysOnErrorSampleRate: 1,
  });
}

export default Monitoring;