import axios from 'axios';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

axios.defaults.baseURL = process.env.BASE_URL;

interface PayloadValue {
  name:
    | 'salutation'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'confirmEmail'
    | 'phoneNumber'
    | 'password'
    | 'confirmPassword'
    | 'terms'
    | 'marketing'
    | 'createAccount';
  value: any;
}
export interface BookingState {
  value: IBooking;
  loading: boolean;
  loaded: boolean;
  failed: boolean;
  bookingCancelled: boolean;
}

const initialState: BookingState = {
  value: {},
  loading: false,
  loaded: false,
  failed: false,
  bookingCancelled: false,
};

interface CancelBooking {
  emailAddress: string;
  bookingNumber: string;
}

export const cancelBooking = createAsyncThunk('bookings/cancel', async (data: CancelBooking) =>
  axios
    .post(`/api/bookings/cancel`, data, {
      withCredentials: true,
    })
    .then((response: any) => response.data)
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBooking: (state, action: PayloadAction<PayloadValue>) => {
      let value = {...state.value};
      value[action.payload.name] = action.payload.value;

      state.value = value;
    },
    resetBooking: state => {
      state.value = {};
    },
  },
  extraReducers: {
    [cancelBooking.pending.type]: (state, action) => {
      state.value = {};
      state.failed = false;
      state.bookingCancelled = false;
      state.loading = true;
    },
    [cancelBooking.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.bookingCancelled = true;
      state.loaded = true;
    },
    [cancelBooking.rejected.type]: (state, action) => {
      state.value = {};
      state.failed = true;
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setBooking, resetBooking} = bookingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectBooking = (state: RootState) => state.booking;

export default bookingSlice.reducer;
