import RemCalculator from "../../Utility/RemCalculator";

type Props<S extends string, N = number> = {
    className?: S;
    width: N;
    height: N;
    fill?: S;
}

function Menu({className, width, height, fill}: Props<string>): JSX.Element {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width={RemCalculator(width)} height={RemCalculator(height)} fill={fill} className={className}>
            <path d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z"/>
        </svg>
    )
}

export default Menu;