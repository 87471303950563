import * as React from 'react';
import Dialog from '../../../../components/dialog';
import {Box, Typography} from '@resi/beeem-ui-components';
import {useSelector, useDispatch} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from '@reduxjs/toolkit';

import {checkPassword, isEmailValid, isPhoneValid} from '../../../../utils';
import {resetBooking} from '../../../../features/booking/bookingSlice';
import {RootState} from '../../../../store';
import {useCreateBooking} from '../../../../hooks/useCreateBooking';
import {useCreateGuestBooking} from '../../../../hooks/useCreateGuestBooking';

import Tabs from '../../../../components/tabs';
import {Registered} from '../registered';
import Guest from '../guest';
import {BookingStatus} from './BookingStatus';
import ExistingUser from '../existingUser';

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

type Status = 'booked' | 'notbooked' | 'fullybooked' | 'expired' | 'sent' | 'bookeduser';

interface IBookProps {
  event: IEvent;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  status: Status;
}

const Book = ({open, handleClose, handleOpen, event, status}: IBookProps) => {
  const booking = useSelector((state: RootState) => state.booking.value);
  const profile = useSelector((state: RootState) => state.profile.value);
  const [bookingStatus, setBookingStatus] = React.useState<Status | string>('notbooked');
  const [errors, setErrors] = React.useState(false);
  const dispatch = useDispatch<any>();
  const {bookingRequest, isSending: isSendingUser} = useCreateBooking();
  const {
    bookingRequest: guestBookingRequest,
    isSending: isSendingGuest,
    loginError,
  } = useCreateGuestBooking();

  const resetContent = () => {
    handleClose();
    setBookingStatus('notbooked');
  };
  const isValid = () => {
    if (
      !booking.firstName ||
      /[^a-z]/i.test(booking.firstName) ||
      !booking.lastName ||
      /[^a-z]/i.test(booking.lastName) ||
      !booking.email ||
      !booking.phoneNumber ||
      !booking.tickets ||
      booking.tickets === '0'
    )
      return false;

    if (!booking.confirmEmail || booking.email !== booking.confirmEmail) return false;
    if (
      (booking.createAccount && !checkPassword(booking.password)) ||
      booking.password !== booking.confirmPassword
    )
      return false;
    if (booking.createAccount && booking.password !== booking.confirmPassword) return false;
    if (booking.createAccount && !booking.terms) return false;

    if (!isPhoneValid(booking.phoneNumber)) return false;
    if (!isEmailValid(booking.email)) return false;

    return true;
  };

  React.useEffect(() => {
    setBookingStatus(status);
  }, [status]);

  function PageReload<T extends any, B extends boolean>(action: T, clearBooking: B): void {
    Promise.resolve(action).then(() => {
      location.reload();

      clearBooking && dispatch(resetBooking());
    })
  }

  const makeGuestBooking = async () => {
    if (!isValid()) {
      return setErrors(true);
    }

    const bookingData = await guestBookingRequest(event);

    if (!(bookingData instanceof Error) && !event.isPaid) {
      setBookingStatus('sent');
    }
  };

  const makeBooking = async () => {
    const bookingData = await bookingRequest(event, booking);

    if (!(bookingData instanceof Error) && !event.isPaid) {
      setBookingStatus('bookedUser');
    }

    PageReload<any, boolean>(bookingData, true);
  };

  const items = [
    {
      title: 'New here?',
      content: (
        <Guest
          event={event}
          errors={errors}
          loginError={loginError}
          makeBooking={makeGuestBooking}
          isSending={isSendingGuest}
        />
      ),
    },
    {title: 'Already Registered?', content: <Registered />},
  ];

  return (
    <Dialog open={open} handleClose={resetContent} handleOpen={handleOpen}>
      <Box sx={{width: '100%', margin: 'auto'}}>
        {
          bookingStatus !== 'notbooked' && !isSendingUser && !isSendingGuest ?
          (
            <BookingStatus status={bookingStatus} initiateGuestBooking={makeGuestBooking} />
          ) :
          profile?.loggedIn ? (
            <>
            <Typography variant="h2" color="var(--green-theme)" sx={{textAlign: 'left'}} fontFamily={"Oswald"}>
              {event.title}
            </Typography>
            <ExistingUser
              event={event}
              errors={errors}
              loginError={loginError}
              makeBooking={makeBooking}
              isSending={isSendingUser}
            />
          </>
          ) :
          (
            <>
            <Typography variant="h2" color="var(--green-theme)" sx={{textAlign: 'left'}}>
              {event.title}
            </Typography>
            <Tabs items={items} />
          </>
          )}
      </Box>
    </Dialog>
  );
};

export default Book;
