import RemCalculator from '../../../Utility/RemCalculator';

export function ReusableStyling (gap?: number): object {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: gap && RemCalculator(gap)
  }
}