import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme, styled} from '@mui/material/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  TablePagination,
  Card,
  IconButton,
  Typography,
} from '@resi/beeem-ui-components';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Edit from '../../components/icons/edit.svg';
import Delete from '../../components/icons/delete.svg';
import {ReactComponent as PenWhite} from '../../components/icons/pen-white.svg';
import {ReactComponent as Star} from '../../components/icons/star.svg';
import {convertDate, stripSeconds} from '../../utils/';
import AlertError from '../../images/AlertError.svg';
interface TableProps {
  data: any;
  addItemToDelete: (id: string) => void;
  itemsToDelete: string[];
  deleteBulk: () => void;
  deleteSingleEvent: (id: string, recurringId?: string) => void;
  alertMessage?: string;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
const Container = styled(Card)(({theme}) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow: 'none',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  '.MuiTableRow-root': {
    '&:hover': {
      background: 'rgba(63, 81, 181, 0.08)',
    },
    '.MuiTableCell-root': {
      fontWeight: 500,
    },
    '.MuiTableCell-head': {
      fontSize: '14px',
    },
  },
}));

function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable({
  data,
  deleteSingleEvent,
  alertMessage,
}: TableProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const navigate = useNavigate();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Container}>
      <Table sx={{minWidth: 500}} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Guests</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell>Event Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 && data?.length > 0
            ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row: any, index: number) => (
            <TableRow key={index}>
              <TableCell>
                <Box
                  sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                  onClick={() => navigate(`/admin/events/${row.id}`)}
                  data-value={"event"}
                >
                  <Box
                    sx={{
                      height: '40px',
                      width: '40px',
                      display: 'block',
                      background: `url(${row.images[0]})`,
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '50%',
                      backgroundSize: 'cover',
                      marginRight: '10px',
                    }}
                  />
                  {row.title}
                </Box>
              </TableCell>
              <TableCell>
                {row.startDate ? convertDate(row.startDate) : "Coming Soon"}
              </TableCell>
              <TableCell>{stripSeconds(row.startTime)}</TableCell>
              <TableCell>
                {!row.capacity ? 'Non-ticketed' : `${row.numberOfAttendees || 0}/${row.capacity}`}
              </TableCell>
              <TableCell>{!row.price ? 'Free' : `£${row.price}`}</TableCell>
              <TableCell>
                <Box
                  component="img"
                  src={Edit}
                  sx={{marginRight: '20px', cursor: 'pointer'}}
                  onClick={() => navigate(`/admin/events/${row.id}`)}
                />
                <Box
                  component="img"
                  src={Delete}
                  sx={{cursor: 'pointer'}}
                  onClick={() => {
                    deleteSingleEvent(row.id, row.recurringId)
                  }}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={row.status === 'DRAFT' ? <PenWhite /> : <Star />}
                  sx={{textTransform: 'initial', '&:hover': {boxShadow: 'none'}}}
                >
                  {row.status === 'DRAFT' ? 'Draft' : 'Published'}
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{height: 53 * emptyRows}}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {alertMessage && (
          <TableBody
            sx={{
              width: '100%',
              height: 'auto',
              background: '#feeceb',
            }}
          >
            <TableRow>
              <TableCell colSpan={12}>
                <Box display="flex" gap="15px">
                  <Box component="img" src={AlertError} height="22px" width="22px" />
                  <Typography variant="body2" textAlign="left" fontWeight="500">
                    {alertMessage}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
              colSpan={8}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
