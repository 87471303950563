import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {wallacea} from '@resi/beeem-ui-theme';

import {Provider, CssBaseline} from '@resi/beeem-ui-components';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ScrollToTop} from './hooks';
import Events from './pages/events';
import Event from './pages/event/Event';
import Main from './components/main';
import Admin from './pages/admin';
import AdminEvents from './pages/adminevents';
import GuestList from './pages/guestlist';
import GuestBooking from './pages/guestbooking';
import AdminEdit from './pages/adminedit';
import SignIn from './pages/signin';
import Verify from './pages/verify';
import VerifyAccount from './pages/verifyaccount';
import Onboarding from './pages/onboarding';
import Update from './pages/update';
import Loading from './pages/loading';
import Tickets from './pages/tickets';
import Confirmation from './pages/confirmation';

import {fetchEvents} from './features/events/eventsSlice';
import {fetchProfile} from './features/profile';

import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import ProtectedRoute from './components/protectedroute';
import CancelBooking from './pages/cancelbooking'
import UseParamsTracker from "./Utility/UTMParamsTracker/UTMParamsTracker";

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const App = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const {value: _events, isFetching} = useSelector((state: RootState) => state.events);
  const [events, setEvents] = useState<IEvent[]>([]);

  useEffect(() => {
    if (_events && _events.length) {
      setEvents(_events);
    }
  }, [_events])

  useEffect(() => {
    dispatch(fetchEvents());
    dispatch(fetchProfile());
  }, []);

  return (
    <Provider theme={wallacea}>
      <CssBaseline />
      <Router>
        <UseParamsTracker>
          <ScrollToTop />
          <Routes>
            <Route
              path="/admin/events/:id"
              element={
                <ProtectedRoute>
                  <AdminEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/events/guests/:id"
              element={
                <ProtectedRoute>
                  <GuestList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/events/guests/booking/:id"
              element={
                <ProtectedRoute>
                  <GuestBooking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/events"
              element={
                <ProtectedRoute>
                  <AdminEvents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/new"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route path="/verify" element={<VerifyAccount />} />
            <Route
              path="/verify/:id"
              element={
                <Main noLoading={true}>
                  <Verify events={events}/>
                </Main>
              }
            />
            <Route
              path="/bookings/cancel/:id"
              element={
                <Main>
                  <CancelBooking />
                </Main>
              }
            />
            <Route path="/update-password" element={<Update />} />
            <Route
              path="/events/booking-confirmation"
              element={
                <Main>
                  <Confirmation />
                </Main>
              }
            />
            <Route path="/events/:id" element={<Event events={events} />} />
            <Route
              path={'/'}
              element={<Main><Events events={events} /></Main>}
            />
            <Route
              path="/signin"
              element={
                <Main showBackButton>
                  <SignIn />
                </Main>
              }
            />
            <Route
              path="/onboarding"
              element={
                <Main showBackButton>
                  <Onboarding />
                </Main>
              }
            />
            <Route path="/loading" element={<Loading />} />
            <Route
              path="/my-tickets"
              element={
                <Main showBackButton>
                  <Tickets />
                </Main>
              }
            />
            <Route
              path="*"
              element={
                <Main>
                  <Events events={events} />
                </Main>
              }
            />
          </Routes>
        </UseParamsTracker>
      </Router>
    </Provider>
  );
};

export default App;
