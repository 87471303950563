import {Box, Button, Typography} from '@resi/beeem-ui-components';

function ErrorNotice(): JSX.Element {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: "2rem"
      }}
    >
      <Typography
        fontFamily={'Barlow'}
        fontSize={'2rem'}
      >
        {"An unexpected error occurred."}
      </Typography>
      <Button
        variant={"outlined"}
        className={''}
        href={"/admin/events"}
      >
        <Typography
          fontFamily={'Barlow'}
          fontSize={'1rem'}
          color={'black'}
        >
          {"Please try again"}
        </Typography>
      </Button>
    </Box>
  )
}

export default ErrorNotice;