import { styled } from "@mui/material";
import { NavContainerProps } from "../WallaceaHeader/Navigation[Style]";
import RemCalculator from "../../Utility/RemCalculator";

const FooterNode = styled('footer')({
    background: 'var(--green-theme)'
});

const FooterContainer = styled('div')(({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
 }: NavContainerProps) => ({
    maxWidth:  '1904px',
    margin: '0 auto',
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    gap: RemCalculator(165),
    "@media (max-width: 768px)": {
        gap: RemCalculator(165/2),
    }
}));

const Content = styled('div')({
    rowGap: RemCalculator(32),
    columnGap: RemCalculator(16),
});

const Image = styled('img')({
    width: RemCalculator(247),
    height: RemCalculator(69)
})

const ContentData = styled('div')({
    maxWidth: RemCalculator(371),
    gap: RemCalculator(16),
});

const Salutation = styled('div')({
    gap: RemCalculator(16*2),
    ".text": {
        maxWidth: RemCalculator(650),
        gap: RemCalculator(16)
    }
});

export {
    FooterContainer,
    FooterNode,
    Image,
    Content,
    ContentData,
    Salutation
}