import React from 'react';
import {Box, Typography} from '@resi/beeem-ui-components';

interface ITitleProps {
  title: string;
  icon: string;
  marginTop?: string;
}

const Title = ({title, icon, marginTop}: ITitleProps) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: marginTop}}>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          border: 'solid 2px #111111',
          borderRadius: '50px',
          background: `url(${icon})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginRight: '15px',
        }}
      />
      <Typography variant="h3" sx={{marginTop: '0px'}}>
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
