import React from 'react'
// import { ReactComponent as TickIcon } from '../../icons/tick.svg'
import { TickIcon } from './TickIcon'
import {
  Box,
  Button,
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { styled } from '@mui/material/styles'

interface IInfoCardProps {
  task?:
    | 'isRemovalsDone'
    | 'isBroadbandDone'
    | 'isConveyancingDone'
    | 'isomeInsuranceDone'
  title: string
  text: string
  icon: string
  price?: number
  linkText?: string
  link: string
  buttonText?: string
  list?: string[]
  steps?: string
  completed?: boolean
  bottomLinkText?: string
  grid?: React.ReactNode
  markComplete: (
    task:
      | 'isRemovalsDone'
      | 'isBroadbandDone'
      | 'isConveyancingDone'
      | 'isomeInsuranceDone'
  ) => void | null
}

const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  border: 'solid .5px rgba(0, 0, 0, 0.12)',
  background: '#FAFAFA',
  padding: '32px 32px 20px 32px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '.MuiTypography-root': {
    textAlign: 'left',
    '&.steps': {
      textAlign: 'right'
    }
  }
}))

const BeeemList = styled(List)(() => ({
  marginTop: '20px'
}))
const BeeemListItem = styled(ListItem)(() => ({
  padding: '0px',
  '.MuiListItemIcon-root': {
    minWidth: '26px'
  },
  '.MuiListItemText-root': {
    marginTop: '1px',
    marginBottom: '1px',
    '.MuiTypography-root': {
      marginTop: '0px'
    }
  }
}))

const InfoCard = ({
  task,
  title,
  text,
  icon,
  price,
  linkText,
  link,
  buttonText,
  list,
  steps,
  grid,
  completed,
  markComplete
}: IInfoCardProps) => {
  return (
    <Container>
      {steps && (
        <Typography
          className='steps'
          variant='body1'
          color='secondary.main'
          sx={{
            width: '100%',
            textAlign: 'right',
            lineHeight: '20px',
            fontWeight: completed ? 700 : 400
          }}
        >
          {steps}
        </Typography>
      )}
      <Box component='img' alt={title} src={icon} sx={{ height: '55px' }} />
      <Typography
        variant='h6'
        color='text.primary'
        sx={{ margin: '30px 0 10px 0' }}
      >
        {title}
      </Typography>
      <Typography variant='body1' color='#000000'>
        {text}
      </Typography>
      <Box sx={{ width: '100%' }}>
        {!grid ? (
          <Box>
            <BeeemList>
              {list &&
                list.map((item, index) => {
                  return (
                    <BeeemListItem key={index}>
                      <ListItemIcon>
                        <TickIcon />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </BeeemListItem>
                  )
                })}
            </BeeemList>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginBottom: '20px',
                minHeight: '52.51px'
              }}
            >
              {linkText && (
                <Link variant='body2' href={link}>
                  {linkText}
                </Link>
              )}
              {price && (
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column',
                    '.MuiTypography-root': { textAlign: 'right' }
                  }}
                >
                  <Typography variant='h4' color='text.primary'>
                    £{price.toFixed(2)}
                  </Typography>
                  <Typography variant='body2' color='text.primary'>
                    Avg saving
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box>{grid}</Box>
        )}
        {completed ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              minHeight: '85px',
              alignItems: 'flex-end'
            }}
          >
            {task === 'isBroadbandDone' ? (
              <Typography variant='body1' color='#000000'>
                <span style={{ fontWeight: 'bold' }}>Congrats:</span> you’re all
                set
              </Typography>
            ) : (
              <Typography variant='body1' color='#000000'>
                <span style={{ fontWeight: 'bold' }}>Great news:</span> you’re
                on the best deal!
              </Typography>
            )}
            {task && (
              <Link
                variant='body2'
                color='primary.dark'
                onClick={() => markComplete(task)}
              >
                Book this task?
              </Link>
            )}
          </Box>
        ) : (
          <Box>
            <Button
              variant='contained'
              sx={{ width: '100%', marginBottom: '10px' }}
            >
              {buttonText}
            </Button>
            {task && (
              <Link
                variant='body2'
                color='primary.dark'
                onClick={() => markComplete(task)}
              >
                Completed this task?
              </Link>
            )}
          </Box>
        )}
      </Box>
    </Container>
  )
}
export default InfoCard
