import React, {BaseSyntheticEvent, ReactNode, useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from '@resi/beeem-ui-components';
import axios from 'axios';
import {SelectChangeEvent} from '@mui/material/Select';
import Side from '../../components/side';
import Route from '../../components/icons/route.svg';
import {ReactComponent as PenWhite} from '../../components/icons/pen-white.svg';
import Images from '../../components/icons/images.svg';
import Pencil from '../../components/icons/pencil.svg';
import {ReactComponent as Star} from '../../components/icons/star.svg';
import Settings from './components/settings';
import ImageUploader from '../../components/imageuploader';
import Image from '../../components/image';
import Video from '../../components/icons/video.svg';
import {
  checkValidation,
  getYoutubeThumbnail,
  checkIfValidVideoUrl,
  dateToUtc,
} from '../../utils';

import Title from '../../components/title';
import Details, {Text} from './components/details';

axios.defaults.baseURL = process.env.BASE_URL;

interface IMainProps {
  children?: ReactNode;
  edit?: boolean;
  editableEvent?: IEventNew;
}

const Admin = ({children, edit, editableEvent}: IMainProps) => {
  const [event, setEvent] = useState<IEventNew>({});
  const [errors, setErrors] = useState(false);
  const [errorMessageFromServer, setErrorMessageFromServer] = useState<string>();
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [callToRSVP, setCallToRSVP] = useState<boolean>(false);

  useEffect(() => {
    if (editableEvent) {
      setEvent(editableEvent);
    }
  }, [editableEvent]);

  const publishEvent = (publish: boolean) => {
    if (!checkValidation(event, edit ? edit : false)) {
      setErrors(true);
    }
    else {
      const formData: any = new FormData();
      if (event.id) {
        formData.append('id', event.id);
      }
      formData.append('title', event.title);
      if(isRecurring) {
        formData.append('startDate', event.startDate);
        formData.append('endDate', event.endDate);
      }
      else {
        if(event.startDate) formData.append('startDate', dateToUtc(event.startDate));
        if(event.endDate) formData.append('endDate', dateToUtc(event.endDate));
      }
      formData.append('startTime', event.startTime);
      formData.append('endTime', event.endTime);
      !isRecurring && formData.append('frequency', event.frequency);
      formData.append('category', event.category);
      formData.append('host', event.host);
      formData.append('description', event.description);
      formData.append('isPublic', event.type === 'public');
      formData.append('isPaid', event.paid ? event.paid : false);
      formData.append('price', event.price ? event.price : 0);
      if (event.capacity) {
        formData.append('capacity', event.attendanceLimit === false ? 0 : event.capacity);
      }
      if(callToRSVP) {
        formData.append("rsvpPhone", event.rsvpPhone);
        formData.append("rsvpEmail", event.rsvpEmail);
      }
      formData.append('isNonTicketed', event.attendanceLimit === false);
      formData.append('status', publish ? 'PUBLISHED' : 'DRAFT');
      formData.append('videoUrl', event.videoUrl ? event.videoUrl : '');
      formData.append(
        'termsAndCondition',
        event.termsandconditions ? event.termsandconditions : false
      );
      formData.append('lat', event.location?.location?.lat);
      formData.append('lon', event.location?.location?.lng);
      formData.append('location', event.location?.address ? event.location?.address : '');
      if (event.sessionNumber) {
        formData.append('numberOfSessions', event.sessionNumber);
      }
      formData.append('note', event.notes ? event.notes : '');
      formData.append('imageCaption', event.imageCaption ? event.imageCaption : '');
      !isRecurring && formData.append('freeForResidents', event.freeForResidents ? event.freeForResidents : false);
      formData.append('isComingSoon', event.isComingSoon === true);

      if (event.editImages?.length) {
        for (const image of event.editImages) {
          !isRecurring && formData.append('imageUrls[]', image);
        }
      }
      else {
        formData.append('imageUrls', '');
      }
      if (event.images?.length) {
        for (const image of event.images) {
          formData.append('images', image);
        }
      }

      axios.post( `/api/events/${isRecurring ? 'recurring' : ''}`, formData, { headers: { 'content-type': 'multipart/form-data' }})
      .then(() => window.location.href = '/admin/events')
      .catch(error => setErrorMessageFromServer(error?.response?.statusText))
    }
  };

  const handleChange = (
    eventChange: BaseSyntheticEvent | IDateEvent | SelectChangeEvent | ISwitchEvent
  ) => {
    setEvent({
      ...event,
      [eventChange.target.name]: eventChange.target.value,
    });
  }

  const onDropProfile = (files: File[]) => {
    setEvent({
      ...event,
      images: [files[0]],
      editImages: [],
    });
  };

  const deleteImage = (index: number) => {
    const images = event.images ? [...event.images] : [];
    images.splice(index, 1);
    const _editImages = event.editImages ? [...event.editImages] : [];
    _editImages.splice(index, 1);

    setEvent({
      ...event,
      images: images,
      editImages: _editImages,
    });
  };

  return (
    <Box sx={{padding: '20px 0', height: '100%', width: '100%', background: '#FFFFFF'}}>
      <Side />
      <Box sx={{maxWidth: '1344px', margin: 'auto', padding: {xs: '0px 20px', lg: '0 100px'}}}>
        <Grid container spacing={{xs: '30px', lg: '100px'}} sx={{marginBottom: '50px'}}>
          <Grid item xs={12} md={7}>
            <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
              <Typography variant="body1" sx={{color: 'rgba(0, 0, 0, 0.6)'}}>
                <span style={{textDecoration: 'underline'}}>Events</span> /{' '}
                <span color="rgba(0, 0, 0, 0.35)">
                  {
                    edit ? `${event.title} - ${event.startDate ? new Date(event.startDate).toLocaleDateString('en-GB') : 'Coming soon'}` : `Add Event`
                  }
                </span>
              </Typography>
              <Typography
                variant={"h3"}
                sx={{ color: '#f44336' }}
              >
                {errorMessageFromServer}
              </Typography>
              <Typography variant="h2" color="#111111" sx={{marginTop: '20px'}}>
                {edit ? event.title : 'Add a new event'}{' '}
                {edit && (
                  <span style={{fontSize: '22px'}}>
                    {event.startDate ? new Date(event.startDate).toLocaleDateString('en-GB') : ''}
                  </span>
                )}
              </Typography>
              {edit && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: {xs: 'start', md: 'center'},
                    gap: '.5rem',
                    marginTop: '1rem'
                  }}
                >
                  {
                    event.capacity &&
                    <>
                      <Typography variant={'body1'} fontWeight={'400'} color="#111111">
                        <span style={{fontWeight: 'bold'}}>
                          {event.numberOfAttendees ? event.numberOfAttendees : 0}
                        </span>{' '}
                        guests attending. <span style={{fontWeight: 'bold'}}>{event.capacity + " available space(s)."}</span>
                      </Typography>
                      <Button
                        variant={"contained"}
                        sx={{
                          textTransform: 'initial',
                          fontSize: '12px',
                          width: '35%',
                          '@media (max-width: 768px)': {
                            width: '100%'
                          },
                        }}
                        href={`/admin/events/guests/${event.id}`}
                      >
                        {`View guest list of ${event.numberOfAttendees ? event.numberOfAttendees : 0}`}
                      </Button>
                    </>
                  }
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                marginTop: '18px',
                display: {xs: 'flex', lg: 'block'},
                textAlign: 'right',
                '.MuiTypography-body1': {
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                },
              }}
            >
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Event status:{' '}
              </Typography>
              <Button
                variant="contained"
                startIcon={edit && event.status === 'PUBLISHED' ? <Star /> : <PenWhite />}
                sx={{textTransform: 'initial', fontSize: '22px'}}
              >
                {edit && event.status === 'PUBLISHED' ? 'Published' : 'Draft'}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing="100px">
          <Grid item xs={12} md={6}>
            <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
              <Title icon={Route} title="Event details" marginTop="20px" />
              <Details
                event={event}
                handleChange={handleChange}
                errors={errors}
                edit={edit}
                isRecurring={isRecurring}
                setIsRecurring={setIsRecurring}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Title icon={Images} title="Event image" marginTop="20px" />
            {
              event.images?.length ?
              <Box sx={{display: 'flex', margin: '20px 0'}}>
                {event.images &&
                  event.images.map((image, index) => {
                    return (
                      <Image
                        key={index}
                        index={index}
                        image={URL.createObjectURL(image)}
                        onClick={index => (index || index === 0 ? deleteImage(index) : () => {})}
                      />
                    );
                  })}
              </Box> : event.editImages?.length ?
              <Box sx={{marginTop: '20px'}}>
                {event.editImages?.length &&
                  event.editImages.map((image, index) => {
                    return (
                      <Image
                        key={index}
                        index={index}
                        image={image}
                        onClick={index => (index || index === 0 ? deleteImage(index) : () => {})}
                      />
                    );
                  })}
              </Box> :
              <Box sx={{marginTop: '20px'}}></Box>
            }
            <ImageUploader onDrop={onDropProfile} />
            <Text
              error={errors && !event.host}
              label="Image caption"
              required={true}
              variant="standard"
              name="imageCaption"
              value={event.imageCaption ? event.imageCaption : ''}
              onChange={handleChange}
            />
            <Box sx={{marginTop: '90px', marginBottom: '20px'}}>
              <Title icon={Video} title="Event video" marginTop="20px" />
              {checkIfValidVideoUrl(event.videoUrl) && !!event.videoUrl && (
                <Image
                  image={getYoutubeThumbnail({url: event.videoUrl, version: '0'}) || ''}
                  onClick={index => setEvent({...event, videoUrl: ''})}
                />
              )}
              <Text
                value={event.videoUrl ? event.videoUrl : ''}
                name="videoUrl"
                label="Video URL (YouTube or Vimeo)"
                onChange={handleChange}
                helperText={
                  !!event.videoUrl && !checkIfValidVideoUrl(event.videoUrl)
                    ? 'Please enter a valid Youtube or Vimeo video URL'
                    : 'Embed a hosted video by adding a Youtube or Vimeo URL'
                }
                variant="standard"
                error={!!event.videoUrl && !checkIfValidVideoUrl(event.videoUrl)}
              />
            </Box>
            <Settings
              event={event}
              handleChange={handleChange}
              errors={errors}
              edit={edit}
              callToRSVP={callToRSVP}
              setCallToRSVP={setCallToRSVP}
            />
          </Grid>
        </Grid>
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <Title icon={Pencil} title="Event notes" marginTop="20px" />
            <Text
              value={event.notes ? event.notes : ''}
              name="notes"
              label="Notes"
              rows={4}
              onChange={handleChange}
              helperText="Add notes about the event (for internal purposes only)"
              variant="standard"
              data-value={"notes"}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <Grid container spacing="100px">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: {xs: 'column', sm: 'row'},
              margin: '50px 0',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => publishEvent(false)}
              sx={{
                textTransform: 'initial',
                '&:hover': {color: '#111111'},
                marginRight: '20px',
                marginBottom: {xs: '20px', sm: '0px'},
                width: {xs: '100%', sm: '152px'},
              }}
            >
              Save draft
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => publishEvent(true)}
              sx={{
                textTransform: 'initial',
                width: {xs: '100%', sm: '152px'},
                '&:hover': {backgroundColor: '#01788B'},
              }}
              data-value={"submit-event"}
            >
              Publish {edit ? 'updates' : 'event'}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Admin;
