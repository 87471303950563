import React, {useEffect, useState} from 'react';
import {Box, CircularProgress} from '@resi/beeem-ui-components';
import {useParams} from 'react-router-dom';
import Book from './components/book';
import Map from '../../components/map';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store';
import Header from '../../components/header';
import WallaceaFooter from '../../components/Footer/Footer';
import {AppThunkDispatch} from '../signin';
import {BookingEvent, fetchBookingEvents} from '../../features/profile';
import {resetBooking} from '../../features/booking/bookingSlice';
import Navigation from '../../components/WallaceaHeader/Navigation';
import {Hero, InfoSection, Location} from './Event[Style]';
import {FormatEventDate, GetEventTime} from './EventDateFormatter';
// @ts-ignore
import * as converter from "written-number";
import useDetectDevice from '../../hooks/useDetectDevice';

axios.defaults.baseURL = process.env.BASE_URL;

interface IEventProps {
  events: IEvent[];
}

const Event = ({events}: IEventProps) => {
  const profile = useSelector((state: RootState) => state.profile.value);
  const loading = useSelector((state: RootState) => state.profile.loading);
  const isFetching = useSelector((state: RootState) => state.events.isFetching);
  const userEvents: BookingEvent[] = useSelector((state: any) => state.profile.bookingEvents);
  const dispatch = useDispatch<AppThunkDispatch>();
  const {id: userId} = profile;

  const [event, setEvent] = useState<IEvent | null>(null);
  const [open, setOpen] = useState(false);
  const [status] = useState<'booked' | 'notbooked' | 'fullybooked' | 'expired' | 'sent'>('notbooked');
  const {id} = useParams();
  const needToCancelCaption: string = 'If you booked online, please follow the instructions in your booking confirmation email, or if you have an account, visit';
  const { isDesktop } = useDetectDevice();

  useEffect(() => {
    window.addEventListener('unload', function () {});
    window.addEventListener('beforeunload', function () {});
  }, []);

  useEffect(() => {
    if (open) return;
    if (id) {
      axios
        .get(`/api/events/${id}`)
        .then(result => {
          setEvent(result.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [id, events, open]);

  console.log(profile)

  useEffect(() => {
    if (userId) dispatch(fetchBookingEvents(userId));
  }, [userId]);

  function HandleClose(): void {
    dispatch(resetBooking());
    setOpen(false);
  }

  const showBookNowButton = () => {
    if(event) {
        return (
          (event.capacity && event.capacity > 0) &&
          (!event.isComingSoon) &&
          (!event.rsvpEmail) &&
          (!event.rsvpPhone) &&
          !(new Date(event.endDate!).setHours(24, 0, 0, 0) <= Date.now())
        )
    }
    return false;
  }

  if (loading || isFetching || !event) {
    return (
      <Box height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{maxWidth: '1904px', margin: '0 auto'}}>
      <Navigation />
      {
        !loading && (
          <Header
            loggedIn={!!profile.loggedIn}
            profile={profile}
            showBackButton={true}
          />
        )
      }
      <Hero>
        <div className={"container d-grid grid-2 w-100 h-100"}>
          <img src={event.images[0]} alt={event.title} className={"w-100 h-100"} />
          <div className={"info d-col align-center justify-center h-100 w-100"}>
            <div className={'info-container d-col f-grow justify-center align-center'}>
              <span className="title-host d-col">
                <h1 className="font-green font-oswald font-upper font-large">{event.title}</h1>
                <p className={'font-body font-barlow font-capitalize'}>{event.host}</p>
              </span>
              <span className={'date'}>
                {
                  event.isComingSoon ?
                  <p className={'font-body font-barlow'}>{'Coming Soon'}</p> :
                  <p className={'font-body font-barlow'}>{FormatEventDate(event.startDate)}, {GetEventTime(event.startTime)} - {GetEventTime(event.endTime)}</p>
                }
              </span>
              <p className={'font-italic font-body font-barlow font-w-lg'}>{event.price < 1 ? 'Free.' : '£' + event.price}</p>
              {
                showBookNowButton() ? (
                  <button
                    onClick={() => setOpen(true)}
                    className={'btn-primary font-barlow font-body-sm'}
                    data-name={'event-book-button'}
                  >
                    Book now
                  </button>
                ):
                <></>
              }
              {
                (event.capacity && (new Date(event.endDate!).setHours(24, 0, 0, 0) > Date.now())) ?
                <p className={'font-body font-barlow font-capitalize'}>{converter(event.capacity)} spaces left</p>:
                <></>
              }
            </div>
            {
              (event.rsvpPhone || event.rsvpEmail) &&
              <div className={"rsvp w-100 d-col align-center justify-center"}>
                <p className="font-green font-body font-barlow">RSVP by calling <span className="font-w-lg">{event?.rsvpPhone}</span></p>
                <p className="font-green font-body font-barlow">Or email <span className="font-w-lg email">{event?.rsvpEmail}</span></p>
              </div>
            }
          </div>
        </div>
      </Hero>
      {
        (isDesktop && event.imageCaption) &&
        <p
          style={{ padding: "14px" }}
          className={'w-100 font-left font-body font-barlow font-capitalize'}
        >
          {event.imageCaption}
        </p>
      }
      <Location className={"d-grid grid-2"}>
        <span className={'content d-col align-start w-100'}>
          <span className={'header d-col align-start'}>
            <h1 className={'font-green'}>{'Event information.'}</h1>
            <p className={'description font-body font-barlow'}>{event.description}</p>
          </span>
          <span className={'header d-col align-start'}>
            <h1 className={'font-green'}>Location.</h1>
            <p className="font-body font-green">{event.location}</p>
          </span>
        </span>
        <div className={'map-container'}>
          <Map coords={{lat: event.lat, lng: event.lon}} />
        </div>
      </Location>
      <InfoSection className={"d-grid grid-2"}>
        <div className="text d-col justify-start">
          <h2 className="header font-body font-barlow font-w-lg font-left">{"T&Cs."}</h2>
          <p className="font-body font-green font-barlow font-left">{event.termsAndCondition}</p>
        </div>
        <div className="text d-col justify-start">
          <h2 className="header font-body font-barlow font-w-lg font-left">{"Need to cancel?"}</h2>
          <p className="font-body font-green font-barlow font-left">{needToCancelCaption} <a href="/my-tickets" className={'link font-body font-barlow'}>My tickets</a></p>
          <p className="font-body font-green font-barlow font-left">If you booked by telephone or email, please contact us on <a href={"tel:020 3960 1005"} className={"font-body link"}>020 3960 1005.</a></p>
        </div>
      </InfoSection>
      <Book
        open={open}
        handleClose={HandleClose}
        handleOpen={() => {}}
        event={event}
        status={status}
      />
      <WallaceaFooter />
    </Box>
  );
};

export default Event;
