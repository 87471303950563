import React, {useEffect} from 'react';
import {Box, Grid, TextField, styled} from '@resi/beeem-ui-components';
import {SelectChangeEvent} from '@mui/material/Select';
import DatePicker from '../../../../components/datepicker';
import SelectVariants from '../../../../components/selectvariants';
import AddressPicker from '../../../../components/addresspicker';
import {categories} from '../../../../data';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, {Dayjs} from 'dayjs';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {EventTypes} from '../../../../constants';
import Switch from '../../../../components/switch';
import {Typography} from '@mui/material';
import RemCalculator from '../../../../Utility/RemCalculator';

interface IDetailsProps {
  event: IEventNew;
  handleChange: (event: React.BaseSyntheticEvent | IDateEvent | SelectChangeEvent) => void;
  errors?: boolean;
  edit?: boolean;
  isRecurring: boolean;
  setIsRecurring: Function;
}

export const Text = styled(TextField)(({theme}) => ({
  '.MuiFormLabel-root': {
    color: '#111111',
    fontWeight: 500,
  },
}));

const Details = ({
  event,
  handleChange,
  errors,
  edit,
  isRecurring,
  setIsRecurring
}: IDetailsProps) => {
  const getFrequencies = () => {
    if (!event.frequency) return [];
    const total = event.frequency === 'weekly' ? 53 : event.frequency === 'monthly' ? 13 : 31;
    const values = [];
    for (let x = 1; x < total; x++) {
      values.push({
        name: `${x}`,
        label: `${x}`,
      });
    }
    return values;
  };

  const isPublishedEvent = (event: IEventNew): boolean => event.status === 'PUBLISHED';
  const [startTime, setStartTime] = React.useState<Dayjs | any>(dayjs(`2022-04-17T${event ? event?.startTime : '00:00'}`));
  const [endTime, setEndTime] = React.useState<Dayjs | any>(dayjs(`2022-04-17T${event ? event?.startTime : '00:00'}`));

  useEffect(() => {
    setStartTime(dayjs(`2022-04-17T${event ? event?.startTime : '00:00'}`));
    setEndTime(dayjs(`2022-04-17T${event ? event?.endTime : '00:00'}`));
  }, [event])

  function HandleTimePicker(data: Dayjs | null | string | undefined, name: 'startTime' | 'endTime'): void {
    const hour = new Date(String(data)).getHours();
    const minutes = new Date(String(data)).getMinutes();
    const date: IDateEvent = {
      target: { name, value: (`${hour}:${minutes}`) as any }
    };
    handleChange(date);
  }

  function DisableRecurringEventSwitch(): boolean {
    switch (event?.recurringId) {
      case undefined:
        return false;
      case null:
        return false;

      default:
        return true;
    }
  }

  return (
    <Box sx={{marginTop: '20px'}}>
      <Grid container spacing="20px">
        <Grid item xs={12} md={12}>
          <Text
            label="Event title"
            required={true}
            name="title"
            value={event.title ? event.title : ''}
            variant="standard"
            onChange={handleChange}
            error={errors && !event.title}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Switch
            fontWeight={500}
            fontSize="18px"
            label={'Recurring event (Weekly)'}
            disabled={DisableRecurringEventSwitch() || event.recurringId === null}
            checked={isRecurring || DisableRecurringEventSwitch()}
            name={'recurringEventToggle'}
            onChange={() => setIsRecurring(!isRecurring)}
          />
          {
            isRecurring &&
            <Typography
              variant={'body2'}
              sx={{
                background: 'rgba(244,64,53,0.2)',
                padding: `${RemCalculator(4)} ${RemCalculator(8)}`,
                borderRadius: `${RemCalculator(4)}`,
                marginTop: `${RemCalculator(12)}`
              }}
            >
              {'This event will be restricted to no more than three months in duration'}
            </Typography>
          }
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Event start date"
            dataValue={'startDate'}
            minDate={new Date()}
            value={event.startDate ? event.startDate : null}
            name={'startDate'}
            error={errors && !event.startDate}
            onChange={handleChange}
            fontWeight={500}
            marginTop="0px"
            required={true}
            readonly={event?.isComingSoon === true ? false : isPublishedEvent(event)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Event end date"
            dataValue={'endDate'}
            minDate={event.startDate ? event.startDate : new Date()}
            value={event.endDate ? event.endDate : null}
            name={'endDate'}
            error={errors && !event.endDate}
            onChange={handleChange}
            fontWeight={500}
            marginTop="0px"
            required={true}
            readonly={event?.isComingSoon === true ? false : isPublishedEvent(event)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={"Start Time"}
              inputFormat={"HH:mm"}
              ampm={false}
              value={startTime ? startTime : String(event.startTime)}
              onChange={(data) => HandleTimePicker(data, 'startTime')}
              renderInput={(params) => <TextField {...params} data-value={"startTime"} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={"End Time"}
              inputFormat={"HH:mm"}
              ampm={false}
              value={endTime ? endTime : String(event.endTime)}
              onChange={(data) => HandleTimePicker(data, 'endTime')}
              renderInput={(params) => <TextField {...params} data-value={"endTime"} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectVariants
            error={errors && !event.type}
            label="Event type"
            name="type"
            values={EventTypes}
            value={event.type ? event.type : ''}
            handleChange={handleChange}
            required={true}
            color="#111111"
            fontWeight={500}
            id={'type'}
          />
        </Grid>
        {event.frequency && !edit && event.frequency !== 'single' && (
          <Grid item xs={12} md={6}>
            <SelectVariants
              error={
                errors &&
                ((event.frequency && event.frequency === 'weekly') ||
                  event.frequency === 'monthly' ||
                  event.frequency === 'daily') &&
                !event.sessionNumber
              }
              label="Number of sessions"
              name="sessionNumber"
              values={getFrequencies()}
              value={event.sessionNumber ? event.sessionNumber : ''}
              handleChange={handleChange}
              required={true}
              color="#111111"
              fontWeight={500}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <SelectVariants
            error={errors && !event.category}
            label="Event category"
            name="category"
            values={categories}
            value={event.category ? event.category : ''}
            handleChange={handleChange}
            required={true}
            color="#111111"
            fontWeight={500}
            id={"category"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AddressPicker
            error={errors && !event.location ? 'Please enter location' : ''}
            label="Event location"
            helper=""
            name="location"
            value={event.location?.address ? event.location?.address : ''}
            onChange={handleChange}
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Text
            error={errors && !event.host}
            label="Host"
            required={true}
            variant="standard"
            name="host"
            value={event.host ? event.host : ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Text
            error={errors && !event.description}
            label="Description"
            helperText="Add a detailed event description"
            required={true}
            variant="standard"
            multiline
            rows={4}
            name="description"
            data-value={"description"}
            value={event.description ? event.description : ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Text
            error={errors && !event.termsandconditions}
            label="Terms and conditions"
            helperText="Add additional event terms and conditions"
            required={true}
            variant="standard"
            multiline
            rows={4}
            name="termsandconditions"
            data-value={"termsAndConditions"}
            value={event.termsandconditions ? event.termsandconditions : ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Details;
