import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '../../store';
import {stripSeconds} from '../../utils';
import axios from 'axios';

import Admin from '../../pages/admin';

axios.defaults.baseURL = process.env.BASE_URL;

const Edit = () => {
  const events = useSelector((state: RootState) => state.events.value);
  const [event, setEvent] = React.useState<IEventNew>({});
  const {id} = useParams();

  React.useEffect(() => {
    if (id) {
      axios
        .get(`/api/events/${id}`)
        .then(result => {
          let event = result.data;
          if (event.id) {
            setEvent({
              id: event.id,
              title: event.title,
              startDate: event.startDate,
              endDate: event.endDate,
              endTime: stripSeconds(event.endTime),
              startTime: stripSeconds(event.startTime),
              type: event.isPublic ? 'public' : 'residentOnly',
              location: {
                address: event.location,
                location: {
                  lat: event.lat,
                  lng: event.lon,
                },
              },
              host: event.host,
              category: event.category,
              frequency: event.frequency,
              description: event.description,
              termsandconditions: event.termsAndCondition,
              notes: event.note,
              status: event.status,
              videoUrl: event.videoUrl,
              price: event.price,
              freeForResidents: event.freeForResidents,
              attendanceLimit: event.capacity > 0,
              capacity: event.capacity,
              editImages: event.imageUrls ? event.imageUrls : event.images,
              numberOfAttendees: event.numberOfAttendees,
              isComingSoon: event?.isComingSoon,
              recurringId: event?.recurringId,
              rsvpEmail: event?.rsvpEmail,
              rsvpPhone: event?.rsvpPhone,
              imageCaption: event?.imageCaption
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [id]);

  return <Admin edit={true} editableEvent={event} />;
};

export default Edit;
