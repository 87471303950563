import React from 'react';
import {Box, Button, useTheme} from '@resi/beeem-ui-components';

interface IChipProps {
  text: string;
  variant?: 'light' | 'dark';
}

const Chip = ({text, variant}: IChipProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: variant !== 'light' ? 'var(--green-theme)' : '#E0E0E0',
        color:
          variant !== 'light' ? theme.palette.secondary.contrastText : theme.palette.text.primary,
        borderRadius: '50px',
        width: 'fit-content',
        fontSize: '13px',
        lineHeight: '30px',
        padding: '0 10px',
      }}
    >
      {text}
    </Box>
  );
};

export default Chip;
