import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Link, Typography} from '@resi/beeem-ui-components';
import axios from 'axios';

import {RootState} from '../../store';

axios.defaults.baseURL = process.env.BASE_URL;

const LoadingPage = ({image}: IParentProps) => {
  const profile = useSelector((state: RootState) => state.profile.value);

  const resendEmail = async () => {
    const url = `/api/auth/resend-code`;

    let body = {
      emailAddress: profile.email,
    };
    try {
      const result = await axios.post(url, body, {
        withCredentials: true,
      });
    } catch (e) {
      console.log('error');
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        background: 'var(--green-theme)',
      }}
      data-value={'loading-screen'}
    >
      <Box
        sx={{
          margin: 'auto',
          maxWidth: '566px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '15px',
        }}
      >
        <Typography variant="h2" color="secondary.contrastText">
          An email has been sent to you so you can validate your email address. Follow the
          instructions in the email.
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body1"
          color="secondary.contrastText"
          sx={{marginTop: '50px', marginBottom: '80px'}}
        >
          The validation email expires in 30 minutes.{' '}
          <Link color="secondary.contrastText" onClick={() => resendEmail()}>
            Resend email if not received or expired.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingPage;
