import * as React from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField as Text,
} from '@resi/beeem-ui-components';
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from '@reduxjs/toolkit';
import {homehub} from '@resi/beeem-ui-theme';

import {fetchProfile} from '../../../../features/profile';
import {RootState} from '../../../../store';

axios.defaults.baseURL = process.env.BASE_URL;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const Registered = () => {
  const [emailAddress, setEmailAddress] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);

  const dispatch = useDispatch<AppThunkDispatch>();

  const signIn = async () => {
    setIsLoggingIn(true);
    if (!emailAddress && !password) {
      setErrorMessage('Please enter both your email and password');
    } else if (!emailAddress) {
      setErrorMessage('Please enter your email');
    } else if (!password) {
      setErrorMessage('Please enter your password');
    } else {
      setErrorMessage('');
      const url = `/api/auth/sign-in`;

      let result;
      try {
        result = await axios.post(
          url,
          {emailAddress, password},
          {
            withCredentials: true,
          }
        );
      } catch (e: any) {
        setIsLoggingIn(false);
        setErrorMessage(e.response?.data?.message);
      }
      setIsLoggingIn(false);
      if ((result && result.status === 200) || result?.statusText === 'OK') {
        dispatch(fetchProfile());
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '528px',
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Typography variant="h3" sx={{marginTop: '50px'}} color={'var(--green-theme)'}>
        Sign in
      </Typography>
      <Text
        label="Email"
        name="email"
        value={emailAddress}
        variant="outlined"
        onChange={event => setEmailAddress(event.target.value)}
        sx={{margin: '40px 0 0 0'}}
      />
      <Text
        label="Password"
        name="password"
        value={password}
        variant="outlined"
        type="password"
        onChange={event => setPassword(event.target.value)}
        sx={{margin: '20px 0 100px 0'}}
      />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label={<span className={'font-green'}>Keep me logged in</span>}
        />
      </FormGroup>

      <button
        disabled={isLoggingIn}
        onClick={() => signIn()}
        className={'btn-primary font-body'}
        style={{margin: '50px 0'}}
      >
        {isLoggingIn ? (
          <CircularProgress style={{color: homehub.palette.primary.contrastText}} />
        ) : (
          'Submit'
        )}
      </button>
    </Box>
  );
};
