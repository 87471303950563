//* Dropzone.js*//
import React from 'react';
import {useDropzone} from 'react-dropzone';
import {Typography, Button, Box} from '@resi/beeem-ui-components';

interface IDropProps {
  onDrop: (files: File[]) => void;
}
function Dropzone({onDrop}: IDropProps) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <input
        className="input-zone"
        {...getInputProps()}
        style={{
          display: 'block !important',
          opacity: '0'
        }}
      />
      <Box>
        <Typography
          variant="h5"
          color="#000000"
          sx={{display: {xs: 'none', md: 'block'}, marginTop: '90px', marginBottom: '20px'}}
        >
          Drag and drop a file here, or
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          background: '#374056',
          '&:hover': {
            background: '#01788B',
          },
          color: '#FFFFFF',
          marginLeft: '10px',
          marginTop: {xs: '20px', sm: 0},
          marginBottom: {xs: 0, sm: '40px'},
          width: {xs: '100%', sm: '172px'},
        }}
      >
        UPLOAD IMAGE
      </Button>
      <Typography
        variant="body2"
        color="rgba(0, 0, 0, 0.6)"
        sx={{display: {xs: 'none', md: 'block'}, marginBottom: '20px'}}
      >
        (Recommended image size 800x400. Max file size 400kb)
      </Typography>
    </Box>
  );
}
export default Dropzone;
