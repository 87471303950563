import * as React from 'react';
import axios from 'axios';
import {TextField, Autocomplete, Popper, styled} from '@resi/beeem-ui-components';
import Stack from '@mui/material/Stack';

axios.defaults.baseURL = process.env.BASE_URL;

interface IAddressPickerProps {
  label: string;
  helper: string;
  name: string;
  value: string;
  error?: string;
  onChange: (event: IAddressEvent) => void;
  required?: boolean;
  color?: ColorTypes;
}

const TextComponent = styled(TextField)(({theme}) => ({
  '.MuiFormLabel-root': {
    color: '#111111',
    fontWeight: 500,
    '&.Mui-error': {
      color: '#d32f2f',
    },
  },
}));

const AddressPicker = ({
  label,
  helper,
  name,
  value,
  error,
  onChange,
  required,
  color,
}: IAddressPickerProps) => {
  const [addresses, setAddresses] = React.useState<AddressType[]>([]);

  const [touched, setTouched] = React.useState(false);

  const handleTouch = () => {
    setTouched(true);
  };

  const defaultProps = {
    options: addresses,
    getOptionLabel: (option: AddressType | string) =>
      typeof option === 'string' ? option : option.address,
  };

  const CustomPopper = function (props: any) {
    return <Popper {...props} placement="bottom" />;
  };

  const getPlacePredictions = async (event: any) => {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 65 && event.keyCode <= 90)
    ) {
      const addresses = await axios.get(
        `https://api.getaddress.io/autocomplete/${event.target.value}?api-key=${process.env.REACT_APP_ADDRESS_API_KEY}&top=40`
      );

      setAddresses(addresses.data.suggestions);
    }
  };

  const fetchAddressInfo = async (value: AddressType) => {
    const address = await axios.get(
      `https://api.getaddress.io/get/${value.id}?api-key=${process.env.REACT_APP_ADDRESS_API_KEY}`
    );
    const formattedAddress = `${address.data.formatted_address
      .filter((value: string) => value !== '')
      .join(', ')}, ${address.data.postcode}`;

    onChange({
      target: {
        name: name,
        value: {
          address: formattedAddress,
          postcode: address.data.postcode,
          firstLineOfAddress: `${
            address.data.building_number ? address.data.building_number : address.data.line_1
          } ${address.data.thoroughfare}`,
          location: {lng: address.data.longitude, lat: address.data.latitude},
        },
      },
    });
  };

  return (
    <Stack spacing={1} sx={{width: '100%'}}>
      <Autocomplete
        {...defaultProps}
        freeSolo
        id="controlled-demo"
        value={value}
        onChange={(event: React.BaseSyntheticEvent, newValue: string | AddressType | null) => {
          if (newValue && typeof newValue === 'object' && newValue.id) {
            fetchAddressInfo(newValue);
          }
        }}
        filterOptions={options => options}
        renderInput={params => (
          <TextComponent
            {...params}
            data-value={'location-value'}
            name={name}
            label={label}
            required={required}
            variant="standard"
            onFocus={handleTouch}
            color={color}
            onKeyUp={(event: any) => getPlacePredictions(event)}
            error={error && !value ? true : false}
            helperText={error && !value ? error : touched && helper && !value && helper}
          />
        )}
        PopperComponent={CustomPopper}
      />
    </Stack>
  );
};

interface AddressType {
  address: string;
  id?: string;
}

export default AddressPicker;
