export const events = [
  {
    id: '1',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-14T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 0,
    freeForResidents: false,
    images: ['/images/yoga.png'],
    category: 'ARTS',
    isPublic: true,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '2',
    title: 'Yoga Brunch Club1',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-12T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 0,
    freeForResidents: false,
    images: ['/images/tacos.png'],
    category: 'SPORTSANDACTIVITIES',
    isPublic: true,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '3',
    title: 'Yoga Brunch Club2',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-11T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 10,
    images: ['/images/tacos.png'],
    category: 'FOODANDDRINK',
    isPublic: false,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'draft',
  },
  {
    id: '4',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-18T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 5,
    images: ['/images/tacos.png'],
    category: 'FOODANDDRINK',
    isPublic: false,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '5',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-10T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 15,
    images: ['/images/tacos.png'],
    category: 'SPORTSANDACTIVITIES',
    isPublic: false,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '6',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-14T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 0,
    freeForResidents: true,
    images: ['/images/tacos.png'],
    category: 'ARTS',
    isPublic: true,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '7',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-14T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 0,
    freeForResidents: false,
    images: ['/images/tacos.png'],
    category: 'CRAFTANDDIY',
    isPublic: true,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '8',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-14T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 40,
    freeForResidents: false,
    images: ['/images/tacos.png'],
    category: 'SPORTSANDACTIVITIES',
    isPublic: true,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'PUBLISHED',
  },
  {
    id: '9',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    bookingsNumber: 5,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-11T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 10,
    images: ['/images/tacos.png'],
    category: 'MENTALAGILITY',
    isPublic: false,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'draft',
  },
  {
    id: '10',
    title: 'Yoga Brunch Club',
    host: 'Louise Fletcher',
    bookingsNumber: 5,
    lat: 51.3814582824707,
    lon: -0.17856460809707642,
    capacity: 10,
    startTime: '17:00',
    endTime: '19:00',
    startDate: '2022-11-16T23:00:00.000Z',
    location: 'Little Kitchen, 32 Oxford Street, Bristol, BS3 4RJ',
    price: 5,
    images: ['/images/tacos.png'],
    category: 'SPORTSANDACTIVITIES',
    isPublic: false,
    description: `<p>Make your own authentic Vietnamese dishes with Little Kitchen, including Bhan mi and Summer rolls. Whether you’re new to cooking or just want to discover some more dishes to make at home, you'll finish up with a delicious meal to take away with you.</p>
    <p> Your host, Dot, is passionate about food and will be will take you through each recipe step by step.</p>
<p>
  What you'll be making:
  Pork Banh Mi with pickles and fresh herbs
  Summer rolls with Nouc cham dipping sauce
</p><p>
  All ingredients are included, equipment will be provided, and you'll be able to take home recipe cards so you can cook up these delicious dishes from home.</p>
<p>
  Little Kitchen also invites you to bring along a bottle of wine or beers to enjoy as you cook. Their beer expert Dan recommends Camden Hells lager or Pilsner Urquell.
</p><p>
  Please, let us know if you have any allergies, intolerances or special diet requirements.</p>`,
    status: 'draft',
  },
];

export const categories: {name: string; label: string}[] = [
  {name: 'SPORTSANDACTIVITIES', label: 'Sports & Activities'},
  {name: 'ARTS', label: 'Arts'},
  {name: 'CRAFTANDDIY', label: 'Crafts & DIY'},
  {name: 'MENTALAGILITY', label: 'Mental Agility'},
  {name: 'FOODANDDRINK', label: 'Food & Drink'},
  {name: 'CULTURE', label: 'Culture'},
];

export const times = [
  {
    name: '00:00:',
    label: '00:00',
  },
  {
    name: '1:00:',
    label: '1:00',
  },
  {
    name: '2:00:',
    label: '2:00',
  },
  {
    name: '3:00:',
    label: '3:00',
  },
  {
    name: '4:00:',
    label: '4:00',
  },
  {
    name: '5:00:',
    label: '5:00',
  },
  {
    name: '6:00:',
    label: '6:00',
  },
  {
    name: '7:00:',
    label: '7:00',
  },
  {
    name: '8:00:',
    label: '8:00',
  },
  {
    name: '9:00',
    label: '9:00',
  },
  {
    name: '10:00',
    label: '10:00',
  },
  {
    name: '11:00',
    label: '11:00',
  },
  {
    name: '12:00',
    label: '12:00',
  },
  {
    name: '13:00',
    label: '13:00',
  },
  {
    name: '14:00',
    label: '14:00',
  },
  {
    name: '15:00',
    label: '15:00',
  },
  {
    name: '16:00',
    label: '16:00',
  },
  {
    name: '17:00',
    label: '17:00',
  },
  {
    name: '18:00',
    label: '18:00',
  },
  {
    name: '19:00',
    label: '19:00',
  },
  {
    name: '20:00',
    label: '20:00',
  },
  {
    name: '21:00',
    label: '21:00',
  },
  {
    name: '22:00:',
    label: '22:00',
  },
  {
    name: '23:00:',
    label: '23:00',
  },
];

export const bookings = [
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 1,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 1,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 2,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
  {
    ID: '001',
    'First Name': 'John',
    'Last Name': 'Doe',
    Quote: '£1234.34',
    Pickup: 'LA9 7DZ',
    Destination: 'LA23 2EW',
    Status: 0,
  },
];

export const adminEvent = {
  title: 'Mr',
  startDate: new Date('2022-11-24T23:00:00.000Z'),
  endDate: new Date('2022-11-24T23:00:00.000Z'),
  startTime: '11:00:00',
  endTime: '12:00:00',
  category: 'category1',
  isPublic: true,
  host: 'host1',
  description: 'description1',
  isPaid: true,
  price: 10,
  capacity: 100,
  status: 'DRAFT',
  videoUrl: 'test',
  termsAndCondition: 'test1234',
  lat: 1000,
  lon: 2000,
  location: {address: 'testing', location: {lat: 1000, lng: 2000}},
  numberOfAttendees: 0,
  numberOfSessions: 2,
  note: null,
  freeForResidents: false,
};

export const guestlist = [
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: 'testing',
    price: '5.00',
    resident: true,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: 'testing this',
    price: '5.00',
    resident: true,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: '',
    price: '5.00',
    resident: true,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: '',
    price: 0,
    resident: false,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: '',
    price: '5.00',
    resident: true,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: '',
    price: 0,
    resident: false,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: '',
    price: '5.00',
    resident: true,
  },
  {
    firstName: 'Colin',
    lastName: 'Jones',
    emailAddress: 'colinluzio@yahoo.co.uk',
    phone: '07948894806',
    tickets: 2,
    notes: '',
    price: '5.00',
    resident: true,
  },
];

export const userBookings = [
  {
    id: '4345774674',
    date: 'Oct 16',
    time: '19:00 - 21:00',
    count: 2,
    location: 'Little Kitchen',
    title: 'Yoga brunch',
    orderDate: '12 Oct 2022',
    description: 'A wine tasting with a rich chocolate twist awaits in this pairing experience.',
    image: '/images/yoga.png',
  },
  {
    id: '4345774674',
    date: 'Oct 18',
    time: '19:00 - 21:00',
    count: 2,
    location: 'Little Kitchen',
    title: 'Yoga brunch',
    orderDate: '12 Oct 2022',
    description: 'A wine tasting with a rich chocolate twist awaits in this pairing experience.',
    image: '/images/yoga.png',
  },
  {
    id: '4345774674',
    date: 'Oct 17',
    time: '19:00 - 21:00',
    count: 2,
    location: 'Little Kitchen',
    title: 'Yoga brunch',
    orderDate: '12 Oct 2022',
    description: 'A wine tasting with a rich chocolate twist awaits in this pairing experience.',
    image: '/images/yoga.png',
  },
];

export const bookingEventMidday = {
  'date': '2023-01-01T23:00:00.000Z',
  'startTime': '12:00:00',
  'endTime': '13:00:00',
  'count': 2,
  'location': '5 Ashford Road, Bournemouth, Dorset, BH6 5QB',
  'title': 'Test Event with attendance',
  'orderDate': '2023-04-20T12:49:47.614Z',
  'description': 'Amazing time crafting',
}

export const bookingEvents = [{
  'date': '2023-04-22T23:00:00.000Z',
  'startTime': '12:00:00',
  'endTime': '13:00:00',
  'count': 2,
  'location': '5 Ashford Road, Bournemouth, Dorset, BH6 5QB',
  'title': 'Test Event with attendance',
  'orderDate': '2023-04-20T12:49:47.614Z',
  'description': 'Amazing time crafting',
}, {
  'date': '2023-06-30T23:00:00.000Z',
  'startTime': '10:00:00',
  'endTime': '15:00:00',
  'count': 2,
  'location': '20 St. Marys Gardens, Chatham, Kent, ME4 4UN',
  'title': 'Crafts & DIY  - Resident - free - 7 guest - in  July',
  'orderDate': '2023-04-24T09:01:06.076Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-09-14T23:00:00.000Z',
  'startTime': '10:00:00',
  'endTime': '17:00:00',
  'count': null,
  'location': '20 St. Marys Gardens, Chatham, Kent, ME4 4UN',
  'title': 'Food & Drink  - Resident - £9 - 9 guest - in  September',
  'orderDate': '2023-04-24T10:07:48.215Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': null,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-04-24T10:35:07.412Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': null,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-04-24T10:54:13.728Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-05-18T23:00:00.000Z',
  'startTime': '06:00:00',
  'endTime': '18:00:00',
  'count': null,
  'location': 'Kent Police, Medway Police Station Eastbridge, Purser Way, Gillingham, Kent, ME7 1NE',
  'title': 'Sports & Activities - Resident - £7 - 7 guest - in  May',
  'orderDate': '2023-04-24T10:33:06.133Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-10-18T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '18:00:00',
  'count': null,
  'location': 'Flat 25, Merlin House, Chatham Maritime, Chatham, Kent, ME4 4GW',
  'title': 'Culture  - Resident - £10 - 10 guest - in  October',
  'orderDate': '2023-04-24T10:45:27.224Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': null,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-04-24T13:38:57.738Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': null,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-04-24T14:04:52.685Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': 2,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-04-24T14:05:59.519Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': 1,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-04-25T10:05:39.028Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': 1,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-05-02T13:26:48.647Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-06-09T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '12:00:00',
  'count': 1,
  'location': 'Flat 54, North House, Gillingham Gate Road, Chatham, Kent, ME4 4RZ',
  'title': 'Arts  - Resident - free - 6 guest - - in  June',
  'orderDate': '2023-05-04T09:28:46.039Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-05-03T23:00:00.000Z',
  'startTime': '12:00:00',
  'endTime': '16:00:00',
  'count': 1,
  'location': '20 Freeman Gardens, Chatham, Kent, ME4 6ND',
  'title': 'Sports & Activities - Resident - free - 5 guest - in  May',
  'orderDate': '2023-05-04T09:39:29.728Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-07-06T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '14:00:00',
  'count': 1,
  'location': 'N R Medicine, PO Box 5923, Tain, IV19 9AE',
  'title': 'Crafts & DIY  - Resident - £5 - 7 guest - in  July',
  'orderDate': '2023-05-04T09:45:56.549Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-06-09T23:00:00.000Z',
  'startTime': '08:00:00',
  'endTime': '12:00:00',
  'count': 1,
  'location': 'Flat 54, North House, Gillingham Gate Road, Chatham, Kent, ME4 4RZ',
  'title': 'Arts  - Resident - free - 6 guest - - in  June',
  'orderDate': '2023-05-04T11:28:31.012Z',
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis pulvinar metus. Morbi suscipit placerat libero quis semper. In euismod purus quis consequat viverra.',
}, {
  'date': '2023-05-05T23:00:00.000Z',
  'startTime': '23:00:00',
  'endTime': '09:00:00',
  'count': 1,
  'location': '3 Ash Close, Wells, Somerset, BA5 2QR',
  'title': 'Event 4th May 11PM',
  'orderDate': '2023-05-04T21:08:55.812Z',
  'description': 'Test date booking',
}]
