import React from 'react';
import {Box, Button, Grid, styled, Typography} from '@resi/beeem-ui-components';
import axios from 'axios';
import {SelectChangeEvent} from '@mui/material/Select';
import Modal from '../../components/modal';
import Side from '../../components/side';
import AddressCard from '../../components/icons/address-card.svg';
import {ReactComponent as Person} from '../../components/icons/Person.svg';
import {ReactComponent as Delete} from '../../components/icons/delete.svg';
import {ReactComponent as Times} from '../../components/icons/times.svg';
import Images from '../../components/icons/images.svg';
import Pencil from '../../components/icons/pencil.svg';
import {ReactComponent as Star} from '../../components/icons/star.svg';
import ImageUploader from '../../components/imageuploader';
import Image from '../../components/image';
import Video from '../../components/icons/video.svg';
import {checkValidation, getFormattedDate} from '../../utils';

import Title from '../../components/title';

axios.defaults.baseURL = process.env.BASE_URL;

interface IMainProps {
  children?: React.ReactNode;
  edit?: boolean;
  editableEvent?: IEventNew;
}

const GuestBooking = () => {
  const [event, setEvent] = React.useState<IEventNew>({});
  const [errors, setErrors] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const confirmDelete = () => {};

  const GridItem = styled(Grid)(({theme}) => ({
    textAlign: 'left',
    '.MuiTypography-body1': {
      textAlign: 'left',
    },
  }));

  return (
    <Box
      sx={{
        padding: '20px 0',
        height: '100%',
        width: '100%',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Side />
      <Modal open={open} handleOpen={() => setOpen(true)} handleClose={() => setOpen(false)}>
        <Box>
          <Typography variant="h4" sx={{textAlign: 'left'}}>
            Delete
          </Typography>
          <Typography variant="h5" sx={{textAlign: 'left'}}>
            Are you sure you wish to delete
          </Typography>
          <Typography variant="h4" sx={{textAlign: 'left', marginTop: '20px'}}>
            Warning
          </Typography>
          <Typography variant="h5" sx={{textAlign: 'left'}}>
            This will automatically notify and refund guests
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
            <Button
              startIcon={<Delete />}
              sx={{
                textDecoration: 'underline',
                padding: '6px 0',
                '&:hover': {backgroundColor: 'initial', color: '#111111'},
              }}
              onClick={() => confirmDelete()}
            >
              Delete Event
            </Button>
            <Button
              startIcon={<Times />}
              sx={{
                textDecoration: 'underline',
                '&:hover': {backgroundColor: 'initial', padding: '6px 0', color: '#111111'},
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={{maxWidth: '1344px', margin: 'auto', padding: {xs: '0px 20px', lg: '0 100px'}}}>
        <Grid container spacing={{xs: '30px', lg: '100px'}} sx={{marginBottom: '50px'}}>
          <Grid item xs={12} md={6}>
            <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
              <Typography variant="body1" sx={{color: 'rgba(0, 0, 0, 0.6)'}}>
                <span style={{textDecoration: 'underline'}}>Events</span> /{' '}
                <span color="rgba(0, 0, 0, 0.35)">Add event</span>
              </Typography>
              <Typography variant="h2" color="#111111" sx={{marginTop: '20px'}}>
                Add a new event
                <span style={{fontSize: '22px'}}>
                  {event.startDate?.toLocaleDateString('en-GB')}
                </span>
              </Typography>
              <Typography variant="h4" color="#111111">
                <span style={{fontWeight: '500'}}>
                  {event.numberOfAttendees ? event.numberOfAttendees : 0}
                </span>{' '}
                guests attending of <span style={{fontWeight: '500'}}>{event.capacity}</span>{' '}
                available spaces.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'flex-end'}}>
            <Box
              sx={{
                marginTop: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                textAlign: 'right',
                '.MuiTypography-body1': {
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                },
              }}
            >
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Status:
              </Typography>
              <Button
                variant="contained"
                startIcon={<Person />}
                sx={{textTransform: 'initial', fontSize: '22px'}}
              >
                Resident
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing="100px">
          <Grid item xs={12} md={6}>
            <Box sx={{textAlign: 'left', '.MuiTypography-body1': {textAlign: 'left'}}}>
              <Title icon={AddressCard} title="Guest details" marginTop="20px" />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            maxWidth: '500px',
            marginTop: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Grid container spacing="25px">
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Name
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 400, marginRight: '20px'}}>
                Samuel Morrison
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Email
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 400, marginRight: '20px'}}>
                samuel.morrison@me.com
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Phone
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 400, marginRight: '20px'}}>
                Samuel Morrison
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Tickets
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 400, marginRight: '20px'}}>
                1
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 500, marginRight: '20px'}}>
                Price
              </Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography variant="body1" sx={{fontWeight: 400, marginRight: '20px'}}>
                £5.50
              </Typography>
            </GridItem>
          </Grid>
          <Typography variant="body1" sx={{fontWeight: 500, textAlign: 'left', margin: '20px 0'}}>
            Booking notes
          </Typography>
          <Typography variant="body1" sx={{textAlign: 'left'}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a arcu eget orci mollis
            aliquam ut et neque. Proin venenatis tincidunt scelerisque. Cras enim tellus, rutrum ut
            quam et, placerat ullamcorper nibh.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'initial',
              '&:hover': {color: '#111111'},
              marginRight: '20px',
              marginBottom: {xs: '20px', sm: '0px'},
              marginTop: '50px',
            }}
            onClick={() => setOpen(true)}
          >
            Cancel attendance and refund guests
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GuestBooking;
