import {Box, Button, Grid, Typography} from '@resi/beeem-ui-components';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Loader from '../../components/loader';
import {RootState} from '../../store';

const Confirmation = () => {
  const navigate = useNavigate();
  const {value: profile, loading} = useSelector((state: RootState) => state.profile);

  if (loading) return <div />;

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="25px"
      paddingTop="150px"
    >
      {profile.loggedIn && (
        <Typography variant="body1">
          Hi{' '}
          <Box component="span" sx={{textTransform: 'capitalize'}}>
            {profile.firstName}
          </Box>
          ,
        </Typography>
      )}

      <Typography variant="h1" color="secondary.main" textAlign="center" sx={{marginTop: 0}}>
        You're all booked in!
      </Typography>

      <Typography variant="body1">Great news, you're attending the event.</Typography>

      <Grid sx={{padding: '8px 0'}}>
        <Button variant="contained" onClick={() => navigate('/')}>
          Back to events
        </Button>
      </Grid>
    </Box>
  );
};

export default Confirmation;
