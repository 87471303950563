import {createTheme} from '@mui/material/styles';
import {orange} from '@mui/material/colors';
import {alpha} from '@mui/material';
import {DEFAULT} from '../shared/constants/constants';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
    brand: string;
    name: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    brand: string;
    name: string;
  }
}

/*
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '28px',
*/

const theme = createTheme({
  brand: DEFAULT,
  name: 'default',
  typography: {
    h1: {
      fontSize: 38,
      lineHeight: '52px',
      fontWeight: 300,
      letterSpacing: '-1.5px',
      textAlign: 'center',
      marginTop: '27px',
    },
    h2: {
      fontSize: 32,
      lineHeight: '38px',
      fontWeight: 300,
      letterSpacing: '-0.5px',
      marginTop: '39px',
    },
    h3: {
      fontSize: 28,
      lineHeight: '32.68px',
      fontWeight: 600,
      marginTop: '11px',
    },
    h4: {
      fontWeight: 400,
      fontSize: 22,
      lineHeight: '27.17px',
      letterSpacing: '0.25px',
    },
    body1: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '27px',
      letterSpacing: '0.15px',
      marginTop: '10px',
      textAlign: 'center',
    },
    body2: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '23px',
      letterSpacing: '0.15px',
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.4px',
      marginTop: '43px',
    },
  },
  components: {
    // Name of the component
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#F9F9F9 #F9F9F9',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#F9F9F9',
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            width: '6px',
            minHeight: '54px',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
          '.PrivatePickersSlideTransition-root': {
            minHeight: '240px ',
          },
          '.Mui-error': {
            color: 'red',
          },
        },
      },
    },
    MuiButtonBase: {},
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontWeight: 500,
          textTransform: 'uppercase',
          borderRadius: '30px',
          marginTop: '22px',
        },
        contained: {
          marginTop: '50px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        sizeLarge: {
          fontSize: 15,
          lineHeight: '26px',
        },
        sizeMedium: {
          fontSize: 14,
          lineHeight: '24px',
        },
        sizeSmall: {
          fontSize: 13,
          lineHeight: '22px',
        },
      },
    },
    MuiInput: {
      // For ListItem, change this to MuiListItem
      styleOverrides: {
        root: {
          textAlign: 'left',
          marginTop: '20px',
          '&.Mui-focused': {
            // this is to refer to the prop provided by M-UI
            backgroundColor: 'transparent', // updated backgroundColor
          },
        },
        input: {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '20px',
          width: '100%',
          '&.MuiInput-input': {
            textAlign: 'left',
          },
          '&.MuiSelect-select': {
            textAlign: 'left',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '24px',
          color: 'black',
          top: '-8px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '12px',
        },
      },
    },
    MuiSelect: {
      // For ListItem, change this to MuiListItem
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#fff',
          boxShadow:
            '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {},
    },
  },
  palette: {
    primary: {
      main: '#017163',
    },
    secondary: {
      main: '#111111',
    },
    text: {
      primary: '#000000',
    },
    error: {
      main: '#d32f2f',
    },
    background: {
      paper: alpha('#ffffff', 0.54),
      default: '#E3E3E3',
    },
  },
  status: {
    danger: orange[500],
  },
});

export default theme;
