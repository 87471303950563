import React from 'react'
import Dropzone from './Dropzone'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const Container = styled(Box)(({}) => ({
  width: '100%',
  background: '#EEF9FB',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  flexDirection: 'row',
  minHeight: '164px'
}))

const ImageUploader = () => {
  return (
    <Container>
      <Dropzone />
    </Container>
  )
}
export default ImageUploader
