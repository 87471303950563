import {ReactNode, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';

type Props = {
  children: ReactNode;
}

function UTMParamsTracker({
  children
}: Props): JSX.Element {

  const { search } = useLocation();
  const { set } = useLocalStorage();

  useEffect((): void => {
    if(search)
      set("utm_data", JSON.stringify(search.split("?")[1].split("&")));
  }, [search]);

  return <>{children}</>;
}

export default UTMParamsTracker;