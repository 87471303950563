import {Overlay} from './SessionTimedOut[Style]';
import axios from 'axios';
import {useState} from 'react';

axios.defaults.baseURL = process.env.BASE_URL;

function SessionTimedOut(): JSX.Element {
  const message: string = "Your session has expired due to inactivity. Please use the button below to sign out and log in again to continue.";
  const [error, setError] = useState<string | undefined>();

  async function HandleLogout(): Promise<void> {
    axios
      .post(`/api/events/logout`, { withCredentials: true })
      .then(() => {
        window.location.href = "/";
      })
      .catch(() => {
        setError("An unexpected error occurred. Please try again");
      })
  }

  return (
    <Overlay className={"d-col"}>
      <div className={"container d-col align-start"}>
        {
          error ?
          <p className={"font-left"}>{error}</p> :
          <>
            <h2 className={"font-barlow font-green font-underline"}>Session Expired</h2>
            <p className={"font-left"}>{message}</p>
          </>
        }
        <button
          className={"btn-primary font-barlow font-body"}
          onClick={HandleLogout}
        >
          Logout
        </button>
      </div>
    </Overlay>
  )
}

export default SessionTimedOut;