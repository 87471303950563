import React from 'react';
import Header from '../header';
import {Box, CircularProgress} from '@resi/beeem-ui-components';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import WallaceaFooter from '../Footer/Footer';
import Navigation from '../WallaceaHeader/Navigation';

interface IMainProps {
  children?: React.ReactNode;
  showBackButton?: boolean;
  noLoading?: boolean;
}

const Main = ({children, showBackButton, noLoading = false}: IMainProps) => {
  const profile = useSelector((state: RootState) => state.profile.value);
  const loading = useSelector((state: RootState) => state.profile.loading);
  const isFetching = useSelector((state: RootState) => state.events.isFetching);

  if ((loading || isFetching) && !noLoading)
    return (
      <Box height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box sx={{maxWidth: '1904px', margin: '0 auto'}}>
        <Navigation />
        {!loading && (
          <Header
            loggedIn={!!profile.loggedIn}
            profile={profile}
          />
        )}
        <Box>{children}</Box>
      </Box>
      <WallaceaFooter />
    </>
  );
};

export default Main;
