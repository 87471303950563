import { Box, Link, Typography } from "@mui/material";

const NoBookings = (): JSX.Element => {
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "5rem 0",
                gap: "2rem"
            }}
        >
            <Typography
                variant="body1"
                sx={{marginTop: '0px', fontWeight: 400}}
            >
                {"You currently have no event bookings"}
            </Typography>
            <Link
                href="/"
                className={"btn-primary font-body"}
                sx={{
                    textDecoration: 'none'
                }}
            >
                {"View all events"}
            </Link>
        </Box>
    )
}

export default NoBookings;