import React, {useEffect} from 'react';
import {Box, Grid, styled} from '@resi/beeem-ui-components';
import Event from '../../components/event';
import TopFilter from './components/topfilter';
import {filterEvents, filterEventResults} from '../../utils';
import RemCalculator from '../../Utility/RemCalculator';

const EventsBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
}));

interface IEventsProps {
  events: IEvent[];
}

const Events = ({events}: IEventsProps) => {
  const [attending, setAttending] = React.useState(false);
  const [filters, setFilters] = React.useState<IFilters>({
    startDate: new Date(),
  });
  const [showFilters, setShowFilters] = React.useState(false);
  const [filterResults, setFilterResults] = React.useState<IResultsFilter>({
    sort: 'date'
  });
  const [results, setResults] = React.useState<IEvent[]>([]);
  const [count, setCount] = React.useState(1);

  const onChangeResults = (event: React.BaseSyntheticEvent) => {
    setFilterResults({
      ...filterResults,
      [event.target.name]: event.target.value,
    });
  };

  const updateResults = () => {
    let _results = filterEventResults([...events], filters);
    _results = _results.slice(0, 9 * count);

    setResults(_results);
  };

  useEffect(() => {
    updateResults();
  }, [events, count, filters]);

  useEffect(() => {
    const filters = localStorage.getItem('filters');
    if (filters) {
      setFilters(JSON.parse(filters));
    }
    updateResults();
  }, []);

  function GetCurrentEvents(events: IEvent[]) {
    return events.filter(event => {
      if(new Date(event.startDate).setHours(24, 0, 0, 0) >= Date.now()) {
        return event;
      }
    });
  }

  return (
    <div>
      <Grid container padding={RemCalculator(16)}>
        <Grid item xs={12} md={12} lg={12}>
          <EventsBox>
            <TopFilter
              attending={attending}
              setAttending={setAttending}
              setShowFilters={() => setShowFilters(true)}
              onChange={onChangeResults}
              count={events?.length ? filterEvents(filterEventResults(events, filters), filterResults).length : 0}
              filters={filterResults}
            />
            <Grid container spacing="24px">
              {GetCurrentEvents(events).map((event, index) => {
                return (
                  <Grid item xs={12} sm={6} lg={3} key={index}>
                    <Event {...event} />
                  </Grid>
                );
              })}
            </Grid>
          </EventsBox>
        </Grid>
      </Grid>
    </div>
  );
};

export default Events;
