import {styled} from '@mui/material/styles';

export const Hero = styled("div")({
  width: '100%',
  maxHeight: "600px",
  ".info": {
    background: "#F6F3EC",
    maxHeight: "600px",
    ".rsvp": {
      background: "#D7D8B8",
      padding: "25px 21px",
      ".email": {
        textDecoration: "underline"
      }
    },
    ".info-container": {
      maxWidth: "425px",
      gap: "32px",
      ".title-host": {
        gap: "16px"
      }
    }
  },
  img: {
    objectFit: "cover",
    minHeight: "600px",
    maxHeight: "600px"
  },
  "@media screen and (max-width: 1080px)": {
    marginTop: "16px",
    maxHeight: "100%",
    padding: "0",
    ".container": {
      gridTemplateColumns: "1fr"
    },
    ".info": {
      maxHeight: "auto"
    },
    ".info-container": {
      padding: "82px 16px 92px"
    },
    img: {
      width: "100%",
      minHeight: "393px",
      maxHeight: "393px",
      objectFit: "cover"
    },
  }
})

export const Location = styled("div")({
  padding: "111px 0 74px",
  textAlign: "left",
  ".content": {
    gap: "32px",
    padding: "0 60px"
  },
  ".description": {
    textAlign: "left",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap"
  },
  h1: {
    fontSize: "28px",
    fontWeight: 300,
    lineHeight: "30.8px",
    letterSpacing: "-0.01em"
  },
  ".header": {
    gap: "16px",
  },
  ".map-container": {
    height: "600px",
    width: "100%",
    "@media screen and (max-width: 1080px)": {
      padding: "45px 0 0"
    }
  },
  "@media screen and (max-width: 1080px)": {
    gridTemplateColumns: "1fr",
    padding: "85px 0 0",
    ".content": {
      gap: "32px",
      padding: "fit-content"
    },
  }
})

export const InfoSection = styled("div")({
  background: "#F6F3EC",
  padding: "128px 0 118px",
  gap: "60px",
  ".text": {
    gap: "20px",
    maxWidth: "70%",
    margin: "0 auto",
    ".link": {
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  "@media screen and (max-width: 1080px)": {
    ".text": {
      maxWidth: "70%"
    },
    gridTemplateColumns: "1fr",
    padding: "39px 0",
    gap: "70px"
  }
})