type Enquiries<S = string> = {
    header: S,
    content: S[];
};

export type FooterData<S = string> = {
    otherLinks: {
        header: S;
        links: {
            name: S;
            path: S;
        }[]
    };
    salesEnquiries: Enquiries;
    generalEnquiries: Enquiries;
    salutation: {
        header: S;
        caption: S;
    }
}

export const footerData: FooterData = {
    otherLinks: {
        header: "Other links",
        links: [
            {
                name: "Who we are",
                path: "https://wallacealiving.com/about-us"
            },
            {
                name: "News",
                path: "https://wallacealiving.com/news"
            },
            {
                name: "Privacy Policy",
                path: "https://wallacealiving.com/privacy-policy"
            },
            {
                name: "Cookie Policy",
                path: "https://wallacealiving.com/cookie-policy"
            },
            {
                name: "Feedback",
                path: "https://wallacealiving.com/feedback"
            },
        ]
    },
    salesEnquiries: {
        header: "Sales Enquiries",
        content: [
            "020 3960 1000",
            "Monday-Friday 9am- 5pm",
            "enquiries@wallacealiving.com"
        ]
    },
    generalEnquiries: {
        header: "General Enquiries",
        content: [
            "020 3960 1005",
            "Monday-Friday 9am- 5pm",
            "info@wallacealiving.com"
        ]
    },
    salutation: {
        header: `Wallacea Living ©${new Date().getFullYear()}`,
        caption: "Wallacea Living Opco Limited is a Limited Company registered in England and Wales under company number 13529550 and whose registered office is Bury Lodge, Bury Road, Stowmarket, England, IP14 1JA"
    }
}