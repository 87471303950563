type ReturnType = {
    get: Function;
    remove: Function;
    set: Function
}

function useLocalStorage(): ReturnType {
    function GetFromLocalStorage<S extends string>(key: S): S {
        return localStorage.getItem(key) as S;
    }

    function DeleteFromLocalStorage(key: string) {
        localStorage.removeItem(key);
    }

    function SetToLocalStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    return {
        get: GetFromLocalStorage,
        set: SetToLocalStorage,
        remove: DeleteFromLocalStorage
    }
}

export default useLocalStorage;