import Modal from '../../modal';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Typography} from '@resi/beeem-ui-components';
import React, {useEffect, useState} from 'react';
import RemCalculator from '../../../Utility/RemCalculator';
import {IGuestList} from '../../../pages/guestlist/IGuestList';
import {ReusableStyling} from './AmendBooking[Style]';
import axios from 'axios';

export type AmendBookingOptions = 'amend' | 'cancel' | 'verify' | 'viewNotes';

axios.defaults.baseURL = process.env.BASE_URL;

type Props<T> = {
  open: boolean;
  ToggleModal: Function;
  data?: T;
  option: AmendBookingOptions;
}

function AmendBooking(props: Props<IGuestList>): JSX.Element {
  const {
    data,
    open,
    ToggleModal,
    option
  } = props;

  const [tickets, setTickets] = useState<number>();
  const [error, setError] = useState<any>();
  const [requestStatus, setRequestStatus] = useState<{
    status: boolean;
    message: any;
  }>();

  useEffect(() => {
    data && setTickets(data.numberOfTickets);
  }, [data])

  function HandleChange(e: any): void {
    setTickets(e.target.value);
  }

  async function AmendBooking(): Promise<void> {
    axios.patch(`/api/bookings/update/${data?.bookingId}`, {tickets})
      .then(result => {
        ToggleModal();
        window.location.reload();
      })
      .catch(error => setError(error?.response))
  }

  async function CancelBooking(data: IGuestList): Promise<void> {
    axios.post(`/api/bookings/cancel`, {
      bookingNumber: data.bookingNumber,
      emailAddress: data['User.emailAddress']
    })
      .then(res => setRequestStatus({
        status: true,
        message: 'Booking has been cancelled'
      }))
      .catch(err => setRequestStatus({
        status: false,
        message: err?.response
      }))
  }

  async function Verify(data: IGuestList): Promise<void> {
    axios.post(`/api/bookings/resend-confirmation`, {
      bookingNumber: data.bookingNumber,
      emailAddress: data['User.emailAddress']
    })
      .then(res => setRequestStatus({
        status: true,
        message: 'Verification sent'
      }))
      .catch(err => setRequestStatus({
        status: false,
        message: err?.response
      }))
  }

  function ToggleOption(option: AmendBookingOptions, data?: IGuestList): JSX.Element {
    if(!data) return <>{'Error'}</>
    switch (option) {
      case 'cancel':
        return (
          <>
            <Typography
              variant="h4"
              fontFamily={'brandon-grotesque'}
            >
              {`Cancel booking for ${data['User.firstName']} ${data['User.lastName']}`}
            </Typography>
            <Typography
              variant="body1"
              fontFamily={'brandon-grotesque'}
            >
              {`We will dispatch confirmation of this action to both parties`}
            </Typography>
            {
              requestStatus?.status === false ?
              <Typography
                variant="body1"
                fontFamily={'brandon-grotesque'}
                color={'red'}
              >
                {"An unexpected error occurred, please try again later"}
              </Typography> :
              <Typography
                variant="body1"
                fontFamily={'brandon-grotesque'}
              >
                {requestStatus?.message}
              </Typography>
            }
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: RemCalculator(32),
                ['@media (max-width:760px)']: {
                  flexDirection: 'column',
                  gap: RemCalculator(16)
                },
              }}
            >
              {
                (requestStatus?.status === false || !requestStatus) &&
                <Button
                  variant={"contained"}
                  disabled={!tickets}
                  onClick={() => CancelBooking(data)}
                  sx={{ bgcolor: 'error.main', ':hover': { bgcolor: 'error.main' } }}
                >
                  <Typography
                    fontFamily={'Barlow'}
                    fontSize={'1rem'}
                    color={'white'}
                  >
                    {"Confirm"}
                  </Typography>
                </Button>
              }
              <Button
                variant={"contained"}
                onClick={() => {
                  ToggleModal();
                  requestStatus?.status && window.location.reload();
                }}
              >
                <Typography
                  fontFamily={'Barlow'}
                  fontSize={'1rem'}
                  color={'white'}
                >
                  {requestStatus ? "Close" : "Cancel"}
                </Typography>
              </Button>
            </Box>
          </>
        )

      case 'amend':
        return (
          <>
            <Box sx={ReusableStyling(32)}>
              <Typography
                variant="h4"
                fontFamily={'brandon-grotesque'}
              >
                {`Amend number of tickets for ${data['User.firstName']} ${data['User.lastName']}`}
              </Typography>
              <Typography
                variant="body1"
                fontFamily={'brandon-grotesque'}
              >
                {`Current booking has`} <strong>{data.numberOfTickets}</strong> {`tickets`}
              </Typography>
              {
                error &&
                <Typography
                  variant="body1"
                  fontFamily={'brandon-grotesque'}
                  color={'red'}
                >
                  {error?.data?.error?.response}
                </Typography>
              }
            </Box>
            <div>
              <FormControl variant="standard" sx={{minWidth: 120}}>
                <InputLabel>Tickets</InputLabel>
                <Select
                  onChange={HandleChange}
                  label={'Tickets'}
                  value={tickets ?? ''}
                >
                  {
                    Array.from(Array(4).keys()).map((value, index) => (
                      <MenuItem value={value+1} key={index}>
                        {value+1}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: RemCalculator(32),
                ['@media (max-width:760px)']: {
                  flexDirection: 'column',
                  gap: RemCalculator(16)
                },
              }}
            >
              <Button
                variant={"outlined"}
                disabled={!tickets}
                onClick={AmendBooking}
              >
                <Typography
                  fontFamily={'Barlow'}
                  fontSize={'1rem'}
                  color={'black'}
                >
                  {"Confirm"}
                </Typography>
              </Button>
              <Button
                variant={"contained"}
                onClick={() => ToggleModal()}
              >
                <Typography
                  fontFamily={'Barlow'}
                  fontSize={'1rem'}
                  color={'white'}
                >
                  {"Cancel"}
                </Typography>
              </Button>
            </Box>
          </>
        )

      case 'viewNotes':
        return (
          <>
            <Box sx={ReusableStyling(32)}>
              <Typography
                variant="h4"
                fontFamily={'brandon-grotesque'}
              >
                {`Notes from ${data['User.firstName']} ${data['User.lastName']}`}
              </Typography>
              <Typography
                variant="body1"
                fontFamily={'brandon-grotesque'}
              >
                {data.notes}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: RemCalculator(32),
                ['@media (max-width:760px)']: {
                  flexDirection: 'column',
                  gap: RemCalculator(16)
                },
              }}
            >
              <Button
                variant={"contained"}
                onClick={() => ToggleModal()}
              >
                <Typography
                  fontFamily={'Barlow'}
                  fontSize={'1rem'}
                  color={'white'}
                >
                  {"Close"}
                </Typography>
              </Button>
            </Box>
          </>
        )

      default:
        return (
          <>
            <Typography
              variant="h4"
              fontFamily={'brandon-grotesque'}
            >
              {`Send email verification to ${data['User.firstName']} ${data['User.lastName']}`}
            </Typography>
            {
              requestStatus?.status === false ?
                <Typography
                  variant="body1"
                  fontFamily={'brandon-grotesque'}
                  color={'red'}
                >
                  {"An unexpected error occurred, please try again later"}
                </Typography> :
                <Typography
                  variant="body1"
                  fontFamily={'brandon-grotesque'}
                >
                  {requestStatus?.message}
                </Typography>
            }
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: RemCalculator(32),
                ['@media (max-width:760px)']: {
                  flexDirection: 'column',
                  gap: RemCalculator(16)
                },
              }}
            >
              {
                requestStatus?.status ?
                <></> :
                <Button
                  variant={"outlined"}
                  onClick={() => Verify(data)}
                >
                  <Typography
                    fontFamily={'Barlow'}
                    fontSize={'1rem'}
                    color={'black'}
                  >
                    {"Confirm"}
                  </Typography>
                </Button>
              }
              <Button
                variant={"contained"}
                onClick={() => ToggleModal()}
              >
                <Typography
                  fontFamily={'Barlow'}
                  fontSize={'1rem'}
                  color={'white'}
                >
                  {"Close"}
                </Typography>
              </Button>
            </Box>
          </>
        )
    }
  }

  return (
    <Modal open={open} handleClose={() => ToggleModal()}>
      <Box
        sx={ReusableStyling(32 * 2)}
        data-testid={'amend-booking-container'}
      >
        <Box sx={ReusableStyling(32 * 2)}>
          {ToggleOption(option, data)}
        </Box>
      </Box>
    </Modal>
  )
}

export default AmendBooking;
