import React from 'react'
import { Box, Typography, Button, Link } from '@mui/material'
import { styled } from '@mui/material/styles'

interface IProductProps {
  title: string
  offerText?: string
  price: number
  link: string
  image: string
  buttonText: string
}

const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  border: 'solid 1px rgba(0, 0, 0, 0.12)',
  background: '#fff',
  boxShadow: '0px 4px 40px rgb(0 0 0 / 8%)',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative'
}))

const Card = styled(Box)(({}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const Offer = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  position: 'absolute',
  top: '-10px',
  left: '10px',
  borderRadius: '50px',
  fontSize: '13px',
  padding: '0 10px'
}))

const Product = ({
  title,
  offerText,
  price,
  link,
  image,
  buttonText
}: IProductProps) => {
  return (
    <Container>
      <Link href={link} underline='none'>
        <Box
          sx={{
            width: '100%',
            height: '160px',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        {offerText && <Offer>{offerText}</Offer>}
        <Card>
          <Typography
            variant='h6'
            color='text.primary'
            sx={{ padding: '15px' }}
          >
            {title}
          </Typography>
        </Card>
        <Card>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px'
            }}
          >
            <Typography variant='body1' color='black'>
              £{price.toFixed(2)}
            </Typography>
            <Button variant='contained' size='small'>
              {buttonText}
            </Button>
          </Box>
        </Card>
      </Link>
    </Container>
  )
}
export default Product
