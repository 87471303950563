import * as React from 'react';
import {InputLabel, MenuItem, FormControl, Select} from '@resi/beeem-ui-components';
import {SelectChangeEvent} from '@mui/material/Select';

interface ISelectProps {
  label: string;
  values: Category[];
  value: string;
  name?: string;
  handleChange: (event: SelectChangeEvent) => void;
  required?: boolean;
  color?: string;
  fontWeight?: number;
  error?: boolean;
  id?: string;
}

const SelectVariants = ({
  label,
  name,
  values,
  value,
  required,
  handleChange,
  color,
  fontWeight,
  error,
  id,
}: ISelectProps) => {
  return (
    <div>
      <FormControl variant="standard" sx={{minWidth: 120}}>
        <InputLabel
          required={required}
          sx={{...(fontWeight && {fontWeight: fontWeight}), ...(color && {color: color})}}
          error={error}
        >
          {label}
        </InputLabel>
        <Select
          required={required}
          labelId={label}
          id={id || label}
          value={value}
          name={name}
          onChange={handleChange}
          label={label}
          data-testid={label}
          error={error}
          inputProps={{
            'data-testid': id || label || '',
          }}
        >
          {values.map((selectValue, index) => {
            return (
              <MenuItem value={selectValue.name} key={index}>
                {selectValue.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectVariants;
