import * as React from 'react';
import {Box, styled, Typography, Tabs, Tab} from '@resi/beeem-ui-components';

interface Item {
  title: string;
  content: React.ReactNode;
}
interface ITabsProps {
  items: any;
  color?: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `-tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function BasicTabs({items, color}: ITabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const TabsComponent = styled(Tabs)(({theme}) => ({
    '.MuiButtonBase-root': {
      '&.MuiTab-root': {
        maxWidth: '112px',
        padding: '12px 6px',
        [theme.breakpoints.up('md')]: {
          maxWidth: '190px',
          padding: '12px 6px;',
        },
        '&.Mui-selected': {
          color: color ? color : 'var(--green-theme)',
        },
      },
    },
    '.MuiTabs-indicator': {
      backgroundColor: color ? color : 'var(--green-theme)',
    },
  }));
  return (
    <Box sx={{width: '100%'}}>
      <Box>
        <TabsComponent
          value={value}
          onChange={handleChange}
          aria-label="Tabs component"
          color="secondary"
        >
          {items.map((item: Item, index: number) => {
            return <Tab label={item.title} {...a11yProps(0)} sx={{width: '50%'}} key={index} />;
          })}
          ;
        </TabsComponent>
      </Box>
      {items.map((item: Item, index: number) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            {item.content}
          </TabPanel>
        );
      })}
    </Box>
  );
}
