export function FormatEventDate(eventDate: string): string {
  const date: number = new Date(eventDate).getDate();
  
  const formattedDate: string = new Intl.DateTimeFormat("en-GB", {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format((new Date(eventDate)));

  return formattedDate.replace(`, ${date}`, ` ${date}${GetOrdinalSuffix(date)}`);
}

function GetOrdinalSuffix(day: number): "th" | "st" | "rd" | "nd" {
  if (day > 3 && day < 21) return 'th';

  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function GetEventTime(time: string): string {
  let [hours, minutes] = time.split(':');
  let meridian: string = 'am';
  let hoursInt: number = parseInt(hours, 10);
  let minutesInt: number = parseInt(minutes, 10);

  if (hoursInt >= 12) {
    meridian = 'pm';
    hoursInt -= hoursInt > 12 ? 12 : 0;
  }

  if (hoursInt === 0) hoursInt = 12;
  hours = (hoursInt).toString().slice(-2);

  let timeString: string = `${hours}`;
  if (minutesInt >= 1) timeString += `:${minutes}`;
  timeString += `${meridian}`;

  return timeString;
}