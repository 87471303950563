import React from 'react';
import {useDispatch} from 'react-redux';
import {
  Box,
  Button,
  Typography,
  TextField,
  styled,
  Link,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@resi/beeem-ui-components';

import {setProfile} from '../../../../features/profile';
import type {RootState} from '../../../../store';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from '@reduxjs/toolkit';
import {checkPassword} from '../../../../utils';

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const PasswordField = styled(TextField)(() => ({
  '.MuiFormLabel-root': {
    '&.MuiInputLabel-root': {
      top: '0px',
    },
  },
}));
const ItemList = styled(ListItem)(() => ({
  display: 'list-item',
  padding: 0,
}));
interface IProfileProps {
  profile: IProfile;
  reminder?: boolean;
  error?: boolean;
}

const Password = ({profile, reminder, error}: IProfileProps) => {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        width: '529px',
        maxWidth: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"
        color="secondary"
        sx={{marginTop: '40px', marginBottom: {xs: '35px', md: '40px'}}}
      >
        Create a password
      </Typography>
      <PasswordField
        variant="outlined"
        type="password"
        label="Password"
        error={error && !checkPassword(profile.password)}
        sx={{marginBottom: '20px'}}
        value={profile.password ? profile.password : ''}
        onChange={event => dispatch(setProfile({name: 'password', value: event.target.value}))}
        color="secondary"
        name={"password"}
      />
      <PasswordField
        variant="outlined"
        type="password"
        label="Confirm your password"
        error={error && profile.password !== profile.confirmPassword}
        value={profile.confirmPassword ? profile.confirmPassword : ''}
        onChange={event =>
          dispatch(setProfile({name: 'confirmPassword', value: event.target.value}))
        }
        color="secondary"
        name={"confirmPassword"}
      />
      <Typography variant="body1" color="#000000" sx={{textAlign: 'left', marginTop: '41px'}}>
        Password requirements:
      </Typography>
      <List sx={{listStyleType: 'disc', pl: 4}}>
        <ItemList>One lower case character </ItemList>
        <ItemList>One upper case character </ItemList>
        <ItemList>One number </ItemList>
        <ItemList>10 characters minimum</ItemList>
      </List>
      {!reminder && (
        <>
          <FormGroup sx={{marginTop: '56px'}}>
            <FormControlLabel
              sx={{'& .MuiFormControlLabel-label': {textAlign: 'left'}}}
              control={
                <Checkbox
                  checked={profile.terms ? profile.terms : false}
                  name={'marketingOptIn'}
                  onChange={event =>
                    dispatch(setProfile({name: 'terms', value: event.target.checked}))
                  }
                />
              }
              label={
                <span>
                  I have read and accept the{' '}
                  <Link
                    color="primary.dark"
                    href="https://wallacealiving.com/privacy-policy"
                    target="_blank"
                  >
                    privacy policy.
                  </Link>
                </span>
              }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              sx={{'& .MuiFormControlLabel-label': {textAlign: 'left'}}}
              control={
                <Checkbox
                  checked={profile.marketing ? profile.marketing : false}
                  name={'privacyPolicy'}
                  onChange={event =>
                    dispatch(setProfile({name: 'marketing', value: event.target.checked}))
                  }
                />
              }
              label={<span>I’m happy to receive marketing communications</span>}
            />
          </FormGroup>
        </>
      )}
    </Box>
  );
};

export default Password;
