import React from 'react'
import { ThemeProvider, Theme } from '@mui/material/styles'

interface LibraryThemeProviderProps {
  children?: React.ReactNode
  theme: Theme
}

const LibraryThemeProvider: React.FC<LibraryThemeProviderProps> = ({
  children,
  theme
}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default LibraryThemeProvider
