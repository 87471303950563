import {Box, Grid, TextField} from '@resi/beeem-ui-components';
import Select from '../../../../components/selectvariants';

interface ITopFilter {
  attending: boolean;
  setAttending: (attending: boolean) => void;
  setShowFilters: () => void;
  onChange: (event: any) => void;
  count: number;
  filters: IResultsFilter;
}

const TopFilter = ({
  attending,
  setAttending,
  setShowFilters,
  onChange,
  count,
  filters,
}: ITopFilter) => {
  return (
    <Box
      className={"d-col align-start"}
      gap={4}
    >
      <h1
        className={"font-oswald font-x-large font-green"}
      >
        {count} event{count !== 1 ? 's' : ''} found for you
      </h1>
      <Grid
        sx={{
          display: 'flex',
          marginTop: '16px',
          marginBottom: '16px',
          width: '100%'
        }}
      >
        <Grid
          className={"d-row align-center justify-between w-100"}
          gap={4}
        >
          <Grid item xs={9} md={6} sx={{display: 'flex', justifyContent: 'space-between'}}>
            <TextField
              name="search"
              id="search"
              label="Search"
              aria-label="Search"
              variant="outlined"
              sx={{width: '100%', maxWidth: '466px'}}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Select
              values={[
                {label: 'Date', name: 'date'},
                {label: 'Price', name: 'price'},
              ]}
              name="sort"
              label="Organise by"
              handleChange={onChange}
              value={filters.sort ? filters.sort : ''}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopFilter;
