import React from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

axios.defaults.baseURL = process.env.BASE_URL;

const VerifyAccount = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email');
    const code = params.get('code');

    axios
      .post(`/api/auth/confirm`, {emailAddress: email, code})
      .then(result => {
        navigate('/signin');
      })
      .catch(function (error) {
      });
  }, []);

  return <></>;
};

export default VerifyAccount;
