import { Link } from "react-router-dom";
import Menu from "../../../assets/Icons/Menu";
import { navLinks } from "../NavLinks";
import { CloseMenu, MobileMenuContainer, MobileMenuList } from "./MobileMenu[Style]";
import { useState, useEffect } from "react";
import Close from "../../../assets/Icons/Close";

function MobileMenu(): JSX.Element {

    const [isActive, setIsActive] = useState<boolean>(false);

    function HandleMenuToggle(): void {
        setIsActive(isActive ? false : true);
    }

    useEffect(() => {
        isActive ? document.body.style.overflow = "hidden" : document.body.style.overflow = "";
    }, [isActive])

    return (
        <MobileMenuContainer>
            <button
                onClick={HandleMenuToggle}
            >
                {
                    isActive ?
                    <CloseMenu className="d-row align-center">
                        <p className="font-body font-green font-barlow">Close</p>
                        <Close width={30 * 1.25} height={30 * 1.25} fill={"var(--green-theme)"} />
                    </CloseMenu>
                    :
                    <Menu width={30 * 1.25} height={30 * 1.25} fill={"var(--green-theme)"} />
                }
            </button>
            {   isActive &&
                <MobileMenuList
                    className={`d-col justify-start`}
                    paddingTop={16}
                    paddingBottom={16}
                    paddingLeft={16}
                    paddingRight={16}
                >
                    <div
                        className="mobile-nav-list-container d-col align-start justify-between h-100 w-100"
                    >
                        <div
                            className="container d-col justify-center f-grow w-100"
                        >
                            {
                                navLinks.map(navLink => (
                                    <Link
                                        key={navLink.path}
                                        to={navLink.path}
                                        rel={"noopener"}
                                        className={"font-body font-green font-barlow btn-secondary"}
                                    >
                                        {navLink.name}
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                    <div className="contact-btn-container d-row w-100 justify-center">
                        <Link
                            to={"/"}
                            className="font-green font-body btn-primary w-100"
                        >
                            {"Contact"}
                        </Link>
                    </div>
                </MobileMenuList>
            }
        </MobileMenuContainer>
    )
}

export default MobileMenu;