import RemCalculator from "../../Utility/RemCalculator";

type Props<S extends string, N = number> = {
    className?: S;
    width: N;
    height: N;
    fill?: S;
}

function Logo({className, width, height, fill}: Props<string>): JSX.Element {
    return (
            <svg viewBox="0 0 167 45" xmlns="http://www.w3.org/2000/svg" width={RemCalculator(width)} height={RemCalculator(height)} fill={"none"} className={className}>
            <path d="M50.1805 29.4543V41.5939H57.3448V43.4081H48.2921V29.4543H50.1805Z" fill={fill}/>
            <path d="M63.4773 29.4543V43.4081H61.5889V29.4543H63.4773Z" fill={fill}/>
            <path d="M79.6067 29.39L73.3963 44L67.186 29.39H69.4248L73.4742 39.4452L77.3873 29.39H79.6067Z" fill={fill}/>
            <path d="M85.3628 29.4543V43.4081H83.4744V29.4543H85.3628Z" fill={fill}/>
            <path d="M103.001 29.4544V43.8713H102.943L92.6049 33.4687L92.6439 43.4082H90.736V29.0298H90.8333L101.151 39.5482L101.113 29.4544H103.001Z" fill={fill}/>
            <path d="M117.021 43.309C116.106 43.5921 115.259 43.7336 114.481 43.7336C113.014 43.7336 111.723 43.4345 110.607 42.8362C109.49 42.2379 108.63 41.4177 108.027 40.3755C107.423 39.3333 107.122 38.1624 107.122 36.8629C107.122 35.3446 107.446 34.029 108.095 32.9161C108.744 31.8031 109.62 30.9507 110.723 30.3589C111.827 29.767 113.034 29.4711 114.344 29.4711C115.136 29.4711 115.882 29.5644 116.583 29.7509C117.284 29.9375 117.914 30.1852 118.472 30.494L117.849 32.2116C117.433 31.98 116.911 31.7774 116.281 31.6037C115.652 31.43 115.084 31.3432 114.578 31.3432C113.488 31.3432 112.524 31.5619 111.687 31.9993C110.85 32.4368 110.204 33.0608 109.75 33.8714C109.296 34.682 109.069 35.6277 109.069 36.7085C109.069 37.7121 109.296 38.6063 109.75 39.3912C110.204 40.176 110.847 40.784 111.677 41.215C112.508 41.646 113.455 41.8616 114.52 41.8616C115.013 41.8616 115.493 41.8133 115.96 41.7168C116.427 41.6203 116.817 41.482 117.128 41.3019V38.542H115.116L114.325 36.7471H119.036V42.3826C118.608 42.7172 117.936 43.026 117.021 43.309Z" fill={fill}/>
            <path d="M51.6744 1.78491V17.971H61.2268V20.3899H49.1565V1.78491H51.6744Z" fill={fill}/>
            <path d="M70.9343 1.78491V17.971H80.4867V20.3899H68.4164V1.78491H70.9343Z" fill={fill}/>
            <path d="M120.915 20.5616C119.989 20.8961 118.981 21.0634 117.891 21.0634C115.97 21.0634 114.278 20.6433 112.816 19.8025C111.353 18.9623 110.229 17.83 109.442 16.4057C108.654 14.982 108.261 13.4123 108.261 11.6965C108.261 9.86113 108.658 8.20971 109.455 6.74292C110.25 5.27613 111.353 4.12715 112.764 3.29469C114.174 2.46286 115.771 2.04663 117.553 2.04663C118.609 2.04663 119.595 2.1843 120.513 2.45836C121.43 2.73306 122.252 3.09332 122.979 3.53915L121.966 5.88086C120.529 4.92037 119.085 4.43981 117.631 4.43981C116.42 4.43981 115.299 4.75761 114.27 5.39193C113.24 6.0269 112.418 6.88896 111.804 7.97811C111.189 9.0679 110.882 10.2645 110.882 11.5679C110.882 12.8713 111.163 14.1155 111.726 15.1962C112.288 16.277 113.093 17.1224 114.14 17.731C115.187 18.3402 116.394 18.6445 117.761 18.6445C119.439 18.6445 120.957 18.1729 122.203 17.2292L123.212 19.4679C122.606 19.8629 121.84 20.2271 120.915 20.5616Z" fill={fill}/>
            <path d="M142.867 1.78491V4.17809H133.185V9.83937H141.578L141.584 12.2325H133.185V17.971H143.231V20.3899H130.667V1.78491H142.867Z" fill={fill}/>
            <path d="M17.6648 20.3513L13.2779 9.23459L8.47578 20.4028L1 1H3.93321L8.78727 14.5356L12.032 6.09515L10.0332 1H12.5252L17.7426 14.2525L22.3631 1H25.0627L17.6648 20.3513Z" fill={fill}/>
            <path d="M37.9863 15.6962H31.0563L29.1367 20.3918H26.7434L34.6216 1.78296H34.7708L42.6483 20.3912H39.8566L37.987 15.6956L37.9863 15.6962ZM37.1635 13.6202L34.5956 7.0962L31.9285 13.6202H37.1635Z" fill={fill}/>
            <path d="M98.4817 15.6962H91.5517L89.6322 20.3918H87.2389L95.1163 1.78296H95.2656L103.143 20.3912H100.351L98.4817 15.6956V15.6962ZM97.6589 13.6202L95.091 7.0962L92.4239 13.6202H97.6589Z" fill={fill}/>
            <path d="M161.338 15.6962H154.408L152.488 20.3918H150.095L157.973 1.78357H158.122L165.999 20.3918H163.208L161.338 15.6962ZM160.515 13.6201L157.947 7.09617L155.28 13.6201H160.515Z" fill={fill}/>
        </svg>
    )
}

export default Logo;