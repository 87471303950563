import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  styled,
  TextField as Text,
} from '@resi/beeem-ui-components';
import {useSelector, useDispatch} from 'react-redux';
import {checkPassword, getTicketNumbers, pluraliseWord} from '../../../../utils';
import SelectVariants from '../../../../components/selectvariants';
import {SelectChangeEvent} from '@mui/material/Select';

import {RootState} from '../../../../store';
import {setBooking} from '../../../../features/booking/bookingSlice';

interface IGuestProps {
  errors: boolean;
  event: IEvent;
  loginError: boolean;
  makeBooking: () => void;
  isSending: boolean;
}

const ExistingUser = ({errors, event, loginError, makeBooking, isSending}: IGuestProps) => {
  const dispatch = useDispatch();
  const booking = useSelector((state: RootState) => state.booking.value);

  const handleChange = (event: React.BaseSyntheticEvent | SelectChangeEvent) => {
    dispatch(setBooking({name: event.target.name, value: event.target.value}));
  };

  const ItemList = styled(ListItem)(() => ({
    display: 'list-item',
    padding: 0,
  }));

  return (
    <Box>
      {!event.price && (
        <Typography variant="body1" sx={{margin: '20px 0', textAlign: 'left'}} color="var(--green-theme)">
          This event is free to attend.
        </Typography>
      )}
      <Grid container spacing="25px">
        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="body1" sx={{textAlign: 'left', color: 'var(--green-theme)', fontWeight: 500}}>
            Tickets required?
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectVariants
            values={getTicketNumbers(event)}
            label=""
            name="tickets"
            handleChange={event => handleChange(event)}
            value={booking.tickets ? booking.tickets : '0'}
            error={errors && (!booking.tickets || booking.tickets === '0')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" sx={{textAlign: 'left', color: 'var(--green-theme)', fontWeight: 500}}>
            Add a note for the host
          </Typography>
          <Text
            value={booking.notes ? booking.notes : ''}
            name="notes"
            label="Add message"
            onChange={handleChange}
          />
        </Grid>
        {booking.createAccount && (
          <>
            <Grid item xs={12} md={12}>
              <Text
                value={booking.password ? booking.password : ''}
                name="password"
                label="Password"
                onChange={handleChange}
                sx={{marginTop: '-5px'}}
                type="password"
                error={
                  (errors && !booking.password) || (errors && !checkPassword(booking.password))
                }
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{paddingTop: '20px'}}>
              <Text
                value={booking.confirmPassword ? booking.confirmPassword : ''}
                name="confirmPassword"
                label="Confirm your password"
                onChange={handleChange}
                type="password"
                error={
                  (errors && !booking.confirmPassword) ||
                  booking.confirmPassword !== booking.password
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body1"
                color="#000000"
                sx={{textAlign: 'left', marginTop: '20px'}}
              >
                Password requirements:
              </Typography>
              <List sx={{listStyleType: 'disc', pl: 4}}>
                <ItemList>One lower case character </ItemList>
                <ItemList>One upper case character </ItemList>
                <ItemList>One number </ItemList>
                <ItemList>10 characters minimum</ItemList>
              </List>

              <FormGroup sx={{marginTop: '56px'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{color: errors && !booking.terms ? 'red' : 'rgba(0, 0, 0, 0.7)'}}
                      checked={booking.terms ? booking.terms : false}
                      onChange={event =>
                        dispatch(setBooking({name: 'terms', value: !booking.terms}))
                      }
                    />
                  }
                  label={
                    <span>
                      I have read and accept the{' '}
                      <Link
                        color="primary.dark"
                        href="https://wallacealiving.com/privacy-policy"
                        target="_blank"
                      >
                        privacy policy.
                      </Link>
                    </span>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={booking.marketing ? booking.marketing : false}
                      onChange={event =>
                        dispatch(setBooking({name: 'marketing', value: !booking.marketing}))
                      }
                    />
                  }
                  label={<span>I'm happy to receive marketing communications</span>}
                />
              </FormGroup>
            </Grid>
          </>
        )}
        {event.price > 0 && (
          <>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="#202020" sx={{textAlign: 'left'}}>
                Order summary
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color="#202020" sx={{textAlign: 'left', fontWeight: 500}}>
                {booking.tickets || '0'} x General admission{' '}
                {pluraliseWord(parseInt(booking.tickets || '0'), 'ticket')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                color="#202020"
                sx={{textAlign: 'right', fontWeight: 500}}
              >
                £{event.price}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color="#202020" sx={{textAlign: 'left', fontWeight: 500}}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                color="#202020"
                sx={{textAlign: 'right', fontWeight: 500}}
              >
                £{event.price * parseInt(booking.tickets ? booking.tickets : '0')}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          {loginError && (
            <Alert severity="error" sx={{boxShadow: 'none', marginBottom: '20px'}}>
              An account exists for this email.{' '}
              <span style={{textDecoration: 'underline'}}>Sign in to make a booking</span>
            </Alert>
          )}
          <Box
            className={"d-row justify-end"}
          >
            <button
              className={"btn-primary font-body"}
              onClick={makeBooking}
              disabled={isSending}
            >
              {isSending ? 'Sending' : 'Book now'}
            </button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExistingUser;
