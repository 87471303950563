import {useState, MouseEvent, ChangeEvent} from 'react';
import {Navigate} from 'react-router-dom';
import {useTheme, styled} from '@mui/material/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  TablePagination,
  Card,
  IconButton,
} from '@resi/beeem-ui-components';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {ReactComponent as Person} from '../../components/icons/Person.svg';
import EventNote from '../../components/icons/EventNote.svg';
import {IGuestList} from '../../pages/guestlist/IGuestList';
import Edit from '../../components/icons/edit.svg';
import Delete from '../../components/icons/delete.svg';
import AmendBooking, {AmendBookingOptions} from './AmendBooking/AmendBooking';

interface Props {
  data: IGuestList[];
  disabledState?: boolean;
  showConfirmedActions?: boolean;
  showUnconfirmedActions?: boolean;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const Container = styled(Card)(({theme}) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow: 'none',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  '.MuiTableRow-root': {
    '&:hover': {
      background: 'rgba(63, 81, 181, 0.08)',
    },
    '.MuiTableCell-root': {
      fontWeight: 500,
    },
    '.MuiTableCell-head': {
      fontSize: '14px',
    },
  },
}));

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function CustomPaginationActionsTable({
  data,
  disabledState,
  showConfirmedActions,
  showUnconfirmedActions
}: Props): JSX.Element {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState('');
  const [goToDashboard, setGoToDashboard] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [booking, setBooking] = useState<IGuestList>();
  const [option, setOption] = useState<AmendBookingOptions>('amend');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const selectBooking = (id: string) => {
    setSelected(id);
    setGoToDashboard(true);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (goToDashboard) {
    return <Navigate to={`/${selected}`} />;
  }

  function ToggleModal(data?: IGuestList): void {
    setIsOpen(!isOpen);
    data && setBooking(data);
  }

  function ControlModalAction(row: IGuestList, option: AmendBookingOptions): void {
    ToggleModal(row);
    setOption(option);
  }

  return (
    <>
      <TableContainer component={Container}>
        <Table sx={{minWidth: 500}} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Tickets</TableCell>
              <TableCell sx={{textAlign: 'center'}}>Notes</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Event Status</TableCell>
              {
                showConfirmedActions &&
                <>
                  <TableCell>Edit</TableCell>
                  <TableCell>Cancel</TableCell>
                </>
              }
              { showUnconfirmedActions && <TableCell>Resend Verification</TableCell> }
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 && data?.length > 0
              ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row: IGuestList, index: number) => (
              <TableRow key={index}>
                <TableCell sx={disabledState ? { color: '#00000059' } : {}}>
                  {row['User.firstName']} {row['User.lastName']}
                </TableCell>
                <TableCell sx={disabledState ? { color: '#00000059' } : {}}> {row['User.emailAddress']}</TableCell>
                <TableCell sx={disabledState ? { color: '#00000059' } : {}}>{row['User.phoneNumber']}</TableCell>
                <TableCell sx={disabledState ? { color: '#00000059' } : {}}>{row.numberOfTickets}</TableCell>
                <TableCell sx={{ textAlign: 'center', color: disabledState ? '#00000059' : '' }}>
                  {
                    row.notes ?
                    <Box
                      component="img"
                      src={EventNote}
                      sx={{cursor: 'pointer'}}
                      onClick={() => ControlModalAction(row, 'viewNotes')}
                    /> :
                    '-'
                  }
                </TableCell>
                <TableCell sx={disabledState ? { color: '#00000059' } : {}}>{!row.total ? 'Free' : `£${row.total}`}</TableCell>
                <TableCell>
                  {' '}
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={row['User.residentStatus'] ? <Person /> : ''}
                    sx={{textTransform: 'initial', '&:hover': {boxShadow: 'none'}, background: disabledState ? '#00000059' : ''}}
                  >
                    {row['User.residentStatus'] ? 'Resident' : 'Guest'}
                  </Button>
                </TableCell>
                {
                  showConfirmedActions &&
                  <>
                    <TableCell>
                      <Box
                        component="img"
                        src={Edit}
                        sx={{cursor: 'pointer'}}
                        onClick={() => ControlModalAction(row, 'amend')}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        component="img"
                        src={Delete}
                        sx={{cursor: 'pointer'}}
                        onClick={() => ControlModalAction(row, 'cancel')}
                      />
                    </TableCell>
                  </>
                }
                {
                  showUnconfirmedActions &&
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={row['User.residentStatus'] ? <Person /> : ''}
                      sx={{':hover': { color: 'black' }}}
                      onClick={() => ControlModalAction(row, 'verify')}
                    >
                      {"Email copy"}
                    </Button>
                  </TableCell>
                }
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{height: 53 * emptyRows}}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                colSpan={10}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AmendBooking
        open={isOpen}
        ToggleModal={ToggleModal}
        data={booking}
        option={option}
      />
    </>
  );
}

export default CustomPaginationActionsTable;