interface IEnvironmentLens<T> {
  local: T;
  dev: T;
  staging: T;
  prod: T;
}

type PathCheckerProp = 'http' | 'dev' | 'qa' | 'https';

function PathChecker(needle: PathCheckerProp): boolean {
  return window.origin.includes(needle);
}

function EnvironmentLens(): IEnvironmentLens<boolean> {
  return {
    local: PathChecker('http'),
    dev: PathChecker('dev'),
    staging: PathChecker('qa'),
    prod: PathChecker('https')
  }
}

export default EnvironmentLens;