import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';

axios.defaults.baseURL = process.env.BASE_URL;

export const fetchEvents = createAsyncThunk('event/eventsLoading', () =>
  axios.get(`/api/events`).then((response: any) => response.data)
);

interface RemovePayloadValue {
  value: string;
}

export interface EventsState {
  value: IEvent[] | null;
  isFetching?: boolean;
}

const initialState: EventsState = {
  value: null,
  isFetching: false,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    removeEvents: (state, action: PayloadAction<string[]>) => {
      state.value = state.value?.filter(event => !action.payload.includes(event.id)) || null;
    },
  },
  extraReducers: {
    [fetchEvents.pending.type]: (state, action) => {
      state.value = null;
      state.isFetching = true;
    },
    [fetchEvents.fulfilled.type]: (state, action) => {
      state.value = action.payload.filter((event: IEvent) => !!event.title);
      state.isFetching = false;
    },
    [fetchEvents.rejected.type]: (state, action) => {
      state.value = [];
      state.isFetching = false;
    },
  },
});

export const {removeEvents} = eventsSlice.actions;

export default eventsSlice.reducer;
