export const TITLES: {label: string; value: string}[] = [
  {label: 'Mr', value: 'Mr'},
  {label: 'Mrs', value: 'Mrs'},
  {label: 'Miss', value: 'Miss'},
  {label: 'Ms', value: 'Ms'},
  {label: 'Sir', value: 'Sir'},
  {label: 'Lady', value: 'Lady'},
  {label: 'Lord', value: 'Lord'},
  {label: 'Dr', value: 'Dr'},
  {label: 'Colonel', value: 'Colonel'},
  {label: 'Captain', value: 'Captain'},
  {label: 'Reverend', value: 'Reverend'},
  {label: 'Major', value: 'Major'},
  {label: 'Professor', value: 'Professor'},
  {label: 'Judge', value: 'Judge'},
];

export const EventTypes: {name: string; label: string}[] = [
  {
    name: 'residentOnly',
    label: 'Resident only'
  },
  {
    name: 'public',
    label: 'Public'
  }
]

export const categories: {name: string; label: string}[] = [
  {name: 'SPORTSANDACTIVITIES', label: 'Sports & Activities'},
  {name: 'ARTS', label: 'Arts'},
  {name: 'CRAFTANDDIY', label: 'Crafts & DIY'},
  {name: 'MENTALAGILITY', label: 'Mental Agility'},
  {name: 'FOODANDDRINK', label: 'Food & Drink'},
  {name: 'CULTURE', label: 'Culture'},
];