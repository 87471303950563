import { styled } from "@mui/material";
import { NavContainerProps } from "../Navigation[Style]";
import RemCalculator from "../../../Utility/RemCalculator";

const MobileMenuContainer = styled('div')(({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
 }: NavContainerProps) => ({
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    "button": {
        position: "relative",
        zIndex: 6
    }
}));

const MobileMenuList = styled('div')(({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
 }: NavContainerProps) => ({
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    position: "absolute",
    background: "var(--secondary-color)",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 5,
    ".mobile-nav-list-container": {
        padding: "0 10% 0"
    },
    ".container": {
        gap: RemCalculator(35),
        "a": {
            borderBottom: "1px solid var(--green-theme-aux)",
            textAlign: "left"
        }
    },
}));

const CloseMenu = styled('div')({
    gap: RemCalculator(16/2)
});

export {
    MobileMenuContainer,
    MobileMenuList,
    CloseMenu
}