import { Link } from 'react-router-dom';
import Logo from '../../assets/Icons/Logo';
import ArcoLogo from '../../images/arco_logo.png';
import { footerData } from './FooterData';
import { Content, ContentData, FooterContainer, FooterNode, Image, Salutation } from './Footer[Style]';
import useDetectDevice from '../../hooks/useDetectDevice';

function Footer(): JSX.Element {

  const {
    generalEnquiries,
    salesEnquiries,
    otherLinks,
    salutation
  } = footerData;

  const { isMobile } = useDetectDevice();

  return (
    <FooterNode>
      <FooterContainer
        paddingTop={20}
        paddingRight={16}
        paddingLeft={16}
        paddingBottom={20}
        className={"d-col w-100"}
      >
        <Content className="d-row flex-wrap align-start justify-between w-100">
          <ContentData className="d-col align-start footer-data-container">
            <h1
              className="font-body font-light font-barlow font-left font-w-lg"
            >
              {otherLinks.header}
            </h1>
            <div className="d-col link-content align-start">
              {
                otherLinks.links.map((link) => (
                  <Link
                    key={link.path}
                    to={link.path}
                    className={"font-body font-light font-barlow"}
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "var(--secondary-color)"
                    }}
                  >
                    {link.name}
                  </Link>
                ))
              }
            </div>
          </ContentData>
          <ContentData className="d-col align-start footer-data-container">
            <h1
              className="font-body font-light font-barlow font-left font-w-lg"
            >
              {salesEnquiries.header}
            </h1>
            <div className="d-col link-content align-start">
              {
                salesEnquiries.content.map((data) => (
                  <p
                    key={data}
                    className={"font-body font-light font-barlow"}
                  >
                    {data}
                  </p>
                ))
              }
            </div>
          </ContentData>
          <ContentData className="d-col align-start footer-data-container">
            <h1
              className="font-body font-light font-barlow font-left font-w-lg"
            >
              {generalEnquiries.header}
            </h1>
            <div className="d-col link-content align-start">
              {
                generalEnquiries.content.map((data) => (
                  <p
                    key={data}
                    className={"font-body font-light font-barlow"}
                  >
                    {data}
                  </p>
                ))
              }
            </div>
          </ContentData>
          <ContentData className="d-col footer-data-container">
            <Image src={ArcoLogo} alt="Arco | Setting Standards for Retirement Communities" className={"arco-image"} />
          </ContentData>
        </Content>
        <Salutation
          className={`${isMobile ? "d-col" : "d-row"} start-center justify-between`}
        >
          <div className="d-col align-start font-left text">
            <h3 className="font-body font-light">
              {salutation.header}
            </h3>
            <p className="font-body-xs font-light">{salutation.caption}</p>
          </div>
          <div
            className={`d-col ${isMobile ? "w-100 align-start" : "w-100 align-end"}`}
          >
            <Logo width={200} height={42.7} fill={"var(--secondary-color)"} />
          </div>
        </Salutation>
      </FooterContainer>
    </FooterNode>
  );
};

export default Footer;