import { INavLink } from "./INavLink";

export const navLinks: INavLink<string>[] = [
    {
        name: "Life at Wallacea",
        path: "https://wallacealiving.com/life-at-wallacea"
    },
    {
        name: "Residence",
        path: "https://wallacealiving.com/residences"
    },
    {
        name: "Wellbeing",
        path: "https://wallacealiving.com/wellbeing"
    },
    {
        name: "How it works",
        path: "https://wallacealiving.com/how-it-works"
    },
    {
        name: "Who we are",
        path: "https://wallacealiving.com/who-we-are"
    },
    {
        name: "Events",
        path: "https://wallacealiving.com/events"
    },
    {
        name: "News",
        path: "https://wallacealiving.com/news"
    }
];