import {styled} from '@mui/material/styles';

export const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgb(0, 0, 0, .75)",
  zIndex: 10,
  ".container": {
    background: "white",
    padding: "32px 16px",
    gap: "32px",
    "button": {
    }
  }
});