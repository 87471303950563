import React from 'react';
import {Box, Button, Grid, Typography} from '@resi/beeem-ui-components';
import Clock from '../../../../components/icons/clock-black.svg';
import Ticket from '../../../../components/icons/ticket.svg';
import MapMarker from '../../../../components/icons/map-marker.svg';
import {convertDate, pluraliseWord, stripSeconds} from '../../../../utils';

interface IBookingProps {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  count: number;
  location: string;
  title: string;
  orderDate: Date;
  description: string;
  image: string;
  last?: boolean;
  cancelBooking: (id: string) => void;
  historic?: boolean;
}

type EventDateReturnType<S extends string> = {
  day: S;
  month: S;
}

const Booking = ({
  id,
  date,
  startTime,
  endTime,
  count,
  location,
  title,
  orderDate,
  description,
  image,
  last,
  cancelBooking,
  historic = false,
}: IBookingProps) => {
  const eventTime = `${stripSeconds(startTime)} - ${stripSeconds(endTime)}`;

  function SetEventDate<S extends string>(eventDate: S): EventDateReturnType<string> {
    let dateMaker: S[] = eventDate.split(" ") as S[];

    return {
      day: dateMaker[0],
      month: `${dateMaker[1]} - ${dateMaker[2]}`
    } satisfies EventDateReturnType<string>;
  }

  return (
    <Box
      sx={{
        marginTop: '30px',
        paddingBottom: '30px',
        borderBottom: !last ? 'solid 1px rgba(0, 0, 0, 0.12)' : 'none',
      }}
    >
      <Grid container spacing="15px">
        <Grid item xs={12} md={2.5}>
          <Box>
            <Grid container spacing="15px">
              <Grid item xs={6} md={12}>
                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                  <Box
                    sx={{
                      backgroundImage: `url(${Clock})`,
                      backgroundRepeat: 'no-repeat',
                      width: '48px',
                      height: '48px',
                      backgroundPositionY: '2px',
                      marginTop: '4px'
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{fontWeight: 600, textAlign: 'left', width: '100%'}}
                      color={"var(--green-theme)"}
                    >
                      {SetEventDate<string>(convertDate(date)).day}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{fontWeight: 600, textAlign: 'left', width: '100%'}}
                      color={"var(--green-theme)"}
                    >
                      {SetEventDate<string>(convertDate(date)).month}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{fontWeight: 600, textAlign: 'left', width: '100%'}}
                      color={"var(--green-theme)"}
                    >
                      {eventTime}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={12}>
                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                  <Box
                    sx={{
                      backgroundImage: `url(${Ticket})`,
                      backgroundRepeat: 'no-repeat',
                      width: '48px',
                      height: '48px',
                      backgroundPositionY: '2px',
                      marginTop: '2px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{fontWeight: 600, textAlign: 'left', width: '100%'}}
                    color={"var(--green-theme)"}
                  >
                    {count} x {pluraliseWord(count, 'ticket')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={12}>
                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                  <Box
                    sx={{
                      backgroundImage: `url(${MapMarker})`,
                      backgroundRepeat: 'no-repeat',
                      width: '48px',
                      height: '48px',
                      backgroundPositionY: '2px',
                      marginTop: '4px'
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 400,
                      textAlign: 'left',
                      textDecoration: 'underline',
                      width: '100%',
                    }}
                    color={"var(--green-theme)"}
                  >
                    {location}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4.5}>
          <Box
            sx={{
              backgroundImage: `url(${image ?? '/images/yoga.png'})`,
              backgroundSize: 'cover',
              width: '100%',
              height: {xs: '200px', md: '100%'},
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
            <Typography
              variant="h3"
              sx={{textAlign: 'left', width: '100%', marginTop: '0px'}}
              color={"var(--green-theme)"}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{textAlign: 'left', width: '100%', marginTop: '0px', fontWeight: 600}}
              color={"var(--green-theme)"}
            >
              <>Ordered on {new Date(orderDate).toLocaleString('en-GB').split(",")[0]}</>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'left',
                width: '100%',
                marginTop: '30px',
                lineHeight: '25px',
                fontWeight: 400,
              }}
              color={"var(--green-theme)"}
            >
              {description}
            </Typography>
            {!historic && (
              <Box
                sx={{marginTop: '30px', display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}
              >
                <button
                  className={"btn-primary font-body"}
                  onClick={() => cancelBooking(id)}
                >
                  CANCEL BOOKING
                </button>
                {/*<Button*/}
                {/*  variant="contained"*/}
                {/*  sx={{*/}
                {/*    width: {xs: '100%', md: 'auto'},*/}
                {/*    marginLeft: {xs: '0px', md: '20px'},*/}
                {/*    marginTop: {xs: '20px', md: '0px'},*/}
                {/*  }}*/}
                {/*>*/}
                {/*  CONTACT HOST*/}
                {/*</Button>*/}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Booking;
