import axios from 'axios';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../store';
import {parseDateTimeFromUTCToLocale} from '../utils';

axios.defaults.baseURL = process.env.BASE_URL;

export const useCreateBooking = () => {
  const [isSending, setIsSending] = useState(false);

  const bookingRequest = async (event: IEvent, booking: any) => {
    try {
      setIsSending(true);

      const data = {
        eventId: event.id,
        numberOfTickets: parseInt(booking.tickets ? booking.tickets : ''),
        notes: booking.notes,
        eventDateTime: parseDateTimeFromUTCToLocale(event.startDate, event.startTime),
      };

      await axios
        .post('/api/bookings', data)
        .then(result => {
          // If is paid event will return URL to open, otherwise is free stop isSending so we can display message

          if (result.data.url) {
            window.open(result.data.url, '_self');
          } else {
            setIsSending(false);
          }
          return true;
        })
        .catch(error => {
          throw new Error(error);
        });
    } catch (e) {
      setIsSending(false);
      return new Error('Something went wrong');
    }
  };

  return {bookingRequest, isSending};
};
