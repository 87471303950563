import { useEffect, useState } from "react";

type ReturnType<T> = { isDesktop: T, isTablet: T, isMobile: T };

function useDetectDevice(): ReturnType<boolean> {
    const [device, setDevice] = useState<number>(window.innerWidth);

    function SetDeviceWidth() {
        setDevice(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", SetDeviceWidth);

        () => window.removeEventListener("resize", SetDeviceWidth);
    }, [device])

    return {
        isDesktop: device > 1280,
        isTablet: device > 767 && device < 1281,
        isMobile: device < 768
    }
}

export default useDetectDevice;