import {Box, Divider, Typography} from '@resi/beeem-ui-components';
import {useNavigate} from 'react-router-dom';

type Status = 'booked' | 'notbooked' | 'fullybooked' | 'expired' | 'sent' | 'bookeduser';

export const BookingStatus = ({
  status,
  initiateGuestBooking,
}: {
  status: Status | string;
  initiateGuestBooking: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{'.MuiTypography-root': {textAlign: 'left'}}}
      data-name={"bookingConfirmation"}
    >
      {status === 'sent' ? (
        <>
          <Typography variant="h2">
            An email has been sent to you so you can validate your email address. Follow the
            instructions in the email.
          </Typography>
          <Typography variant="body1" sx={{margin: '50px 0 150px 0'}}>
            The validation email expires in 30 minutes.{' '}
            <span
              style={{textDecoration: 'underline', cursor: 'pointer'}}
              onClick={initiateGuestBooking}
            >
              Resend email if not received or expired.
            </span>
          </Typography>
        </>
      ) : status === 'booked' ? (
        <>
          <Typography variant="h2" color="var(--green-theme)" sx={{marginBottom: '20px'}}>
            Your ticket to this event has been booked.
          </Typography>
          <Divider />
          <Typography variant="body1" color="#202020" sx={{marginTop: '20px'}}>
            Please check your email for confirmation.
          </Typography>
          <Box sx={{margin: '40px 0', display: 'flex', flexDirection: 'row'}}>
            <button className={"btn-primary font-body"} style={{ marginRight: '20px' }} onClick={() => navigate(`/signin`)}>
              Sign In
            </button>
            <button className={"btn-primary font-body"} onClick={() => navigate(`/onboarding`)}>
              Create Account
            </button>
          </Box>
        </>
      ) : status === 'bookedUser' ? (
        <>
          <Typography variant="h2" color="var(--green-theme)" sx={{marginBottom: '20px'}}>
            Your ticket to this event has been booked.
          </Typography>
          <Divider />
          <Typography variant="body1" color="#202020" sx={{marginTop: '20px'}}>
            Please check your email for confirmation.
          </Typography>
          <Box sx={{margin: '40px 0', display: 'flex', flexDirection: 'row'}}></Box>
        </>
      ) : status === 'fullybooked' ? (
        <>
          <Typography variant="h2" color="#EB3754" sx={{marginBottom: '20px'}}>
            Unfortunately this event is fully booked
          </Typography>
          <Divider />
          <Typography variant="body1" color="#202020" sx={{margin: '20px 0 150px 0'}}>
            Please check and try again
          </Typography>
        </>
      ) : (
        <>
          {' '}
          <Typography variant="h2" color="#EB3754" sx={{marginBottom: '20px'}}>
            This link has expired
          </Typography>
          <Divider />
          <Typography variant="body1" color="#202020" sx={{marginTop: '20px'}}>
            Please close and try again
          </Typography>{' '}
        </>
      )}
    </Box>
  );
};
