import axios from 'axios';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../store';
import {parseDateTimeFromUTCToLocale} from '../utils';
import PrepareUTMData from '../Utility/UTMParamsTracker/PrepareUTMData';

axios.defaults.baseURL = process.env.BASE_URL;

export const useCreateGuestBooking = () => {
  const [isSending, setIsSending] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const booking = useSelector((state: RootState) => state.booking.value);

  const bookingRequest = async (event: IEvent) => {
    try {
      setIsSending(true);

      const data = {
        eventId: event.id,
        salutation: booking.salutation || 'Mr',
        firstName: booking.firstName,
        lastName: booking.lastName,
        emailAddress: booking.email,
        confirmEmailAddress: booking.confirmEmail,
        ...(booking.createAccount && {
          password: booking.password,
          confirmPassword: booking.confirmPassword,
        }),
        phoneNumber: booking.phoneNumber,
        numberOfTickets: parseInt(booking.tickets ? booking.tickets : ''),
        notes: booking.notes,
        eventDateTime: parseDateTimeFromUTCToLocale(event.startDate, event.startTime),
        marketingOptIn: booking.marketing || false,
        ...(booking.createAccount && { ...PrepareUTMData(false)})
      };

      const url = booking.createAccount ? `/api/bookings/create-account` : `/api/bookings/guest`;

      await axios
        .post(url, data)
        .then(result => {
          if (result?.status !== 200) {
            setLoginError(true);
          }
          // If is paid event will return URL to open, otherwise is free stop isSending so we can display message
          if (result.data.url) {
            return window.open(result.data.url, '_self');
          } else {
            setIsSending(false);
          }
        })
        .catch(error => {
          throw new Error(error);
        });

      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      return new Error('Something went wrong');
    }
  };

  return {bookingRequest, isSending, loginError};
};
