import React from 'react';
import {Box} from '@resi/beeem-ui-components';
import Calendar from '../../components/icons/calendar.svg';
import Cog from '../../components/icons/cog.svg';
import {useNavigate} from 'react-router-dom';

const Side = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: '#111111',
        width: '96px',
        height: '100%',
        position: 'fixed',
        left: '0px',
        top: '0px',
        display: {xs: 'none', lg: 'flex'},
        flexDirection: 'column',
        padding: '0 20px',
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        src="/images/home-white.svg"
        sx={{marginTop: '20px', width: '65px', cursor: 'pointer'}}
        onClick={() => navigate('/admin/events')}
      />
      <Box component="img" src={Calendar} sx={{marginTop: '60px', width: '30px'}} />
    </Box>
  );
};

export default Side;
