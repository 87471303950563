import React from 'react';
import axios from 'axios';
import {Box, Button, Typography} from '@resi/beeem-ui-components';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Password from '../onboarding/steps/password';
import {RootState} from '../../store';
import {checkPassword} from '../../utils';

axios.defaults.baseURL = process.env.BASE_URL;

const Update = () => {
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.profile.value);
  const [error, setError] = React.useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const emailAddress = params.get('email');

  const changePassword = async () => {
    if (!checkPassword(profile.password) || profile.password !== profile.confirmPassword) {
      setError(true);
    } else {
      axios
        .post('/api/auth/confirm-password', {
          emailAddress,
          code,
          newPassword: profile.password,
        })
        .then(result => {
          navigate('/signin');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <Box
      sx={{
        width: '529px',
        maxWidth: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '100px',
      }}
    >
      <Typography variant="h3" color="text.primary" sx={{marginBottom: '60px'}}>
        Change your password
      </Typography>
      <Password profile={profile} reminder={true} error={error} />
      <Button
        variant="contained"
        sx={{width: '408px', maxWidth: '100%', margin: '50px auto'}}
        onClick={changePassword}
      >
        Update your password
      </Button>
    </Box>
  );
};

export default Update;
