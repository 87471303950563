import * as React from 'react';
import {Box, Modal, Backdrop, Grid, Typography} from '@resi/beeem-ui-components';
import Times from '../icons/times.svg';

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {in: open, children, onEnter, onExited, ...other} = props;

  return <Box>{children}</Box>;
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: {xs: '50px 20px', md: '100px 100px 50px 100px'},
  maxWidth: '800px',
  maxHeight: '90%',
  height: '100%',
  width: { xs: '90%', md: '100%'},
  overflowY: 'scroll',
  bgcolor: '#FFFFFF',
  boxShadow: 24,
};

interface ISpringModalProps {
  children: React.ReactElement;
  open: boolean;
  handleOpen?: () => void;
  handleClose: () => void;
}

export default function SpringDialog({children, open, handleOpen, handleClose}: ISpringModalProps) {
  return (
    <Box>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              component="img"
              src={Times}
              sx={{position: 'absolute', top: '20px', right: '20px', cursor: 'pointer'}}
              onClick={handleClose}
            />
            {children}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
