import Logo from '../../assets/Icons/Logo';
import { navLinks } from './NavLinks';
import { Link } from 'react-router-dom';
import { NavContainer, NavLinks } from './Navigation[Style]';
import useDetectDevice from '../../hooks/useDetectDevice';
import MobileMenu from './MobileMenu/MobileMenu';

function Navigation(): JSX.Element {
  const { isDesktop, isMobile } = useDetectDevice();

  return (
    <NavContainer
      paddingTop={ 16 }
      paddingLeft={ 16 }
      paddingRight={ 16 }
      paddingBottom={ 16 }
      className={"d-row align-center justify-between"}
    >
        <Link
          to={"https://wallacealiving.com/"}
          className={"logo"}
        >
          <Logo width={isMobile ? 200/1.5 : 200} height={42.7} fill={"#285743"} />
        </Link>
        {
          isDesktop ?
          <>
            <NavLinks className="d-row align-center">
              {
                navLinks.map(navLink => (
                  <Link
                    key={navLink.path}
                    to={navLink.path}
                    rel={"noopener"}
                    className={"font-body-sm font-green font-barlow nav-link"}
                  >
                    {navLink.name}
                  </Link>
                ))
              }
            </NavLinks>
            <Link
              to={"https://wallacealiving.com/contact"}
              className={"btn-primary font-barlow font-body-sm"}
            >
              {"Contact"}
            </Link>
          </> :
          <MobileMenu />
        }
    </NavContainer>
  )
}

export default Navigation;